import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Fancy Yarns" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1>PulseTex</h1>
            <p>PulseTex Fancy Yam System supplies intermittent air to the air jet, controlling the production of non-intermingling lengths between intermingling points.</p>
            <p>When two yarns of different dye characteristics or Ecru and doped dyed yarns are combined using the PulseTex system special colour effects or patterns are obtained in the yarn and in the fabric.</p>
            <p>PulseTex can be integrated in false-twist texturing, winding or air-covering machines to combine various different yarn components. PulseTex is totally user settable and is controlled by a PLC with special software.</p>
           <br/>
           <h1>MagnaTex</h1>
           <p>MagnaTex Fancy Yarn system intermittently draws the yarn by means of “pinch” points at high frequency. The yarn has low crimp and distinctive light and dark lengths depending on texturing machine and system settings. The system is controlled by PLC with special software.</p>
           <br/>
           <h1>SlubTex</h1>
           <p>SlubTex is based on core and effect yarns. When two yarns of the same or different characteristic are processed on a false twist texturing machine, one yarn is overfeed into an air jet.</p>
           <p>The SlubTex device will overfeed one yarn into the air jet, the jet will combine the “effect” yarn with the “core” yarn to give a false twist slub yarn.</p>
           <p>SlubTex is controlled by PLC with special software.</p>
           <br/>
           <p>SlubTex can also be integrated on:</p>
           <ul>
               <li>False twist texturing machines.</li>
               <li>Air Jet Texturing to give “3D” slubbing effect.</li>
               <li>Assembly or Re-winding machines.</li>
           </ul>
           <br/>
           <h1>Core & Effect Yarns</h1>
           <p>
           Core and effect yarns are produced on a false twist machines by means of additional feeds, guides and air jets. Two different yarns are processed at different draws and overfeeds. The yarns are co-mingled together with an air jet to give a 2ply yarn with special characteristics.
           </p>
           <p><b>All of the above systems are available as plug and play one section modules for trial at customer sites. Please contact us for more details if you would like to make fancy and innovative yarns with our systems. Our expert technology experts will work with you to develop the yarns.</b></p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
