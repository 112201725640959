import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading1, Subheading } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import SliderCard from "components/cards/SliderWithoutTitle.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Select from 'react-select';

//fancy Yarn

import yarn1 from "images/fancyyarns/0001.jpg";
import yarn2 from "images/fancyyarns/0002.jpg";
import yarn3 from "images/fancyyarns/0003.jpg";
import yarn4 from "images/fancyyarns/0004.jpg";
import yarn5 from "images/fancyyarns/0005.jpg";
import yarn6 from "images/fancyyarns/0006.jpg";
import yarn7 from "images/fancyyarns/0007.jpg";
import yarn8 from "images/fancyyarns/0008.jpg";
import yarn9 from "images/fancyyarns/0009.jpg";
import yarn10 from "images/fancyyarns/0010.jpg";
import yarn11 from "images/fancyyarns/0011.jpg";
import yarn12 from "images/fancyyarns/0012.jpg";

import torqtexgraph from "images/fancyyarns/torqtexgraph.jpg";
import torqtex from "images/fancyyarns/torqtex.jpg";

import heaterplate from "images/attachments/heaterplate.jpg";
import heaterpath from "images/attachments/heaterpath.jpg";

//pop-up windwos import
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
const Paragraph1 = tw.p`my-3 md:pr-8 lg:my-3 text-base xl:text-lg`;
const Heading = tw(SectionHeading)`w-full`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`md:mt-8 mt-4 py-3 w-48 mr-2 bg-primary-500 text-gray-100
  hocus:bg-secondary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0 rounded-full text-center cursor-default`
]);

const Features = tw.div`max-w-64 mx-auto md:mx-0 w-2/12 bg-gray-200 `;

const Feature = styled(Link)(props => [
  tw`block cursor-pointer py-3 mt-2 sm:mt-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base font-bold text-lg text-primary-500 cursor-pointer pl-2`,
  tw`hover:bg-gray-300 hover:text-gray-700`,
  props => props.active && tw`bg-primary-500! text-gray-100!`
]);

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
// Random Decorator Blobs (shapes that you see in background)

//Yarn cutters
const fancyyarns = [
  { imageSrc: yarn1 },
  { imageSrc: yarn2 },
  { imageSrc: yarn3 },
  { imageSrc: yarn4 },
  { imageSrc: yarn5 },
  { imageSrc: yarn6 },
  { imageSrc: yarn7 },
  { imageSrc: yarn8 },
  { imageSrc: yarn9 },
  { imageSrc: yarn10 },
  { imageSrc: yarn11 },
  { imageSrc: yarn12 }
];

var featureChanged = false;

export default ({
  heading = "Value Added Fancy Yarns",
  watchVideoButtonText = "Watch Video",
  watchVideoYoutubeUrl = "/components/yarnFancyPages/FancyYarnIntroductionPage",
}) => {
  const features = [
    {
      index: 0,
      title: "Fancy Yarn Solutions", 
      value: 0,
      slug: 'fancy-yarn-solutions',
      urlSlug: '/FancyYarn/fancy-yarn-solutions',
      label: "Fancy Yarn Solutions",
      description: "We have the best professional marketing people across the globe just to work with you."
    },
    {
      index: 1,
      title: "High Shrinkage",
      value: 1,
      slug: 'high-shrinkage',
      urlSlug: '/FancyYarn/high-shrinkage',
      label: "High Shrinkage",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 2,
      title: "TorqTex",
      value: 2,
      slug: 'torqtex',
      urlSlug: '/FancyYarn/torqtex',
      label: "TorqTex",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 3,
      title: "MagTex",
      value: 3,
      slug: 'magtex',
      urlSlug: '/FancyYarn/magtex',
      label: "MagTex",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 4,
      title: "PulseTex",
      value: 4,
      slug: 'pulsetex',
      urlSlug: '/FancyYarn/pulsetex',
      label: "PulseTex",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 5,
      title: "SlubTex",
      value: 5,
      slug: 'slubtex',
      urlSlug: '/FancyYarn/slubtex',
      label: "SlubTex",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 6,
      title: "Core & Effect",
      value: 6,
      slug: 'core&effect',
      urlSlug: '/FancyYarn/core&effect',
      label: "Core & Effect",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
  ];
  const { name, type } = useParams();
  const [activeFeature, setActiveFeature] = useState(features[0].index);
  
  const changeFeature = (index, isFromLoad) => {
    setActiveFeature(index);

    if(isFromLoad) {
      featureChanged = true;
    } else {
      const body = document.querySelector('#fancy');

      if(body){
        body.scrollIntoView({
          behavior: 'smooth'
        }, 500);
      }
    }
  }

  useEffect(() => {
    console.log("I load");
    debugger;
    if(!featureChanged) {
      let featureSlug = features.find( ({ slug }) => slug === type );
      if(featureSlug) {
        changeFeature(featureSlug.index, true);
      }
    }
  });

  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>Fancy Yarn Solutions</Heading>
        <Paragraph1 id="fancy">
          Worldwide production of standard textured yarns has become highly competitive. Companies are competing for a market, which is often unwilling to pay for the real production cost of the yarns. This has meant for many companies in the texturing industry much lower profits and even losses.
        </Paragraph1>
        <Paragraph1>
          The industry is under severe pressure to innovate by offering yarns with new features. Apart from chemical or spinning technology related changes there are also ways to modify the aesthetics of yarns on the texturing machine. These yarns are often referred to as fancy yarns.
        </Paragraph1>
        <div tw="flex">
          <Paragraph1>
            Fancy yarns have deliberately introduced <b>irregular</b> characteristics, in either,
          <br></br>
            <div tw="flex flex-wrap justify-center">
              <PrimaryButton as="a" href=''>
                Yarn Diameter
              </PrimaryButton>
              <PrimaryButton as="a" href=''>
                Bulk
              </PrimaryButton>
              <PrimaryButton as="a" href=''>
                Appearance
              </PrimaryButton>
              <PrimaryButton as="a" href=''>
                Colour
              </PrimaryButton>
              <PrimaryButton as="a" href=''>
                Touch
              </PrimaryButton>
            </div>
          </Paragraph1>
        </div>
        <Paragraph1>
          We can offer texturing companies process expertise, hardware, software and control systems for <b>ANY</b> OEM texturing machinery to produce fancy yarns. Shown below are typical yarn paths to make some fancy yarns. We have also detailed the hardware and software required to make fancy yarns on customer’s existing machinery.
        </Paragraph1>
        <div tw="flex mt-10 flex-col md:flex-row">
          <Features tw="hidden md:block">
            {features.map((feature, index) => (
              <Feature to={feature.urlSlug} key={index} active={activeFeature === index} onClick={() => changeFeature(index)}>
                {feature.title}
              </Feature>
            ))}
          </Features>
          <Select options={features} defaultValue={features[0]} tw="w-full block text-left md:hidden block" onChange={e => changeFeature(e.index)} />

          {activeFeature === 0 ?
            <div tw="mt-4 md:mt-0 md:w-5/6 md:pl-8">
              <div tw="flex">
                <div tw="w-full md:w-3/4">
                  <SliderCard title="" cards={fancyyarns} slidesShow={1} />
                </div>
              </div>
            </div> : ''}

          {activeFeature === 2 ?
            <div tw="mt-4 md:mt-0 md:w-5/6 md:pl-8">
              <SectionHeading>TorqTex Yarn Conversion</SectionHeading>
              <br></br>
              <Paragraph1>
                The production of TorqTex yarns by the false twist method requires controlled insertion of very low levels of twist. Conventional disc twisting systems are not ideally suited to this application, whilst this approach can work; it is inherently unstable and prone to variability.
             </Paragraph1>
              <Paragraph1>
                TSP’s TorqTex conversion of any OEM friction unit has been developed specifically for low twist operation and provides controlled twist insertion. This conversion enables our customers to successfully process TorqTex yarns.
             </Paragraph1>
              <Paragraph1>
                This conversion produces bright (glossy) flat yarns. End uses include ladies’ legwear and automotive trimmings. Mono-filament yarns have also been processed for use in vertical blinds.
             </Paragraph1>
              <Paragraph1>
                The TorqTex conversion controls the yarn across its twisting surface at low angles. The successful production of TorqTex yarns requires it to be processed on linear texturing zones – V type machines
             </Paragraph1>
              <Paragraph1>
                <div tw="mb-4">Conversion Details:</div>
                <div>• Simple innovative “plug and play” design</div>
                <div>•	No modification required to the friction unit</div>
                <div>•	Same components used to change from ‘S’ to ‘Z’ twist</div>
                <div>•	The friction unit changes a temporary can be easily converted from standard to TorqTex and vice - versa</div>
              </Paragraph1>
              <div tw="mt-8">
                <Subheading1>Initial Set Up Conditions</Subheading1>
                <Paragraph1>
                  Low twist levels generated by the TorqTex unit will increase T1 & T2 process tensions. They may exceed the normal operating range of the Unitens sensor. We propose the sensor is by-passed.
                  </Paragraph1>
                <Paragraph1>
                  Lower twist levels enable the user to reduce the draw ratio without any detrimental effect on surge speeds.
                  </Paragraph1>
                <Paragraph1>
                  Process tensions can be further reduced by moving back the cooling track. This will reduce additional process friction and therefore reduce process tensions.
                  </Paragraph1>
                <Paragraph1>
                  Primary heater temperatures should be kept normal.
                  </Paragraph1>
                <Paragraph1>
                  Crimp modification is adjusted by the secondary heater temperature, typically temperatures of 120 to 150{<span>&deg;</span>}C are used. Below are twist levels generated by the TorqTex conversion for one type of yarn.
                  </Paragraph1>
                <img tw="mt-8 mb-4 rounded-lg" src={torqtexgraph} alt="Design Illustration" />
              </div>
              <Subheading1 tw="mt-8">TorqTex conversion Barmag Type 8 Friction Unit</Subheading1>
              <img tw="mt-8 mb-4 rounded-lg" src={torqtex} alt="Design Illustration" />
            </div> : ''}

            {activeFeature === 1 ?
            <div tw="mt-4 md:mt-0 md:w-5/6 md:pl-8">
              <SectionHeading>High Shrinkage</SectionHeading>
                <br></br>
                <Paragraph1>
                Electrical heater plate enables user process yarns that require high shrinkage properties. Depending on the process parameters and shrinkage levels required the user can achieve 25 to +30% shrinkage with a process speed of +500mpm.
                </Paragraph1>
                <Paragraph1>
                Existing vapour phase liquid heater have a minimum working temperature of 150{<span>&deg;</span>}C they cannot uniformly process yarns where high shrinkage properties are required. 
                </Paragraph1>
                <Paragraph1>
                Fitting of the heaters and the yarn path used has no permanent adjustments to the original machine. There is no obstruction to the operator for threading the standard yarn path.
                </Paragraph1>
                <Paragraph1>
                  <div>•	Heater Plate length from 0.30 to 0.50m</div>
                  <div>•	Heater Plate is capable of working from Ambient to 245{<span>&deg;</span>}C</div>
                  <div>•	Each heater has two yarn tracks</div>
                  <div>•	Each heater processes 2 yarns</div>
                  <div>•	Heater Plate fitted with twist stoppers</div>
                  <div>•	Ceramic Guides located at the exit of the heater</div>
                  <div>•	Heaters attached to the existing Primary heater by clamping</div>
                  <div>•	Heaters can be left in place when processing standard products</div>
                  <div>•	Existing cooling tubes / tracks utilised</div>
                  <div>•	Input end of the cooling tube / track lowered in-line with the exit of the heater plate</div>
                  <div>•	Cooling tube / track can be easily re-located to original position</div>
                  <div>•	Rotating guides fitted above the input feed to help defect the yarn to the twist stopper</div>
                </Paragraph1>
                <div tw="mt-8">
                  <Subheading1>Heater Plate Profile</Subheading1>
                  <img tw="mt-8 mb-4 rounded-lg" src={heaterplate} alt="Design Illustration" />
                </div>  
                <div tw="mt-8">
                  <Subheading1>Heater Yarn Path & Location</Subheading1>
                  <img tw="mt-8 mb-4 rounded-lg" src={heaterpath} alt="Design Illustration" />
                </div>  
            </div> : ''}
            {activeFeature === 3 || activeFeature === 4 || activeFeature === 5 || activeFeature === 6 ?
            <div tw="mt-4 md:mt-0 md:w-5/6 md:pl-8">
              <Paragraph1>
              Page under construction, we will update this page soon.
              </Paragraph1>
            </div> : ''}
        </div>
      </ContentWithPaddingXl>
      <DecoratorBlob2 />
    </Container>
  );
};