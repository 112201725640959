import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, {useEffect} from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

import ComponentRenderer from "ComponentRenderer.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/machine.jpg";
import macHeroScreenshotImageSrc from "images/longmachine.jpg";
import Header from "components/headers/light.js";

export default function App({ roundedHeaderButton }) {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [ ]);

  return (
    <Router>
    <AnimationRevealPage>      
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Switch>
        <Route exact path="/">
         
            <Hero roundedHeaderButton={true} />
            <Features
              subheading={<Subheading>Services</Subheading>}
              imageSrc={macHeroScreenshotImageSrc}
              imageSrc2={heroScreenshotImageSrc}
              heading={
                <>
                  We Are <HighlightedText>Solutions Provider</HighlightedText> For
                </>
              }
            />
            <GetStarted/>
        </Route>
        <Route exact path="/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/:name/:type">
          <ComponentRenderer />
        </Route>
      </Switch>
      <Footer />
      </AnimationRevealPage>
    </Router>
  );
}

