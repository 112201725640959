import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center md:mx-12 pt-8`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full mt-4`;

const VerticalSpacer = tw.div`mt-4 w-full`


const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 md:px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto items-center px-6 py-4 border-2 border-dashed border-primary-500 rounded-lg mt-4`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-3 h-10`}
  }

  .divContainer {
    ${tw`mb-4 leading-none`}
  }

  .title {
    ${tw`mt-2 font-bold text-lg text-primary-500`}
  }

  .description {
    ${tw`mt-6 mb-6 text-lg text-secondary-500 leading-normal w-full`}
  }
`;
const ImageColumn = tw.div`md:w-11/12 flex-shrink-0 mt-4 relative`;

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);


const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const styleobj = {
  paddingLeft:"1.1em" ,
  textIndent:"-1.1em"
}

export default ({ cards = null, heading = "Amazing Features",  
imageSrc = "",
imageSrc2 = "",
imageRounded = true,
imageBorder = false,
showDecoratorBlob = false,
imageShadow = false,subheading = "Features", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Machinery Spare Parts",
      description: "TSP offers OEM quality spare parts for Disc, Pin and Air Jet texturing machinery. Our quality products cover the following machine manufacturers, Rieter Scragg, Barmag, ICBT, TMT, Guidici, RPR, Aalidhra, Bhagat, Himson and Chinese OEM Texturing machinery.",
      description2: "Customers often ask us for solutions for various components or products associated with other types of machines or processes including the POY & FDY spinning process. To facilitate our customer's requirements, we have extended our product range to include many textile auxiliary products from anti-bacterial master batch chips to coning oil, from perforated plastic tubes or cones to sizing agents and from POY to dyed yarns."
    },
    { imageSrc: ReliableIconImage, 
      title: "Machine Conversions & Attachments",
      description: "TSP’s conversions and attachments can be installed on Rieter Scragg, Barmag, ICBT, TMT, Guidici, RPR, Aalidhra, Bhagat, Himson and Chinese OEM Texturing machinery. They can improve machine runnability, efficiency or enable the user to produce fancy yarns.",
      description2: "Electronic components are fast becoming obsolete. TSP offers electronic solutions for older generation machinery to enable the user to continue production. We also offer a guaranteed electronic repair service of electronic boards and inverters for all types of machinery."
   },
    { imageSrc: SupportIconImage, title: "Consultancy Services",
      description:null }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <ImageColumn>
          <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
            {showDecoratorBlob && <DecoratorBlob />}
        </ImageColumn>
        <Heading>{heading}</Heading>
      
        <VerticalSpacer />
        {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>
                </span>
                <p className="description">
                {card.description || (
                  <div className="ulContainer">
                    <div className="divContainer">Process speed optimisation</div>
                    <div className="divContainer">Process break rate reduction</div>
                    <div className="divContainer">Improve yarn quality</div>
                    <div className="divContainer">Increase in 1st grade or AX yarns</div>
                    <div className="divContainer">Reduction in waste yarn</div>
                    <div className="divContainer">Development and matching of competitors yarns</div>
                    <div className="divContainer">Development of fancy yarns</div>
                    <div className="divContainer">Core & Effect Yarns on false twist texturing machines</div>
                  </div>
                )}
                </p>
                <p className="description">
                  {card.description2}
                </p>
                <p className="description">
                  {card.description3}
                </p>
              </Card>
            </Column>
          ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
