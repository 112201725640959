import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading, Subheading1 } from "components/misc/Headings.js";
import { Subheading as SubheadingBase } from "components/misc/Headings";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import SliderCard from "components/cards/SliderWithoutTitle.js";
import { PrimaryButton, PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Select from 'react-select';

//fancy Yarn

import yarn1 from "images/fancyyarns/0001.jpg";
import yarn2 from "images/fancyyarns/0002.jpg";
import yarn3 from "images/fancyyarns/0003.jpg";
import yarn4 from "images/fancyyarns/0004.jpg";
import yarn5 from "images/fancyyarns/0005.jpg";
import yarn6 from "images/fancyyarns/0006.jpg";
import yarn7 from "images/fancyyarns/0007.jpg";
import yarn8 from "images/fancyyarns/0008.jpg";
import yarn9 from "images/fancyyarns/0009.jpg";
import yarn10 from "images/fancyyarns/0010.jpg";
import yarn11 from "images/fancyyarns/0011.jpg";
import yarn12 from "images/fancyyarns/0012.jpg";

import heater from "images/attachments/heater.jpg";
import rieter from "images/attachments/rieter.jpg";
import rieter2 from "images/attachments/rieter2.jpg";
import diagram from "images/attachments/daigram.jpg";
import operator from "images/attachments/operator.jpg";
import operator2 from "images/attachments/operator2.jpg";
import yarncooling from "images/attachments/yarncooling.jpg";
import magnetic from "images/attachments/magnetic.jpg";
import magnetic1 from "images/attachments/magnetic1.jpg";
import magnetic2 from "images/attachments/magnetic2.jpg";
import magnetic3 from "images/attachments/magnetic3.jpg";
import magnetic4 from "images/attachments/magnetic4.jpg";
import frictiongraph from "images/attachments/frictiongraph.jpg";
import friction from "images/attachments/friction.jpg";
import friction2 from "images/attachments/friction2.jpg";
import coolingtable from "images/attachments/coolingtable.jpg";
import cooling from "images/attachments/cooling.jpg";
import cooling1 from "images/attachments/cooling1.jpg";
import cooling2 from "images/attachments/cooling2.jpg";
import hotpins from "images/attachments/hotpins.jpg";
import hotpins1 from "images/attachments/hotpins1.jpg";
import hotpins2 from "images/attachments/hotpins2.jpg";
import knittedyarns from "images/attachments/knittedyarns.jpg";
import heaterplate from "images/attachments/heaterplate.jpg";
import heaterpath from "images/attachments/heaterpath.jpg";
import hotplate from "images/attachments/hotplate.jpg";
import machinecontrol from "images/attachments/machinecontrol.jpg";
import spandex from "images/attachments/spandex.jpg";
import spandexbreaks from "images/attachments/spandexbreaks.jpg";
import spandexfabric from "images/attachments/spandexfabric.jpg";
import spandexbarmag from "images/attachments/spandexbarmag.jpg";

const styleobj = {
  
    paddingLeft:"1em" ,
     textIndent:"-1em"
  
}

const TextConSpan = tw.span`mr-10`;
const Paragraph1 = tw.p`my-3 md:pr-8 lg:my-3 text-base xl:text-lg`;
const Heading = tw(SectionHeading)`w-full`;
const Features = tw.div`max-w-64 mx-auto md:mx-0 w-1/4 bg-gray-200 `;

const Feature = styled(Link)(props => [
  tw`block cursor-pointer py-3 mt-2 sm:mt-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base font-bold text-lg text-primary-500 cursor-pointer pl-2`,
  tw`hover:bg-gray-300 hover:text-gray-700`,
  props => props.active && tw`bg-primary-500! text-gray-100!`
]);

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const TabInnerContent = tw(motion.div)`md:ml-8 flex flex-wrap`;
const TabInnerDiv = tw.div`mt-4 md:mt-0 md:w-3/4`;

// Random Decorator Blobs (shapes that you see in background)
var featureChanged = false;

//Yarn cutters
const fancyyarns = [
  { imageSrc: yarn1 },
  { imageSrc: yarn2 },
  { imageSrc: yarn3 },
  { imageSrc: yarn4 },
  { imageSrc: yarn5 },
  { imageSrc: yarn6 },
  { imageSrc: yarn7 },
  { imageSrc: yarn8 },
  { imageSrc: yarn9 },
  { imageSrc: yarn10 },
  { imageSrc: yarn11 },
  { imageSrc: yarn12 }
];

export default ({
  heading = "Value Added Fancy Yarns",
  watchVideoButtonText = "Watch Video",
  watchVideoYoutubeUrl = "/components/yarnFancyPages/FancyYarnIntroductionPage",
}) => {
  const { name, type } = useParams();
  const features = [
    {
      index: 0,
      title: "Machine Control Systems",
      slug: "machine-control-systems",
      value: 0,
      urlSlug: '/Attachments/machine-control-systems',
      label: "Machine Control Systems",
      description: "We have the best professional marketing people across the globe just to work with you."
    },
    {
      index: 1,
      title: "Heater Control Systems",
      slug: "heater-control-systems",
      value: 1,
      urlSlug: '/Attachments/heater-control-systems',
      label: "Heater Control Systems",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 2,
      title: "Primary Electrical Heater",
      slug: "primary-electrical-heater",
      value: 2,
      urlSlug: '/Attachments/primary-electrical-heater',
      label: "Primary Electrical Heater",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 3,
      title: "Short Heater Attachment",
      slug: "short-heater-attachment",
      value: 3,
      urlSlug: '/Attachments/short-heater-attachment',
      label: "Short Heater Attachment",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 4,
      title: "Equal Length Doffing Systems",
      slug: "equal-length-doffing-systems",
      urlSlug: '/Attachments/equal-length-doffing-systems',
      value: 4,
      label: "Equal Length Doffing Systems",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 5,
      title: "Spandex Feed Attachment",
      slug: "spandex-feed-attachment",
      urlSlug: '/Attachments/spandex-feed-attachment',
      value: 5,
      label: "Spandex Feed Attachment",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 6,
      title: "Additional Feed Rollers",
      slug: "additional-feed-rollers",
      value: 6,
      urlSlug: '/Attachments/additional-feed-rollers',
      label: "Additional Feed Rollers",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 7,
      title: "Nip Roller to Apron Conversion",
      slug: "nip-roller-to-apron-conversion",
      value: 7,
      urlSlug: '/Attachments/nip-roller-to-apron-conversion',
      label: "Nip Roller to Apron Conversion",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 8,
      title: "Cooling Tube Conversion",
      slug: "cooling-tube-conversion",
      value: 8,
      urlSlug: '/Attachments/cooling-tube-conversion',
      label: "Cooling Tube Conversion",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 9,
      title: "Cooling Track Conversion",
      slug: "cooling-track-conversion",
      value: 9,
      urlSlug: '/Attachments/cooling-track-conversion',
      label: "Cooling Track Conversion",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 10,
      title: "Hot Pin Attachment",
      slug: "hot-pin-attachment",
      value: 10,
      urlSlug: '/Attachments/hot-pin-attachment',
      label: "Hot Pin Attachment",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 11,
      title: "Hot Plates Attachment",
      slug: "hot-plates-attachment",
      value: 11,
      urlSlug: '/Attachments/hot-plates-attachment',
      label: "Hot Plates Attachment",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 12,
      title: "Magnetic Pin Spindles",
      slug: "magnetic-pin-spindles",
      value: 12,
      urlSlug: '/Attachments/magnetic-pin-spindles',
      label: "Magnetic Pin Spindles",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 13,
      title: "Friction Unit Conversions",
      slug: "friction-unit-conversions",
      value: 13,
      urlSlug: '/Attachments/friction-unit-conversions',
      label: "Friction Unit Conversions",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    }
  ];

  const [activeFeature, setActiveFeature] = useState(features[0].index);

  const changeFeature = (index, isFromLoad) => {
    setActiveFeature(index);

    if(isFromLoad) {
      featureChanged = true;
    } else {
      const body = document.querySelector('#attachment');

      if(body){
        body.scrollIntoView({
          behavior: 'smooth'
        }, 500);
      }
    }
  }

  useEffect(() => {
    if(!featureChanged) {
      let featureSlug = features.find( ({ slug }) => slug === type );
      if(featureSlug) {
        changeFeature(featureSlug.index, true);
      }
    }
  });

  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>Conversions & Attachments</Heading>
        <Paragraph1>
          Our extensive knowledge of the texturing process and experience working with all types of Draw Texturing machinery has given us the ability to provide solutions and practical knowledge to help our customers. 
        </Paragraph1>
        <Paragraph1 style={styleobj}>
          <div>•	Older machinery can be converted to make Value Added Products</div>
          <div>•	Machinery can be converted to make niche products</div>
          <div>•	Problematic older machine parts can be replaced by new technology</div>
          <div>•	Custom made systems can be developed and supported </div>
          <div>•	Obsolete or unsupported electronics can be replaced by modern off the shelf electronics</div>
        </Paragraph1>
        <Paragraph1 id="attachment">
          Below is a comprehensive list of products and systems that we have developed and supplied to our customers.
        </Paragraph1>
        <div tw="flex mt-10 flex-col md:flex-row">
          <Features tw="hidden md:block">
            {features.map((feature, index) => (
              <Feature to={feature.urlSlug} key={index} active={activeFeature === index} onClick={() => changeFeature(index)}>
                {feature.title}
              </Feature>
            ))}
          </Features>
          <Select options={features} defaultValue={features[0]} tw="w-full block text-left md:hidden block" onChange={e => changeFeature(e.index)} />
      
          <TabInnerDiv>
            <TabInnerContent>
            {activeFeature === 0 ?
              <div>
                <SectionHeading>Machine Control Systems</SectionHeading>
                <br></br>
                <Paragraph1>
                Electronic components used for machine drives and temperature control systems are specific to OEM’s. Over time components within the machine and temperature control systems become obsolete or the OEM’s do not provide sufficient support.
                </Paragraph1>
                <Paragraph1>
                Older components become problematic which increases the risk of machine stoppages. Delivery times for replacement parts or repairs can exceed 2 months or longer.
                </Paragraph1>
                <Paragraph1>
                TSP provides machine drive and temperature systems to convert older or obsolete electronic to modern technology control systems. The components used in our systems are available commercially, this enables the user’s to quickly repair future faults.
                </Paragraph1>
                <Paragraph1>
                Detailed below is a typical system we install to replace OEM control machinery.
                </Paragraph1>
                <Subheading tw="mt-8 text-2xl">
                User Interface
                </Subheading>
                <Paragraph1>
                <div tw="mb-1" style={styleobj}>•	Speed control of all motors / Shafts in closed loop will be performed by AC Inverters via Encoder feedback mounted on the shaft,</div>
                <Paragraph1 tw="m-8">
                  <div tw="mb-1">•	All Feed Rollers</div>
                  <div tw="mb-1">•	Take Up Rollers</div>
                  <div tw="mb-1">•	Traverse Speed</div>
                </Paragraph1>
                  <div tw="mb-1" style={styleobj}>•	Calculation of shaft speeds based on user set points</div>
                  <div tw="mb-1" style={styleobj}>•	Parameters downloaded to AC drives through RS484 serial link</div>

                  <Subheading tw="mt-8 text-2xl">
                  Traverse Edge Breaking Control System
                  </Subheading>
                  <Paragraph1 style={styleobj}>
                    <div tw="mb-1">•	Edge Breaking & Radial Disturbance (Anti - Patterning)</div>
                    <div tw="mb-1">•	Maximum stroke modification length depends on the existing mechanical mechanism</div>
                    <div tw="mb-1">•	Multi stroke modification system for soft edge generation</div>
                    <div tw="mb-1">•	Synchronization of traverse and axial disturbance to maintain winding tension</div>
                    <div tw="mb-1">•	Provides ‘Larger’ to ‘Small’ steps of axial disturbance</div>
                    <div tw="mb-1">•	Maximum 16 steps</div>
                    <div tw="mb-1">•	Fully user settable parameters</div>
                    <div tw="mb-1">•	Traverse will synchronise the axial cycle to maintain uniform winding tensions</div>
                    <div tw="mb-1">•	Parameters can be stored as ‘Program No. of your choice</div>
                    <div tw="mb-1">•	Pre-programmed ‘Programs’ for commonly used yarns</div>
                  </Paragraph1>
                  <Subheading tw="mt-8 text-2xl">
                  Temperature Control System
                  </Subheading>
                  <Paragraph1 style={styleobj}>
                  <div tw="mb-1">•	Temperature control for Primary and Secondary Heaters</div>
                  <div tw="mb-1">•	Inert Temperature control Primary Heaters</div>
                  <div tw="mb-1">•	Alarm Logging</div>
                  <div tw="mb-1">•	0 - 250{<span>&deg;</span>}C / 300{<span>&deg;</span>}C range</div>
                  <div tw="mb-1">•	Compatible for Thermocouple and PT 100 sensors</div>
                  <div tw="mb-1">•	Global temperature set point</div>
                  <div tw="mb-1">•	Individual set point for each heater</div>
                  <div tw="mb-1">•	Delta T - cable drop compensation</div>
                  <div tw="mb-1">•	Delta TT – global heater trimming facility</div>
                  </Paragraph1>
                </Paragraph1>
                <Subheading tw="mt-8 text-2xl">
                Machine Control Schematic
                </Subheading>
                <img tw="mr-6  mt-8 mb-4 rounded-lg" src={machinecontrol} alt="Design Illustration" />
              </div>: ''}
            {activeFeature === 11 ?
              <div>
                <SectionHeading>Hot Plates</SectionHeading>
                <br></br>
                <Paragraph1>
                Hot Plates are available in lengths from 10 to 30cm to fit all OEM machinery they are used to make tight spot and Melange yarns. 
                </Paragraph1>
                <Paragraph1>These heaters can also be used to “preheat” the yarn for machines where existing heaters cannot heat heavy Denier yarns to the required temperature.</Paragraph1>
                <Paragraph1 style={styleobj}>
                  <div tw="mb-4">Product Features,</div>
                  <div>•	Hot Plates are available with or without Yarn Tracks </div>
                  <div>•	Each Hot Plate Processes 2 yarns</div>
                  <div>•	Uniform Temperature</div>
                  <div>•	Micro Processor Temperature Control</div>
                  <div>•	PT 100 Temperature Sensor</div>
                  <div>•	0 to 250oC Temperature Range</div>
                </Paragraph1>
                <img tw="mr-6  mt-8 mb-4 rounded-lg" src={hotplate} alt="Design Illustration" />
              </div>: ''}
              {activeFeature === 1 ?
              <div>
                <SectionHeading>Heater Control Systems</SectionHeading>
                <br></br>
                <Paragraph1>
                  Latest technology standalone heater control systems that operate independently from the OEM machine control system.
                </Paragraph1>
                <Paragraph1 style={styleobj}>
                  <div tw="mb-4">System features,</div>
                  <div>•	Microprocessor based digital PID control</div>
                  <div>•	Controls both Primary and Secondary heaters</div>
                  <div>•	Ability to control individual heaters</div>
                  <div>•	Independent control of left and right sides of machine</div>
                  <div>•	Calibration facility for individual heaters</div>
                  <div>•	Individual heater alarms and global alarm</div>
                  <div>•	Real time temperatures displayed and set points adjusted by HMI</div>
                </Paragraph1>
                <img tw="mr-6 ml-1 mt-8 mb-4 rounded-lg" src={heater} alt="Design Illustration" />
              </div>: ''}
              {activeFeature === 2 ?
              <div>
                <Paragraph1>
                Page under construction, we will update this page soon.
                </Paragraph1>
              </div>: ''}
              {activeFeature === 3 ?
              <div>
                <SectionHeading>Electrical Heater Plate Attachment</SectionHeading>
                <br></br>
                <Paragraph1>
                Electrical heater plate enables user process yarns that require high shrinkage properties. Depending on the process parameters and shrinkage levels required the user can achieve 25 to +30% shrinkage with a process speed of +500mpm.
                </Paragraph1>
                <Paragraph1>
                Existing vapour phase liquid heater have a minimum working temperature of 150{<span>&deg;</span>}C they cannot uniformly process yarns where high shrinkage properties are required. 
                </Paragraph1>
                <Paragraph1>
                Fitting of the heaters and the yarn path used has no permanent adjustments to the original machine. There is no obstruction to the operator for threading the standard yarn path.
                </Paragraph1>
                <Paragraph1 style={styleobj}>
                  <div>•	Heater Plate length from 0.30 to 0.50m</div>
                  <div>•	Heater Plate is capable of working from Ambient to 245{<span>&deg;</span>}C</div>
                  <div>•	Each heater has two yarn tracks</div>
                  <div>•	Each heater processes 2 yarns</div>
                  <div>•	Heater Plate fitted with twist stoppers</div>
                  <div>•	Ceramic Guides located at the exit of the heater</div>
                  <div>•	Heaters attached to the existing Primary heater by clamping</div>
                  <div>•	Heaters can be left in place when processing standard products</div>
                  <div>•	Existing cooling tubes / tracks utilised</div>
                  <div>•	Input end of the cooling tube / track lowered in-line with the exit of the heater plate</div>
                  <div>•	Cooling tube / track can be easily re-located to original position</div>
                  <div>•	Rotating guides fitted above the input feed to help defect the yarn to the twist stopper</div>
                </Paragraph1>
                <div tw="mt-8">
                  <Subheading1>Heater Plate Profile</Subheading1>
                  <img tw="mt-8 mb-4 rounded-lg" src={heaterplate} alt="Design Illustration" />
                </div>  
                <div tw="mt-8">
                  <Subheading1>Heater Yarn Path & Location</Subheading1>
                  <img tw="mt-8 mb-4 rounded-lg" src={heaterpath} alt="Design Illustration" />
                </div>  
              </div>: ''}
              {activeFeature === 4 ?
              <div>
                <SectionHeading>Equal Length Doffing System</SectionHeading>
                <br></br>
                <Paragraph1>
                The system is designed to integrate seamlessly to any production machine with the following features,
                
                </Paragraph1>
                <div tw="flex justify-around rounded-full">
              <PrimaryButton tw="text-center md:text-left rounded-full py-3 px-6" as="a" href=''>
              Doff Timing System
              </PrimaryButton>
              <PrimaryButton tw="text-center md:text-left rounded-full py-3 px-6" as="a" href=''>
              Production Monitoring
              </PrimaryButton>

            </div>
              <Subheading tw="mt-8 text-2xl">
                Timed Doffing System - For Equal Length Packages
              </Subheading>
              <Paragraph1>
                <div tw="mb-1" style={styleobj}>•	The system can be set to operate in GANG or RANDOM mode of doffing</div>
                <div tw="mb-1" style={styleobj}>•	The system can be set to work in ‘Length’ or ‘Time’ mode for measurement</div>
                <div tw="mb-1" style={styleobj}>•	In “Length Mode” the system counts the actual length through a proximity switch to measure the take up roller rotation. This mode is normally suitable for “Random” doffing method</div>
                <div tw="mb-1" style={styleobj}>•	The operator is alerted to doff packages with visual indications for each position</div>
                <div tw="mb-1" style={styleobj}>•	System can operate cutters (Auto Cut) for over-run positions ensuring equal length</div>
                <div tw="mb-1" style={styleobj}>•	System can be set to delay the cutters by a user settable time if the Auto Cut is enabled</div>
                <div tw="mb-1" style={styleobj}>•	Single, double, multi sided doffing sequences</div>
                <div tw="mb-1" style={styleobj}>•	Collects information form Yarn detectors and maintains the ‘Start Time’ or ‘End Time’ of a package, thereby generating reports for Production, Efficiency and Breaks</div>
                <div tw="mb-1" style={styleobj}>•	Connects to PC for ‘Online displays’ and MIS reports</div>
                <div tw="mb-1" style={styleobj}>•	Exhaustive reports for Production, Breaks, Efficiency, Equal length packages</div>
                <div tw="mb-1" style={styleobj}>•	Connects to Label printer giving package information</div>
                <div tw="mb-1" style={styleobj}>•	Can be viewed on any PC connected on to LAN</div>
                <div tw="mb-1" style={styleobj}>•	System can only operate with a yarn sensor output for production. Yarn Sensor has 4 wires with the following connections,</div>
                <Paragraph1 tw="m-8">
                  <div>1.	Positive 24DC Supply </div>
                  <div>2.	Negative 24DC Supply</div>
                  <div>3.	Negative 24DC Trigger Output for Cutter Activation</div>
                  <div>4.	Output for Yarn Monitoring Systems</div>
                </Paragraph1>
              </Paragraph1>
              <Subheading tw="mt-8 text-2xl">
                Production Monitoring
              </Subheading>
              <Paragraph1>
                <div tw="mb-1" style={styleobj}>•	Measures actual production of the yarn on the machine. Production is not calculated from running time of the position but directly from the machine take-up shaft via a proximity switch</div>
                <div tw="mb-1" style={styleobj}>•	There is no requirement to enter any machine speeds. User can change machine speed whenever he wants. The measured production will be correct.</div>
                <div tw="mb-1" style={styleobj}>•	The system generates the total production per machine & per plant individually for,
                <Paragraph1 tw="m-8">
                  <div tw="mb-1">•	Denier</div>
                  <div tw="mb-1">•	Lot or Merge Number</div>
                </Paragraph1>
                </div>
                <div tw="mb-1">•	Gives number of packages generated with average package weight for machine & plant</div>
                <Subheading tw="mt-8 text-2xl">
                    Production Efficiency:
                </Subheading>
                <Paragraph1 style={styleobj}>
                  <div tw="mb-1">•	Gives working efficiency of plant & machine</div>
                  <div tw="mb-1">•	Gives two types of efficiencies,
                    <Paragraph1 tw="m-8" style={styleobj}>
                      <div tw="mb-1">•	Absolute</div>
                      <div tw="mb-1">•	Relative only for the period machine is running</div>
                    </Paragraph1>
                  </div>
                </Paragraph1>
                <div tw="mb-1" style={styleobj}>•	Gives down time by position with selection on down period, no of stoppages of positions etc.</div>
                <div tw="mb-1" style={styleobj}>•	Maintains log of start & stop timings of machine. Thereby gives utility ratio of machine working over selected period. Highlights machines with longer down times.</div>
                <Subheading tw="mt-8 text-2xl">
                Breaks:
                </Subheading>
                <Paragraph1 style={styleobj}>
                  <div tw="mb-1">•	System gives various figures on yarn breaks including,</div>
                  <Paragraph1 tw="m-8">
                  <div tw="mb-1">•	Breaks per Hour</div>
                  <div tw="mb-1">•	Higher Yarn Breaks By Position</div>
                  <div tw="mb-1">•	Spindles with Large Re-threading Times</div>
                  <div tw="mb-1">•	Repeatedly Breaking Positions</div>
                  <div tw="mb-1">•	Breaks per Ton</div>
                </Paragraph1>
              </Paragraph1>
              </Paragraph1>
              <img tw="mr-6 ml-1 mt-8 mb-4 rounded-lg" src={diagram} alt="Design Illustration" />
                <div tw="flex mt-8 hidden md:flex">
                  <div tw="flex flex-col items-center w-1/2">
                    <Subheading1 tw="text-center">Operator Doffing Indicator</Subheading1>
                  </div>
                  <div tw="flex flex-col items-center w-1/2">
                    <Subheading1 tw="text-center">Section Slave Box</Subheading1>
                  </div>
                </div>
                <div tw="flex mt-4 hidden md:flex">
                  <div>
                    <img src={operator} alt="Design Illustration" />
                  </div>
                  <div>
                    <img src={operator2} alt="Design Illustration" />
                  </div>
                </div>
                <div tw="flex mt-8 md:hidden flex-col">
                    <Subheading1 tw="text-center">Operator Doffing Indicator</Subheading1>
                    <img src={operator} alt="Design Illustration" />
                  
                    <Subheading1 tw="text-center">Section Slave Box</Subheading1>
                    <img src={operator2} alt="Design Illustration" />
                </div>
              </div>: ''}
              {activeFeature === 5 ?
              <div>
                <SectionHeading>Spandex Attachment</SectionHeading>
                <br></br>
                <Subheading1>Air Covered Yarns (ACY)</Subheading1>
                <Paragraph1>
                Elastane yarn covered by Polyester or Nylon continuous filament textured yarns.
                </Paragraph1>
                <Subheading1>End Use:</Subheading1>
                <Paragraph1>
                ACY yarns are typically used for hosiery, denim fabric, active wear, women’s & men’s fashion apparel, socks, sweaters, seamless underwear, medical, woven fabric, knitted fabric and on small & large circular knitting machines.
                </Paragraph1>
                <Subheading1>Spandex:</Subheading1>
                <Paragraph1>
                The name "Spandex" is an anagram of the word "expands". It is the preferred name in North America and Asia. In many European countries it is referred to as "elastane"
                Brand names for spandex include Lycra (made by Invista, previously a part of DuPont), Elaspan (also Invista), Creora (Hyosung),  Invidya (Indorama), ROICA and Dorlastan (Asahi Kasei), Linel (Fillattice), ESPA (Toyobo) and several others.
                </Paragraph1>
                <Subheading1>Elastane fibres, photo taken by coloured scanning electron microscope (SEM)</Subheading1>
                <img tw="mt-8 mb-4 rounded-lg" src={spandex} alt="Design Illustration" />
                <Paragraph1>
                TSP offers a Controlled Elastane Feed (CEF) to produce ACY yarns on all types of texturing machines and standalone modules for coning and rewinding machines.
                </Paragraph1>

                <Subheading1 tw="mt-8">Controlled Elastane Feed (CEF)</Subheading1>
                <Paragraph1>
                The CEF system enables the user to air cover elastane yarns simultaneously whilst texturing Polyester, Nylon, and Polypropylene yarns. 
                </Paragraph1>
                <Paragraph1>
                The system gives a consistent and accurate draw (draft) to the elastane yarn during the process. This enables the user to process uniform denier, power stretch; air covered polyester, nylon and polypropylene yarns.
                </Paragraph1>
                <Paragraph1>
                The elastane bobbin is driven by a line shaft feed roller. The draw is created by the speed difference between the CEF feed roller and the texturing machine 2<sup>nd</sup> delivery roller. 
                </Paragraph1>
                <Paragraph1>
                The CEF system uses closed loop pneumatic technology to engage and dis-engage the elastane yarn to and from the feed roller. 
                </Paragraph1>
                <Paragraph1>
                Once the start button is pressed the position is initialised. The elastane yarn is engaged to the feed roller by the pneumatic cylinder and the yarn sensor becomes active. 
                </Paragraph1>
                <Paragraph1>
                When the elastane breaks or finishes the yarn sensor located on the CEF system, will send a signal to the pneumatic system to disengage the elastane bobbin and activate the yarn cutter to cut the base yarn.
                </Paragraph1>
                <Paragraph1>
                The CEF is interfaced to the machine yarn sensor and cutter to form a closed loop for elastane or yarn breaks. When the machine sensor activates the positional cutter, a signal is also sent to the CEF pneumatic system to disengage the elastane bobbin from the drive. This feature will prevent wastage of elastane and base yarns.
                </Paragraph1>
                <Paragraph1>
                Elastane yarn is deflected by rotating guides to reduce yarn tension on non-linear contact points of the thread line. CEF is a robust, compact and virtually maintenance free designed system.
                </Paragraph1>
                <Paragraph1>
                The CEF system can easily be mounted to ALL OEM texturing machines within days. CEF can be provided as standalone modules to use on rewinding and coning machines with overfeed rollers. CEF system produces “Value Added Yarns” giving a fast return on investment.
                </Paragraph1>
                <img tw="mt-8 mb-4 rounded-lg" src={spandexbreaks} alt="Design Illustration" />
                <img tw="mt-8 mb-4 rounded-lg" src={spandexfabric} alt="Design Illustration" />
                <Subheading1 tw="mt-8">Spandex Attachment Installed on Barmag FK6 900M and Murata 33H</Subheading1>
                <img tw="mt-8 mb-4 rounded-lg" src={spandexbarmag} alt="Design Illustration" />
              </div>: ''}
              {activeFeature === 6 ?
              <div>
                <SectionHeading>Additional Feed Rollers</SectionHeading>
                <br></br>
                <Paragraph1>
                  Additional feed beams are available to fit all OEM machinery. Feed rollers are available with nip rollers or apron belts. Additional Feed Rollers are essential to product niche or fancy yarns.
                </Paragraph1>
                <Paragraph1 style={styleobj}>
                  <div tw="mb-4">Feed beams are supplied with,</div>
                  <div>•	Easy fit mounting brackets</div>
                  <div>•	Motor mounting bracket</div>
                  <div>•	Nip or Apron belt traverse</div>
                  <div>•	Yarn cutters</div>
                  <div>•  Motor (optional)</div>
                  <div>•	Inverter (optional) </div>
                </Paragraph1>
                <div>
                  <Subheading1>Rieter Scragg Additional Feed Beam</Subheading1>
                  <img tw="mr-6 ml-1 mt-8 mb-4 rounded-lg" src={rieter} alt="Design Illustration" />
                </div>
                <div>
                  <Subheading1>Barmag Additional Feed Beam</Subheading1>
                  <img tw="mr-6 ml-1 mt-8 mb-4 rounded-lg" src={rieter2} alt="Design Illustration" />
                </div>  
              </div>: ''}
              {activeFeature === 7 ?
              <div>
              <SectionHeading>Nip Roller to Apron Belt Conversion</SectionHeading>
              <br></br>
              <Paragraph1>
                Retrofit Apron Belt modules are available to replace Nip Rollers. Converting the Nip Roller yarn feed method to the Apron Belt gives users the following advantages,
              </Paragraph1>
              <Paragraph1>
                <div tw="mb-1" style={styleobj}>•	No effect to yarn quality for higher T2 process tensions</div>
                <div tw="mb-1" style={styleobj}>•	Reduced machine downtime during scheduled stoppage</div>
                <div tw="mb-1" style={styleobj}>•	Environmentally friendly due to the elimination of the Nip Roller grinding process</div>
                <div tw="mb-1" style={styleobj}>•	Lower Maintenance Costs
                <Paragraph1 tw="m-8" style={styleobj}>
                  <div tw="mb-1">•	Elimination of yarn wraps</div>
                  <div tw="mb-1">•	No tools required to change Apron Belts</div>
                  <div tw="mb-1">•	Machine operators can easily change Apron Belts</div>
                </Paragraph1>
                </div>
                <Paragraph1>
                  <div tw="mb-1" style={styleobj}>•	Yarn slippage eliminated when the yarn is traversing. Nip Roller alignment and the grinding process is critical to avoid yarn slippage</div>
                  <div tw="mb-1" style={styleobj}>•	Cost of Apron Belt up to 3 times lower than Nip Roller rubber cot</div>
                  <div tw="mb-1" style={styleobj}>•	Apron Belt lifetime dependant on deniers being processed
                    <Paragraph1 tw="m-8">
                      <div tw="mb-1" style={styleobj}>•	Fine Deniers 30 days</div>
                      <div tw="mb-1" style={styleobj}>•	Heavy deniers 90 days</div>
                      <div tw="mb-1" style={styleobj}>•	Users rotate Aprons Belts from 2<sup>nd</sup> delivery to 1<sup>st</sup> & 3<sup>rd</sup> deliveries to extend lifetime</div>
                    </Paragraph1>
                  </div>
                </Paragraph1>
              </Paragraph1>
              </div>: ''}
              {activeFeature === 8 ?
              <div>
                <SectionHeading>Yarn Cooling Tubes</SectionHeading>
                <br></br>
                <Paragraph1>
                  Yarn Cooling Tubes are available to retrofit and replace damaged Yarn Cooling Tracks. The Yarn Cooling Tubes offered have the following characteristics,
                </Paragraph1>
                <Paragraph1>
                    <table>
                      <tbody>
                        <tr><td><TextConSpan><strong>Length:</strong> </TextConSpan></td><td>Dependant on customer’s machine</td><td></td></tr>
                        <tr><td><TextConSpan><strong>Diameter:</strong></TextConSpan></td><td>38mm or 42mm</td><td></td></tr>
                        <tr><td><TextConSpan><strong>Finish:</strong></TextConSpan></td><td>Hardened and buffed</td><td></td></tr>
                        <tr><td><TextConSpan><strong>Ceramic Guides:</strong></TextConSpan></td><td>Entry and Exit</td><td></td></tr>
                        <tr><td><TextConSpan><strong>Lifetime:</strong></TextConSpan></td><td>Extended tube lifetime by rotating the tube</td><td></td></tr>
                      </tbody>
                    </table>
                </Paragraph1>
                <img tw="mr-6 ml-1 mt-8 mb-4 rounded-lg" src={yarncooling} alt="Design Illustration" />
              </div>: ''}
              {activeFeature === 9 ?
              <div>
                <SectionHeading>High Efficiency Cooling Track</SectionHeading>
                <br></br>
                <Paragraph1>
                High Efficiency Yarn Cooling Track conversions are available to replace damaged OEM cooling tracks. All cooling tracks will cut; the time taken depends mainly on the thread line alignment at the entry and exit of the cooling track and the type of yarn that is produced.
                </Paragraph1>
                <Paragraph1>
                Damaged tracks should to be replaced to ensure correct yarn properties are achieved and broken filaments are reduced. 
                </Paragraph1>
                <Paragraph1>
                The HE Yarn Cooling Track offers the following benefits,
                </Paragraph1>
                <Paragraph1 style={styleobj}>
                  <div>•	Increased cooling efficiency ensuring position to position uniformity</div>
                  <div>•	Optimised cross section ensures greater heat dissipation</div>
                  <div>•	Possibility to increase process speeds due to optimized cooling track curvature</div>
                  <div>•	Correct material to dissipate heat. </div>
                </Paragraph1>
                <Paragraph1>
                Below is a comparison of the thermal conductivity or heat transfer coefficient of aluminium and stainless steel at given temperatures,
                </Paragraph1>
                <img tw="mt-8 mb-4 rounded-lg" src={coolingtable} alt="Design Illustration" />
                <Paragraph1>
                <b>Standard cooling tracks available from all OEM’s are made from mild or stainless steel.</b>
                </Paragraph1>
                <Subheading1 tw="mt-8">High Efficiency Yarn Cooling Track Advantages</Subheading1>
                <Paragraph1 style={styleobj}>
                  <div>•	Single piece cooling zone</div>
                  <div>•	Eliminates frequent thread path alignment</div>
                  <div>•	Replaceable coated steel inserts</div>
                  <div>•	Optimised surface finish to minimise filamentation</div>
                  <div>•	'Track hardened to {">1000"} Vickers</div>
                  <div>•	Track yarn path finished to {"<0.03µm"} Ra</div>
                  <div>•	Ceramic guides at yarn entry & exit points to protect areas where typical damage occurs on standard cooling tracks</div>  
                  <div>•	Same track design available for all OEM DTY machines</div>
                </Paragraph1>
                <Subheading1 tw="mt-8">High Effiecency Cooling Track Conversion on Barmag AFK</Subheading1>
                <img tw="mt-8 mb-4 rounded-lg" src={cooling1} alt="Design Illustration" />                
                <Subheading1 tw="mt-8">Barmag AFK Standard Cooling Track</Subheading1>
                <img tw="mt-8 mb-4 rounded-lg" src={cooling2} alt="Design Illustration" />
                <img tw="mt-8 mb-4 rounded-lg" src={cooling} alt="Design Illustration" />
              </div>: ''}
              {activeFeature === 10 ?
              <div>
                <SectionHeading>Hot Pins</SectionHeading>
                <br></br>
                <Paragraph1>
                Hot Pins are available to fit all OEM machinery they are used to make yarns which appear thick & thin, thin & thick or have tight spots. 
                </Paragraph1>
                <Paragraph1 style={styleobj}>
                  <div tw="mb-4">Product Features:</div>
                  <div>•	Ability To Change Yarn Contact Length</div>
                  <div>•	Each Hot Pin Processes 2 yarns</div>
                  <div>•	Energy Efficient</div>
                  <div>•	Uniform Temperature</div>
                  <div>•	Micro Processor Temperature Control</div>
                  <div>•	PT 100 Temperature Sensor</div>
                  <div>•	0 to 250{<span>&deg;</span>}C Temperature Range</div>
                </Paragraph1>
                <img tw="mt-8 mb-10 rounded-lg" src={hotpins} alt="Hot Pins" />
                <SectionHeading>Rieter Scragg SDS900 Hot Pin Attachment</SectionHeading>
                <div tw="flex mt-8">
                  <div tw="flex flex-col items-center w-1/2">
                    <Subheading1 tw="text-center">Hot Pin Temperature Control</Subheading1>
                  </div>
                  <div tw="flex flex-col items-center w-1/2">
                    <Subheading1 tw="text-center">Hot Pin Attachment With Additional Feed Roller</Subheading1>
                  </div>
                </div>
                <div tw="flex">
                  <div>
                    <img src={hotpins1} alt="Design Illustration" />
                  </div>
                  <div>
                    <img src={hotpins2} alt="Design Illustration" />
                  </div>
                </div>
                <Subheading1 tw="mt-8" >Knitted Yarn Produced With Hot Pins</Subheading1>
                <img tw="mt-8 mb-4 rounded-lg" src={knittedyarns} alt="Design Illustration" />
              </div>: ''}
              {activeFeature === 12 ?
              <div>
                <SectionHeading tw="text-left">Pin Twist Texturing - Magnetic Pin Spindle</SectionHeading>
                <br></br>
                <Paragraph1>
                  Pin twisting enables the most sensitive of yarns to be processed with high stretch characteristics. The reduced filament damage with the Pin Twisting process results in good tensile and properties and high stretch properties.
                </Paragraph1>
                <Paragraph1>
                  Pin Twist technology is over 45 years old and is the predecessor to Friction Disc Texturing. Texturing machinery manufactures moved away from Pin Twisting to Friction Discs Texturing due to the advantages of process speeds.
                </Paragraph1>
                <Paragraph1>
                  Typically, the process speeds for a given yarn for Pin Texturing is 100 to 250mpm depending on the produce required. Friction Twisting process speeds for a given type can be from 800 to 1000mpm.
                </Paragraph1>
                <Paragraph1>
                  Companies producing stretch products like socks and end users making high end apparel still require Pin Textured products.
                </Paragraph1>
                <Paragraph1>
                  TSP’s expert knowledge of the process has enabled us to modify existing Magnetic Pin Spindle Units to reduce maintenance time, maintenance costs, reduce process breaks, and also increase process speeds.
                </Paragraph1>
                <Paragraph1>
                  Pin Texturing companies still use ferrite magnets to hold the twisting pin, the position of the input guide, magnet and exit guide relative to the pin are very important to the process. Mis-aligned components can lead to process breaks.
                </Paragraph1>
                <Paragraph1>
                  The holding force of the ferrite magnet is much lower compared to other magnet types commercially available. TSP’s modification to the existing Magnet Pin Spindle Units uses modern magnetic technology.
                </Paragraph1>
                <div>
                  <Subheading1>Original Design Magnetic Spindle</Subheading1>
                  <img tw="mr-6 ml-1 mt-8 mb-4 rounded-lg" src={magnetic} alt="Design Illustration" />
                </div>
                <div>
                  <Subheading1>TSP Modified Magnetic Spindle</Subheading1>
                  <img tw="mr-6 ml-1 mt-8 mb-10 rounded-lg" src={magnetic1} alt="Design Illustration" />
                </div>
                <SectionHeading tw="text-left">TSP Magnetic Spindle Unit Modification Detail</SectionHeading> 
                <Paragraph1>
                  The design of the modification reduces maintenance time, maintenance costs, reduces process breaks and has the possibility to increase process speeds. The modification is easy to install with one screw fitting.
                </Paragraph1>
                <div tw="flex mt-8 hidden md:flex">
                  <div tw="flex flex-col items-center w-1/3">
                    <Subheading1 tw="text-center">Original Magnetic Spindle Unit</Subheading1>
                    <img tw="mt-8 mb-4 rounded-lg" src={magnetic2} alt="Design Illustration" />
                  </div>
                  <div tw="flex flex-col items-center w-1/3">
                    <Subheading1 tw="text-center">Modified Magnetic Spindle Unit</Subheading1>
                    <img tw="mt-8 mb-4 rounded-lg" src={magnetic3} alt="Design Illustration" />
                  </div>
                  <div tw="flex flex-col items-center w-1/3">
                    <Subheading1 tw="text-center">Modified Magnetic Spindle Unit</Subheading1>
                    <img tw="mt-8 mb-4 rounded-lg" src={magnetic4} alt="Design Illustration" />
                  </div>
                </div>
                <div tw="flex mt-8 md:hidden flex-col items-center">
                    <Subheading1 tw="text-center">Original Magnetic Spindle Unit</Subheading1>
                    <img tw="mt-8 mb-4 rounded-lg" src={magnetic2} alt="Design Illustration" />
                  
                    <Subheading1 tw="text-center">Modified Magnetic Spindle Unit</Subheading1>
                    <img tw="mt-8 mb-4 rounded-lg" src={magnetic3} alt="Design Illustration" />

                    <Subheading1 tw="text-center">Modified Magnetic Spindle Unit</Subheading1>
                    <img tw="mt-8 mb-4 rounded-lg" src={magnetic4} alt="Design Illustration" />
                </div>
              </div>: ''}
              {activeFeature === 13 ?
              <div>
                <SectionHeading tw="text-left">Positorq 2a 6mm to 9mm Disc Conversion</SectionHeading>
                <br></br>
                <Paragraph1>
                TSP innovative design enables users of Rieter Scragg to convert the Positorq 2a friction unit to utilise the commercially accepted 9mm thick working discs. The conversion will give customers the following benefits,
                </Paragraph1>
                <Paragraph1 style={styleobj}>
                  <div>•	Uses industry standard 52.0 or 52.5mm diameter x 9mm thick discs</div>
                  <div>•	Longer lifetime of PU discs</div>
                  <div>•	Reduces spindle rpm</div>
                  <div>•	Potential to increase process speeds</div>
                  <div>•  Potential to increase process efficiency</div>
                  <div>•	Enables customers to make similar products which are processed with 9mm discs </div>
                  <div>•	Reduces inventory of different disc sizes </div>
                  <div>•	Conversion utilises the complete Positorq drive system </div>
                  <div>•	Conversion utilises the majority of the components of the Positorq 2a friction unit</div>                
                </Paragraph1>
                <Paragraph1>
                Companies who have Himson SDS700 or CX machines equipped with the friction unit design the same as Positorq 2a running 6mm discs can also benefit from this conversion.
                </Paragraph1>
                <Paragraph1>
                The P2XL unit generates optimised twist levels for both process speed and product quality.
                </Paragraph1>
                <Paragraph1>
                Comparisons of twist levels generated by other types of friction units are shown below. 
                </Paragraph1>

                <Subheading1 tw="mt-8">150 Denier PE Twist Level Comparison</Subheading1>
                <img tw="mt-8 mb-4 rounded-lg" src={frictiongraph} alt="Design Illustration" />
                <Subheading1>P2XL ASSEMBLY</Subheading1>
                <img tw="mt-8 mb-4 rounded-lg" src={friction} alt="Design Illustration" />
                <img tw="mt-8 mb-4 rounded-lg" src={friction2} alt="Design Illustration" />
              </div>: ''}
            </TabInnerContent>
          </TabInnerDiv>
        </div>
      </ContentWithPaddingXl>
      <DecoratorBlob2 />
    </Container>
  );
};