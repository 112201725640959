import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {

    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const Container1 = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;
const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;
const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;
const MAINDIV=tw.div`flex mb-4`;
const DIV500=tw.div`w-1/4 bg-gray-500 h-12`;
const DIV400=tw.div`w-1/4 bg-gray-400 h-12`;
const DIV=tw.div`flex-1 bg-gray-500 h-12`;
const BUTTON=tw.button`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full`;
export default ({ headingText = "TSP Developed Fancy Yarn Systems" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
           <p>
           Our expert knowledge of the false twist texturing process has enabled us to develop a portfolio of systems that will enable users to develop unique fancy yarns on their texturing machines. Please click the links below for more information.
            </p> 
<br/>


 
  

 <Container1>   
         <Content>
<SixColumns>
  <Column>
  <LinkList>
  <LinkListItem>
  <Link href="#">Spandex Feed Attachment</Link>
  </LinkListItem>
  <LinkListItem>
  <Link href="#">MagTex</Link>
  </LinkListItem>
  </LinkList>
  </Column>


  <Column>
  <LinkList>
  <LinkListItem>
  <Link href="#">Hot Pins</Link>
  </LinkListItem>
  <LinkListItem>
  <Link href="#">Slub Tex</Link>
  </LinkListItem>
  </LinkList>
  </Column>
  <Column>
  <LinkList>
  <LinkListItem>
  <Link href="#">Torq Tex</Link>
  </LinkListItem>
  <LinkListItem>
  <Link href="#">Core & Effect</Link>
  </LinkListItem>
  </LinkList>
  </Column>
  <Column>
  <LinkList>
  <LinkListItem>
  <Link href="#">PulseTex</Link>
  </LinkListItem>
  <LinkListItem>
  <Link href="#">MagTex</Link>
  </LinkListItem>
  </LinkList>
  </Column>
</SixColumns>
</Content>
</Container1>
           
           

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
