import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import TypicalFancy1 from "images/Typical Fancy Yarns1.jpg";
import TypicalFancy2 from "images/Typical Fancy Yarns2.jpg";
import TypicalFancy3 from "images/Typical Fancy Yarns3.jpg";

const HeadingRow = tw.div`flex`;
const Image = tw.img`max-w-full rounded-t sm:rounded`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {

    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Typical Fancy Yarns" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            

            <p>
            Typically the fancy yarns below, are produced with or without small modifications of existing machinery or installing additional feed rollers, hot pins, spandex feed systems, air texturing jets, slubbing systems etc.
            </p>

            <p>
            The fancy yarn list below is not comprehensive and other types of fancy yarns can be made with special hardware and software manufactured by 
               <b>Textile Solutions Professional Ltd.</b>
            </p>
            <Image src={TypicalFancy1}/>
            <Image src={TypicalFancy2}/>
            <Image src={TypicalFancy3}/>
           
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
