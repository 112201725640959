import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import Dropdown from "../misc/Dropdown.js";

import {
  Link
} from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/onlyLogo.jpg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex flex-col justify-between
  max-w-screen-xl mx-auto
`;

export const RouteNavLink = tw(Link)`
px-4 w-full xl:px-4 lg:px-2 lg:mt-0 mt-2 py-3 mr-2 rounded bg-primary-500 text-gray-100
hocus:bg-secondary-700 hocus:text-gray-200 focus:shadow-outline
border-b-0 rounded-full
`;

export const RouteNavSecLink = tw(Link)`
flex items-center px-12 xl:px-10 lg:px-2 -mt-2 rounded bg-blue-500 text-gray-100
hocus:bg-secondary-700 hocus:text-gray-200 focus:shadow-outline
border-b-0
`;

export const NavLinks = tw.div`inline-block xl:w-auto`;
export const SecNavLinks = tw.div`flex justify-around w-full bg-primary-100 rounded-sm`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex pt-1 items-center font-black border-b-0 text-2xl! ml-0! mr-2!`};

  img {
    ${tw`w-14`}
  }
`;

export const LogoContainer = tw.div`flex`;
export const LogoText = styled(NavLink)`
  ${tw`flex underline cursor-pointer font-semibold border-b-0 text-3xl! ml-0!`};
`;

export const LogoText2 = styled(NavLink)`
  ${tw`flex absolute cursor-pointer border-b-0 text-lg! ml-0! mr-0!`};
`;

const logoBottomStyle = {
  top: '34px',
  left: '66px'
};

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center mt-4`}
  }
`);

export const DesktopNavLinks = tw.nav`
  relative hidden lg:flex flex-1 justify-between items-center mb-4
`;

export const SecondaryNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center mt-8
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const defaultLinks = [
    <NavLinks key={1}>
      <RouteNavLink to="/"><span tw="px-4">Home</span></RouteNavLink>
      <RouteNavLink to="/about">About Us</RouteNavLink>
      <RouteNavLink to="/ContactUs">Contact Us</RouteNavLink>
     
      {/* <NavLink href="/#" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="/#">Sign Up</PrimaryLink> */}
    </NavLinks>
  ];

  const mobileLinks = [
    <NavLinks key={1}>
      <RouteNavLink to="/"><span tw="px-4">Home</span></RouteNavLink>
      <RouteNavLink to="/about">About Us</RouteNavLink>
      <RouteNavLink to="/ContactUs">Contact Us</RouteNavLink> 
      <RouteNavLink to="/SpareParts">Spare Parts</RouteNavLink>
      <RouteNavLink to="/Attachments">Attachments & Conversions</RouteNavLink>
      <RouteNavLink to="/Consultancy">Consulting Services</RouteNavLink>
      <RouteNavLink to="/FancyYarn">Fancy Yarn Solutions</RouteNavLink>
      <RouteNavLink to="/ElectronicRepairs">Electronic Repair Service</RouteNavLink>
    </NavLinks>
  ]

  const secondaryLinks = [
    <SecNavLinks key={1}>
      <Dropdown title="Spare Parts" />
      <RouteNavSecLink to="/Attachments">Attachments and Conversions</RouteNavSecLink>
      <RouteNavSecLink to="/Consultancy">Consulting Services</RouteNavSecLink>
      <RouteNavSecLink to="/FancyYarn">Fancy Yarn Solutions</RouteNavSecLink>
      <RouteNavSecLink to="/ElectronicRepairs">Electronic Repair Service</RouteNavSecLink>
    </SecNavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || mobileLinks;

  return (
    <Header className={className || "header-light"}>
      <div>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          <LogoContainer>
            {logoLink}
            <LogoText>Textile Solutions Professional Ltd.</LogoText>
            <LogoText2 style={logoBottomStyle} >Value Added Products & Services for the Fiber Business</LogoText2>
          </LogoContainer>
          {defaultLinks}
        </DesktopNavLinks>

        <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
          {logoLink}
          <MobileNavLinks onClick={toggleNavbar} initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
            {links}
          </MobileNavLinks>
          <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
            {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
          </NavToggle>
        </MobileNavLinksContainer>
      </div>
      <SecondaryNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {secondaryLinks}
      </SecondaryNavLinks>
    </Header>

  
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
