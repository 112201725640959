import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading, Subheading1 } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase, PrimaryButton } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import SinglePageViewer from "components/misc/SinglePage.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import Select from 'react-select';

import diverse from 'images/ceramicguides/Diverse.pdf';
import rods from 'images/ceramicguides/Rods.pdf';
import pigTails from 'images/ceramicguides/Pigtails.pdf';
import oilingNozzles from 'images/ceramicguides/OilingNozzles.pdf';
import ceramicAssemblies from 'images/ceramicguides/CeramicAssemblies.pdf';
import eyelets from 'images/ceramicguides/Eyelets.pdf';
import openGuides from 'images/ceramicguides/OpenGuides.pdf';
import rollerGuides from 'images/ceramicguides/RollerGuides.pdf';
import trapGuides from 'images/ceramicguides/TrapGuides.pdf';
import airNozzles from 'images/ceramicguides/AirNozzles.pdf';
import traverse from 'images/ceramicguides/Traverse.pdf';
import hooks from 'images/ceramicguides/Hooks.pdf';

import rollers1 from 'images/rollers/Take Up Rollers 1.jpg';
import rollers2 from 'images/rollers/Take Up Rollers 2.jpg';
import rollers3 from 'images/rollers/Take Up Rollers 3.jpg';
import rollers4 from 'images/rollers/Take Up Rollers 4.jpg';
import rollers5 from 'images/rollers/Take Up Rollers 5.jpg';
import rollers6 from 'images/rollers/Take Up Rollers 6.jpg';
import rollers7 from 'images/rollers/Take Up Rollers 7.jpg';
import rollers8 from 'images/rollers/Take Up Rollers 8.jpg';
import rollers9 from 'images/rollers/Take Up Rollers 9.jpg';
import rollers10 from 'images/rollers/Take Up Rollers 10.jpg';
import rollers11 from 'images/rollers/Take Up Rollers 11.jpg';
import rollers12 from 'images/rollers/Take Up Rollers 12.jpg';
import rollers13 from 'images/rollers/Take Up Rollers 13.jpg';
import rollers14 from 'images/rollers/Take Up Rollers 14.jpg';

import AirNozzle from "images/ceramicguides/AirNozzles.jpg";
import Diverse from "images/ceramicguides/Diverse.jpg";
import Eyelet from "images/ceramicguides/Eyelet.jpg";
import GuideAssembly from "images/ceramicguides/GuideAssembly.jpg";
import Hook from "images/ceramicguides/Hook.jpg";
import OilingNozzle from "images/ceramicguides/OilingNozzles.jpg";
import OpenType from "images/ceramicguides/OpenType.jpg";
import PigTail from "images/ceramicguides/PigTail.jpg";
import RodsAndTubes from "images/ceramicguides/RodsAndTubes.jpg";
import Roller from "images/ceramicguides/Roller.jpg";
import Trap from "images/ceramicguides/Trap.jpg";
import Traverse from "images/ceramicguides/Traverses.jpg";

import twistedYarns from "images/ceramicguides/twistedYarn.jpg";
import deTwistedYarns from "images/ceramicguides/deTwistedYarn.jpg";
import heberlein from "images/jets/heberlein.jpeg";
import dyetubes from "images/dye/dyetubes.jpg";

//Polyurethane discs
import poly1 from "images/polyurethandiscs/poly1.jpg";
import poly2 from "images/polyurethandiscs/poly2.jpg";
import polytable from "images/polyurethandiscs/polytable.jpg";
import poly3 from "images/polyurethandiscs/poly3.jpg";
import poly4 from "images/polyurethandiscs/poly4.jpg";
import poly5 from "images/polyurethandiscs/poly5.jpg";
import poly6 from "images/polyurethandiscs/poly6.jpg";
import poly41 from "images/polyurethandiscs/poly41.jpg";
import poly42 from "images/polyurethandiscs/poly42.jpg";

//ceramic friction discs
import ceramicfriction from "images/ceramicfriction/ceramicfriction.jpg";
import inputguide from "images/ceramicfriction/inputguide.jpg";
import cdprofile from "images/ceramicfriction/cdprofile.jpg";
import exitknife from "images/ceramicfriction/exitknife.jpg";

//Polymer Filtration
import spinpacks1 from "images/filtration/spinpacks1.jpg";
import spinpacks2 from "images/filtration/spinpacks2.jpg";
import gasket1 from "images/filtration/gasket1.jpg";
import gasket2 from "images/filtration/gasket2.jpg";
import screenfilter from "images/filtration/screenfilter.jpg";
import spinpackfilter from "images/filtration/spinpackfilter.jpg";
import spinnerets from "images/filtration/spinnerets.jpg";
import spinnerets1 from "images/filtration/spinnerets1.jpg";
import spinnerets2 from "images/filtration/spinnerets2.jpg";
import spinnerets3 from "images/filtration/spinnerets3.jpg";
import spinnerets4 from "images/filtration/spinnerets4.jpg";
import spinnerets5 from "images/filtration/spinnerets5.jpg";

import spinpackmedia from "images/filtration/spinpackmedia.jpg";
import spinpackmedia1 from "images/filtration/spinpackmedia1.jpg";
import spinpackmedia2 from "images/filtration/spinpackmedia2.jpg";
import spinpackmedia3 from "images/filtration/spinpackmedia3.jpg";

import plainweave from "images/filtration/plainweave.jpg";
import twilledweave from "images/filtration/twilledweave.jpg";
import plaindutchweave from "images/filtration/plaindutchweave.jpg";
import dutchtwillweave from "images/filtration/dutchtwillweave.jpg";

import candlefilters from "images/filtration/candlefilters.jpg";
import candlefilters2 from "images/filtration/candlefilters1.jpg";

import filter1 from "images/filtration/filter1.jpg";
import filter4 from "images/filtration/filter4.jpg";
import filter5 from "images/filtration/filter5.jpg";
import plant1 from "images/filtration/plant1.jpg";
import machine from "images/filtration/machine.jpg";

import filter6 from "images/filtration/filter6.jpg";
import disc1 from "images/filtration/disc1.jpg";
import disc2 from "images/filtration/disc2.jpg";
import disc3 from "images/filtration/disc3.jpg";

// import filter4 from "images/filtration/poly3.jpg";
// import filter5 from "images/filtration/poly4.jpg";
// import filter6 from "images/filtration/poly5.jpg";
// import filter7 from "images/filtration/poly6.jpg";
// import filter8 from "images/filtration/poly41.jpg";
// import filter9 from "images/filtration/poly42.jpg";


import cots1 from "images/cots/cots1.jpg";
import cots2 from "images/cots/cots2.jpg";
import cots3 from "images/cots/cots3.jpg";
import cots4 from "images/cots/cots4.jpg";

//Yarn cutters
import barmagafkCutter from "images/yarncutters/Barmag AFK Cutter.jpg";
import barmagafk6Cutter from "images/yarncutters/Barmag FK6 Cutter.jpg";
import giudiciCutter from "images/yarncutters/Giudici Cutter.jpg";
import icbtClipOnCutter from "images/yarncutters/ICBT Clip On Cutter.jpg";
import icbtCutter from "images/yarncutters/ICBT Cutter.jpg";
import rprCutter from "images/yarncutters/RPR Cutter.jpg";
import scraggCutter from "images/yarncutters/Scragg Cutter.jpg";

//Yarn sensors
import ET028 from "images/yarnsensors/ET028 new.jpg";
import ET029 from "images/yarnsensors/ET029 new.jpg";
import ET030 from "images/yarnsensors/ET030 new.jpg";
import ET062 from "images/yarnsensors/ET062 new.jpg";
import ET069 from "images/yarnsensors/ET069 new.jpg";
import ET070 from "images/yarnsensors/ET070 new.jpg";
import ET071 from "images/yarnsensors/ET071 new.jpg";
import ET071C from "images/yarnsensors/ET071C new.jpg";
import ET077 from "images/yarnsensors/ET077 new.jpg";
import ET078 from "images/yarnsensors/ET078 new.jpg";
import ET078N from "images/yarnsensors/ET078-N new.jpg";
import ET227 from "images/yarnsensors/ET227 new.jpg";
import ET262 from "images/yarnsensors/ET262 new.jpg";

import ET067 from "images/yarnsensors2/ET067 new.jpg";
import ET068 from "images/yarnsensors2/ET068 new.jpg";
import ET188 from "images/yarnsensors2/ET188 new.jpg";
import ET1883 from "images/yarnsensors2/ET188-3new.jpg";
import ET1884 from "images/yarnsensors2/ET188-4 new.jpg";
import ET188A from "images/yarnsensors2/ET188-A new.jpg";
import ET226 from "images/yarnsensors2/ET226 new.jpg";

//Nip Roller Bearings
import barmagRoller from "images/niprollerbearing/Barmag Nip Roller Assembly.jpg";
import barmagBearing from "images/niprollerbearing/Barmag Nip Roller Bearing.jpg";
import scraggRoller from "images/niprollerbearing/Scragg Nip Roller Assembly.jpg";
import scraggBearing from "images/niprollerbearing/Scragg Nip Roller Bearing -.jpg";
import scraggBearing2 from "images/niprollerbearing/Scragg Nip Roller Bearing --.jpg";
import scraggBearing3 from "images/niprollerbearing/Scragg Nip Roller Bearing.jpg";

//Friction bearings
import friction1 from "images/frictionunitspindlebearings/28T Drive.jpg";
import friction2 from "images/frictionunitspindlebearings/28T Driven.jpg";
import friction3 from "images/frictionunitspindlebearings/Barmag FK6 1000 Drive.jpg";
import friction4 from "images/frictionunitspindlebearings/Barmag FK6 1000 Driven.jpg";
import friction5 from "images/frictionunitspindlebearings/Barmag FK6 700 Drive.jpg";
import friction6 from "images/frictionunitspindlebearings/Barmag FK6 700 Driven-.jpg";
import friction7 from "images/frictionunitspindlebearings/Barmag FK6 700 Driven.jpg";
import friction8 from "images/frictionunitspindlebearings/Barmag FK6 Spindle --.jpg";
import friction9 from "images/frictionunitspindlebearings/Barmag Spindle -.jpg";
import friction10 from "images/frictionunitspindlebearings/Barmag Spindle.jpg";
import friction11 from "images/frictionunitspindlebearings/ICBT 31T Drive.jpg";
import friction12 from "images/frictionunitspindlebearings/ICBT 31T Driven.jpg";
import friction13 from "images/frictionunitspindlebearings/RPR 26T Drive.jpg";
import friction14 from "images/frictionunitspindlebearings/RPR 26T Driven.jpg";
import friction15 from "images/frictionunitspindlebearings/Scragg 18T Drive.jpg";
import friction16 from "images/frictionunitspindlebearings/Scragg 18T Driven.jpg";

import jockey1 from "images/jockeypulleys/Barmag FK6 Jockey Pulley.jpg";
import jockey2 from "images/jockeypulleys/ET085 new.jpg";
import jockey3 from "images/jockeypulleys/ET085A new.jpg";
import jockey4 from "images/jockeypulleys/ICBT Jockey Pulley-.jpg";
import jockey5 from "images/jockeypulleys/ICBT Jockey Pulley.jpg";
import jockey6 from "images/jockeypulleys/Jockey Pulley.jpg";
import jockey7 from "images/jockeypulleys/Scragg Jockey Pulley.jpg";

import barmagmob1 from "images/barmag/mobile/barmag1a1.jpg";
import barmagmob2 from "images/barmag/mobile/barmag1a2.jpg";
import barmagmob3 from "images/barmag/mobile/barmag1a3.jpg";
import barmagmob4 from "images/barmag/mobile/barmag1b1.jpg";
import barmagmob5 from "images/barmag/mobile/barmag1b2.jpg";
import barmagmob6 from "images/barmag/mobile/barmag1b3.jpg";
import barmagmob7 from "images/barmag/mobile/barmag1c1.jpg";
import barmagmob8 from "images/barmag/mobile/barmag1c2.jpg";
import barmagmob9 from "images/barmag/mobile/barmag1c3.jpg";
import barmagmob10 from "images/barmag/mobile/barmag2a1.jpg";
import barmagmob11 from "images/barmag/mobile/barmag2a2.jpg";
import barmagmob12 from "images/barmag/mobile/barmag2a3.jpg";
import barmagmob13 from "images/barmag/mobile/barmag2b1.jpg";
import barmagmob14 from "images/barmag/mobile/barmag2b2.jpg";
import barmagmob15 from "images/barmag/mobile/barmag2b3.jpg";
import barmagmob16 from "images/barmag/mobile/barmag2c1.jpg";
import barmagmob17 from "images/barmag/mobile/barmag2c2.jpg";
import barmagmob18 from "images/barmag/mobile/barmag2c3.jpg";
import barmagmob19 from "images/barmag/mobile/Barmag 3a1.jpg";
import barmagmob20 from "images/barmag/mobile/Barmag 3a2.jpg";
import barmagmob21 from "images/barmag/mobile/Barmag 3a3.jpg";
import barmagmob22 from "images/barmag/mobile/Barmag 3b1.jpg";
import barmagmob23 from "images/barmag/mobile/Barmag 3b2.jpg";
import barmagmob24 from "images/barmag/mobile/Barmag 3b3.jpg";
import barmagmob25 from "images/barmag/mobile/Barmag 3c1.jpg";
import barmagmob26 from "images/barmag/mobile/Barmag 3c2.jpg";
import barmagmob27 from "images/barmag/mobile/Barmag 3c3.jpg";
import barmagmob28 from "images/barmag/mobile/Barmag 4a1.jpg";
import barmagmob29 from "images/barmag/mobile/Barmag 4a2.jpg";
import barmagmob30 from "images/barmag/mobile/Barmag 4a3.jpg";
import barmagmob32 from "images/barmag/mobile/Barmag 4b1.jpg";
import barmagmob33 from "images/barmag/mobile/Barmag 4b2.jpg";
import barmagmob34 from "images/barmag/mobile/Barmag 4b3.jpg";
import barmagmob35 from "images/barmag/mobile/Barmag 4c1.jpg";
import barmagmob36 from "images/barmag/mobile/Barmag 4c2.jpg";
import barmagmob37 from "images/barmag/mobile/Barmag 4c3.jpg";
import barmagmob38 from "images/barmag/mobile/Barmag 5a1.jpg";
import barmagmob40 from "images/barmag/mobile/Barmag 5a2.jpg";
import barmagmob41 from "images/barmag/mobile/Barmag 5a3.jpg";
import barmagmob42 from "images/barmag/mobile/Barmag 5b1.jpg";
import barmagmob43 from "images/barmag/mobile/Barmag 5b2.jpg";
import barmagmob44 from "images/barmag/mobile/Barmag 5b3.jpg";
import barmagmob45 from "images/barmag/mobile/Barmag 5c1.jpg";
import barmagmob46 from "images/barmag/mobile/Barmag 5c2.jpg";
import barmagmob47 from "images/barmag/mobile/Barmag 5c3.jpg";
import barmagmob48 from "images/barmag/mobile/Barmag 6a1.jpg";
import barmagmob50 from "images/barmag/mobile/Barmag 6a2.jpg";
import barmagmob51 from "images/barmag/mobile/Barmag 6a3.jpg";
import barmagmob68 from "images/barmag/mobile/Barmag 6b1.jpg";
import barmagmob69 from "images/barmag/mobile/Barmag 6b2.jpg";
import barmagmob70 from "images/barmag/mobile/Barmag 6b3.jpg";
import barmagmob71 from "images/barmag/mobile/Barmag 6c1.jpg";
import barmagmob72 from "images/barmag/mobile/Barmag 6c2.jpg";
import barmagmob73 from "images/barmag/mobile/Barmag 6c3.jpg";
import barmagmob52 from "images/barmag/mobile/Barmag 7a1.jpg";
import barmagmob53 from "images/barmag/mobile/Barmag 7a2.jpg";
import barmagmob54 from "images/barmag/mobile/Barmag 7a3.jpg";
import barmagmob55 from "images/barmag/mobile/Barmag 7b1.jpg";
import barmagmob56 from "images/barmag/mobile/Barmag 7b2.jpg";
import barmagmob57 from "images/barmag/mobile/Barmag 7b3.jpg";
import barmagmob58 from "images/barmag/mobile/Barmag 7c1.jpg";
import barmagmob60 from "images/barmag/mobile/Barmag 7c2.jpg";
import barmagmob61 from "images/barmag/mobile/Barmag 7c3.jpg";
import barmagmob62 from "images/barmag/mobile/Barmag 8a1.jpg";
import barmagmob63 from "images/barmag/mobile/Barmag 8a2.jpg";
import barmagmob64 from "images/barmag/mobile/Barmag 8a3.jpg";
import barmagmob65 from "images/barmag/mobile/Barmag 8b1.jpg";
import barmagmob66 from "images/barmag/mobile/Barmag 8b2.jpg";
import barmagmob67 from "images/barmag/mobile/Barmag 8b3.jpg";

import barmag1 from "images/barmag/Barmag 1a.jpg";
import barmag2 from "images/barmag/Barmag 1b.jpg";
import barmag3 from "images/barmag/Barmag 1c.jpg";
import barmag4 from "images/barmag/Barmag 2a.jpg";
import barmag5 from "images/barmag/Barmag 2b.jpg";
import barmag6 from "images/barmag/Barmag 2c.jpg";
import barmag7 from "images/barmag/Barmag 3a.jpg";
import barmag8 from "images/barmag/Barmag 3b.jpg";
import barmag9 from "images/barmag/Barmag 3c.jpg";
import barmag10 from "images/barmag/Barmag 4a.jpg";
import barmag11 from "images/barmag/Barmag 4b.jpg";
import barmag12 from "images/barmag/Barmag 4c.jpg";
import barmag13 from "images/barmag/Barmag 5a.jpg";
import barmag14 from "images/barmag/Barmag 5b.jpg";
import barmag15 from "images/barmag/Barmag 5c.jpg";
import barmag16 from "images/barmag/Barmag 6a.jpg";
import barmag17 from "images/barmag/Barmag 6b.jpg";
import barmag18 from "images/barmag/Barmag 6c.jpg";
import barmag19 from "images/barmag/Barmag 7a.jpg";
import barmag20 from "images/barmag/Barmag 7b.jpg";
import barmag21 from "images/barmag/Barmag 7c.jpg";
import barmag22 from "images/barmag/Barmag 8a.jpg";
import barmag23 from "images/barmag/Barmag 8b.jpg";

import Aalidhra1 from "images/Aalidhra/Aalidhra1.jpg";
import Aalidhra2 from "images/Aalidhra/Aalidhra2.jpg";
import Aalidhra3 from "images/Aalidhra/Aalidhra3.jpg";
import Aalidhra4 from "images/Aalidhra/Aalidhra4.jpg";
import Aalidhra5 from "images/Aalidhra/Aalidhra5.jpg";
import Aalidhra6 from "images/Aalidhra/Aalidhra6.jpg";
import Aalidhra7 from "images/Aalidhra/Aalidhra7.jpg";
import Aalidhra8 from "images/Aalidhra/Aalidhra8.jpg";
import Aalidhra9 from "images/Aalidhra/Aalidhra9.jpg";
import Aalidhra10 from "images/Aalidhra/Aalidhra10.jpg";
import Aalidhra11 from "images/Aalidhra/Aalidhra11.jpg";
import Aalidhra12 from "images/Aalidhra/Aalidhra12.jpg";
import Aalidhra13 from "images/Aalidhra/Aalidhra13.jpg";
import Aalidhra14 from "images/Aalidhra/Aalidhra14.jpg";
import Aalidhra15 from "images/Aalidhra/Aalidhra15.jpg";
import Aalidhra16 from "images/Aalidhra/Aalidhra16.jpg";
import Aalidhra17 from "images/Aalidhra/Aalidhra17.jpg";
import Aalidhra18 from "images/Aalidhra/Aalidhra18.jpg";

import Aalidhra1a1 from "images/Aalidhra/mobile/Aalidhra1a1.jpg";
import Aalidhra1a2 from "images/Aalidhra/mobile/Aalidhra1a2.jpg";
import Aalidhra2a1 from "images/Aalidhra/mobile/Aalidhra2a1.jpg";
import Aalidhra2a2 from "images/Aalidhra/mobile/Aalidhra2a2.jpg";
import Aalidhra3a1 from "images/Aalidhra/mobile/Aalidhra3a1.jpg";
import Aalidhra3a2 from "images/Aalidhra/mobile/Aalidhra3a2.jpg";
import Aalidhra4a1 from "images/Aalidhra/mobile/Aalidhra4a1.jpg";
import Aalidhra4a2 from "images/Aalidhra/mobile/Aalidhra4a2.jpg";
import Aalidhra5a1 from "images/Aalidhra/mobile/Aalidhra5a1.jpg";
import Aalidhra5a2 from "images/Aalidhra/mobile/Aalidhra5a2.jpg";
import Aalidhra6a1 from "images/Aalidhra/mobile/Aalidhra6a1.jpg";
import Aalidhra6a2 from "images/Aalidhra/mobile/Aalidhra6a2.jpg";
import Aalidhra7a1 from "images/Aalidhra/mobile/Aalidhra7a1.jpg";
import Aalidhra7a2 from "images/Aalidhra/mobile/Aalidhra7a2.jpg";
import Aalidhra8a1 from "images/Aalidhra/mobile/Aalidhra8a1.jpg";
import Aalidhra8a2 from "images/Aalidhra/mobile/Aalidhra8a2.jpg";
import Aalidhra9a1 from "images/Aalidhra/mobile/Aalidhra9a1.jpg";
import Aalidhra9a2 from "images/Aalidhra/mobile/Aalidhra9a2.jpg";
import Aalidhra10a1 from "images/Aalidhra/mobile/Aalidhra10a1.jpg";
import Aalidhra10a2 from "images/Aalidhra/mobile/Aalidhra10a2.jpg";
import Aalidhra11a1 from "images/Aalidhra/mobile/Aalidhra11a1.jpg";
import Aalidhra11a2 from "images/Aalidhra/mobile/Aalidhra11a2.jpg";
import Aalidhra12a1 from "images/Aalidhra/mobile/Aalidhra12a1.jpg";
import Aalidhra12a2 from "images/Aalidhra/mobile/Aalidhra12a2.jpg";
import Aalidhra13a1 from "images/Aalidhra/mobile/Aalidhra13a1.jpg";
import Aalidhra13a2 from "images/Aalidhra/mobile/Aalidhra13a2.jpg";
import Aalidhra14a1 from "images/Aalidhra/mobile/Aalidhra14a1.jpg";
import Aalidhra14a2 from "images/Aalidhra/mobile/Aalidhra14a2.jpg";
import Aalidhra15a1 from "images/Aalidhra/mobile/Aalidhra15a1.jpg";
import Aalidhra15a2 from "images/Aalidhra/mobile/Aalidhra15a2.jpg";
import Aalidhra16a1 from "images/Aalidhra/mobile/Aalidhra16a1.jpg";
import Aalidhra16a2 from "images/Aalidhra/mobile/Aalidhra16a2.jpg";
import Aalidhra17a1 from "images/Aalidhra/mobile/Aalidhra17a1.jpg";
import Aalidhra17a2 from "images/Aalidhra/mobile/Aalidhra17a2.jpg";
import Aalidhra18a1 from "images/Aalidhra/mobile/Aalidhra18a1.jpg";
import Aalidhra18a2 from "images/Aalidhra/mobile/Aalidhra18a2.jpg";

import rpr1 from "images/rprgiudici/RPR 1a.jpg";
import rpr2 from "images/rprgiudici/RPR 1b.jpg";
import rpr3 from "images/rprgiudici/RPR 1c.jpg";
import rpr4 from "images/rprgiudici/RPR 2a.jpg";
import rpr5 from "images/rprgiudici/RPR 2b.jpg";

import rprmob1 from "images/rprgiudici/mobile/RPR 1a1.jpg";
import rprmob2 from "images/rprgiudici/mobile/RPR 1a2.jpg";
import rprmob3 from "images/rprgiudici/mobile/RPR 1a3.jpg";
import rprmob4 from "images/rprgiudici/mobile/RPR 1b1.jpg";
import rprmob5 from "images/rprgiudici/mobile/RPR 1b2.jpg";
import rprmob6 from "images/rprgiudici/mobile/RPR 1b3.jpg";
import rprmob7 from "images/rprgiudici/mobile/RPR 1c1.jpg";
import rprmob8 from "images/rprgiudici/mobile/RPR 1c2.jpg";
import rprmob9 from "images/rprgiudici/mobile/RPR 1c3.jpg";
import rprmob10 from "images/rprgiudici/mobile/RPR 2a1.jpg";
import rprmob11 from "images/rprgiudici/mobile/RPR 2a2.jpg";
import rprmob12 from "images/rprgiudici/mobile/RPR 2a3.jpg";
import rprmob13 from "images/rprgiudici/mobile/RPR 2b1.jpg";
import rprmob14 from "images/rprgiudici/mobile/RPR 2b2.jpg";
import rprmob15 from "images/rprgiudici/mobile/RPR 2b3.jpg";

import icbt1 from "images/icbt/ICBT 1a.jpg";
import icbt2 from "images/icbt/ICBT 1b.jpg";
import icbt3 from "images/icbt/ICBT 1c.jpg";
import icbt4 from "images/icbt/ICBT 2a.jpg";
import icbt5 from "images/icbt/ICBT 2b.jpg";
import icbt6 from "images/icbt/ICBT 2c.jpg";
import icbt7 from "images/icbt/ICBT 3a.jpg";
import icbt8 from "images/icbt/ICBT 3b.jpg";
import icbt9 from "images/icbt/ICBT 3c.jpg";

//icbt mobile
import icbtmob1 from "images/icbt/mobile/ICBT 1a1.jpg";
import icbtmob2 from "images/icbt/mobile/ICBT 1a2.jpg";
import icbtmob3 from "images/icbt/mobile/ICBT 1a3.jpg";
import icbtmob4 from "images/icbt/mobile/ICBT 1b1.jpg";
import icbtmob5 from "images/icbt/mobile/ICBT 1b2.jpg";
import icbtmob6 from "images/icbt/mobile/ICBT 1b3.jpg";
import icbtmob7 from "images/icbt/mobile/ICBT 1c1.jpg";
import icbtmob8 from "images/icbt/mobile/ICBT 1c2.jpg";
import icbtmob9 from "images/icbt/mobile/ICBT 1c3.jpg";
import icbtmob10 from "images/icbt/mobile/ICBT 2a1.jpg";
import icbtmob11 from "images/icbt/mobile/ICBT 2a2.jpg";
import icbtmob12 from "images/icbt/mobile/ICBT 2a3.jpg";
import icbtmob13 from "images/icbt/mobile/ICBT 2b1.jpg";
import icbtmob14 from "images/icbt/mobile/ICBT 2b2.jpg";
import icbtmob15 from "images/icbt/mobile/ICBT 2b3.jpg";
import icbtmob16 from "images/icbt/mobile/ICBT 2c1.jpg";
import icbtmob17 from "images/icbt/mobile/ICBT 2c2.jpg";
import icbtmob18 from "images/icbt/mobile/ICBT 2c3.jpg";
import icbtmob19 from "images/icbt/mobile/ICBT 3a1.jpg";
import icbtmob20 from "images/icbt/mobile/ICBT 3a2.jpg";
import icbtmob21 from "images/icbt/mobile/ICBT 3a3.jpg";
import icbtmob22 from "images/icbt/mobile/ICBT 3b1.jpg";
import icbtmob23 from "images/icbt/mobile/ICBT 3b2.jpg";
import icbtmob24 from "images/icbt/mobile/ICBT 3b3.jpg";
import icbtmob25 from "images/icbt/mobile/ICBT 3c1.jpg";
import icbtmob26 from "images/icbt/mobile/ICBT 3c2.jpg";
import icbtmob27 from "images/icbt/mobile/ICBT 3c3.jpg";

//murata
import tmt1 from "images/murata/TMT 1a.jpg";
import tmt2 from "images/murata/TMT 1b.jpg";
import tmt3 from "images/murata/TMT 1c.jpg";
import tmt4 from "images/murata/TMT 2a.jpg";
import tmt5 from "images/murata/TMT 2b.jpg";
import tmt6 from "images/murata/TMT 2c.jpg";
import tmt7 from "images/murata/TMT 3a.jpg";
import tmt8 from "images/murata/TMT 3b.jpg";
import tmt9 from "images/murata/TMT 3c.jpg";

import tmtmob1 from "images/murata/mobile/TMT 1a1.jpg";
import tmtmob2 from "images/murata/mobile/TMT 1a2.jpg";
import tmtmob3 from "images/murata/mobile/TMT 1a3.jpg";
import tmtmob4 from "images/murata/mobile/TMT 1b1.jpg";
import tmtmob5 from "images/murata/mobile/TMT 1b2.jpg";
import tmtmob6 from "images/murata/mobile/TMT 1b3.jpg";
import tmtmob7 from "images/murata/mobile/TMT 1c1.jpg";
import tmtmob8 from "images/murata/mobile/TMT 1c2.jpg";
import tmtmob9 from "images/murata/mobile/TMT 1c3.jpg";
import tmtmob10 from "images/murata/mobile/TMT 2a1.jpg";
import tmtmob11 from "images/murata/mobile/TMT 2a2.jpg";
import tmtmob12 from "images/murata/mobile/TMT 2a3.jpg";
import tmtmob13 from "images/murata/mobile/TMT 2b1.jpg";
import tmtmob14 from "images/murata/mobile/TMT 2b2.jpg";
import tmtmob15 from "images/murata/mobile/TMT 2b3.jpg";
import tmtmob16 from "images/murata/mobile/TMT 2c1.jpg";
import tmtmob17 from "images/murata/mobile/TMT 2c2.jpg";
import tmtmob18 from "images/murata/mobile/TMT 2c3.jpg";
import tmtmob19 from "images/murata/mobile/TMT 3a1.jpg";
import tmtmob20 from "images/murata/mobile/TMT 3a2.jpg";
import tmtmob21 from "images/murata/mobile/TMT 3a3.jpg";
import tmtmob22 from "images/murata/mobile/TMT 3b1.jpg";
import tmtmob23 from "images/murata/mobile/TMT 3b2.jpg";
import tmtmob24 from "images/murata/mobile/TMT 3b3.jpg";
import tmtmob25 from "images/murata/mobile/TMT 3c1.jpg";
import tmtmob26 from "images/murata/mobile/TMT 3c2.jpg";
import tmtmob27 from "images/murata/mobile/TMT 3c3.jpg";

//jockey pulleys
import jpulley1 from "images/jockeypulley/Item 2.jpg";
import jpulley2 from "images/jockeypulley/Item 3.jpg";
import jpulley3 from "images/jockeypulley/Item 4.jpg";
import jpulley4 from "images/jockeypulley/Item 5.jpg";
import jpulley5 from "images/jockeypulley/Item 6.jpg";
import jpulley6 from "images/jockeypulley/Item 7.jpg";
import jpulley7 from "images/jockeypulley/Item 8.jpg";
import jpulley8 from "images/jockeypulley/Item 9.jpg";
import jpulley9 from "images/jockeypulley/Item 10.jpg";
import jpulley10 from "images/jockeypulley/Item 11.jpg";
import jpulley11 from "images/jockeypulley/Item 12.jpg";
import jpulley12 from "images/jockeypulley/Item 13.jpg";
import jpulley13 from "images/jockeypulley/Item 14.jpg";
import jpulley14 from "images/jockeypulley/Item 15.jpg";
import jpulley15 from "images/jockeypulley/Item 16.jpg";
import jpulley16 from "images/jockeypulley/Item 17.jpg";
import jpulley17 from "images/jockeypulley/Item 18.jpg";

//splindles
import spindles0 from "images/spindles/Item 1.jpg";
import spindles1 from "images/spindles/Item 2.jpg";
import spindles2 from "images/spindles/Item 3.jpg";
import spindles3 from "images/spindles/Item 4.jpg";
import spindles4 from "images/spindles/Item 5.jpg";
import spindles5 from "images/spindles/Item 6.jpg";
import spindles6 from "images/spindles/Item 7.jpg";
import spindles7 from "images/spindles/Item 8.jpg";
import spindles8 from "images/spindles/Item 9.jpg";
import spindles9 from "images/spindles/Item 10.jpg";
import spindles10 from "images/spindles/Item 11.jpg";
import spindles11 from "images/spindles/Item 12.jpg";
import spindles12 from "images/spindles/Item 13.jpg";
import spindles13 from "images/spindles/Item 14.jpg";
import spindles14 from "images/spindles/Item 15.jpg";
import spindles15 from "images/spindles/Item 16.jpg";
import spindles16 from "images/spindles/Item 17.jpg";
import spindles17 from "images/spindles/Item 18.jpg";
import spindles18 from "images/spindles/Item 19.jpg";
import spindles19 from "images/spindles/Item 20.jpg";
import spindles20 from "images/spindles/Item 21.jpg";
import spindles21 from "images/spindles/Item 22.jpg";
import spindles22 from "images/spindles/Item 23.jpg";
import spindles23 from "images/spindles/Item 24.jpg";
import spindles24 from "images/spindles/Item 25.jpg";
import spindles25 from "images/spindles/Item 26.jpg";
import spindles26 from "images/spindles/Item 27.jpg";
import spindles27 from "images/spindles/Item 28.jpg";

import table1 from "images/jockeypulley/table1.jpg";
import table2 from "images/jockeypulley/table2.jpg";
import table3 from "images/jockeypulley/table3.jpg";

import scragg1 from "images/scragg/BOTTOMSLIDEACHINES.jpg";
import scragg2 from "images/scragg/BOTTOMLIDECAMFOLL.jpg";
import scragg3 from "images/scragg/CAM LEVER482-00716MACHINES.jpg";
import scragg4 from "images/scragg/CAMLEVERORSDS700.jpg";
import scragg5 from "images/scragg/END CAP 45 MM BULL NOSE FOR 280 MM FOR CX.jpg";
import scragg6 from "images/scragg/END CAP 57 X 280 FOR SDS 300.jpg";
import scragg7 from "images/scragg/END CAP 57.60 BULL NOSE FOR 290 TUBE FOR SDS 300.jpg";
import scragg8 from "images/scragg/END CAP 57.60 X 280 FOR CX.jpg";
import scragg9 from "images/scragg/END CAP 69 MM FOR 290 TUBE FOR SDS 300.jpg";
import scragg10 from "images/scragg/END CAP PLAIN END ( 592-00379 ) FOR SDS 900-1200 MACHINES..jpg";
import scragg11 from "images/scragg/END CAP PLAIN END 57 MM FOR 290 TUBE FOR SDS 300.jpg";
import scragg12 from "images/scragg/END CAP PLAIN END FOR 280 TUBE FOR SDS 300.jpg";
import scragg13 from "images/scragg/END CAPS BULL NOSE FOR.jpg";
import scragg14 from "images/scragg/ENDCABULLNOSE.jpg";
import scragg15 from "images/scragg/ENDCAPPLAINENDFOR69 X.jpg";
import scragg16 from "images/scragg/FACE JOCKEY PULLEY ( BIN 17069) FOR SDS 900-1200 MACHINES.jpg";
import scragg17 from "images/scragg/FEED BACK ARM LINK.jpg";
import scragg18 from "images/scragg/FEED ROLLER.jpg";
import scragg19 from "images/scragg/FORSDS900120 MACHINES.jpg";
import scragg20 from "images/scragg/s-3 57.6x280.jpg";
import scragg21 from "images/scragg/SDTM10-611.jpg";
import scragg22 from "images/scragg/SDTM11-256.jpg";
import scragg23 from "images/scragg/SDTM6-668.jpg";
import scragg24 from "images/scragg/SDTM6-86.jpg";
import scragg25 from "images/scragg/SUCTION GUN.jpg";
import scragg26 from "images/scragg/TAKE UP BOWL (591-02500 ) FOR SDS 900-1200 MACHINES..jpg";
import scragg27 from "images/scragg/TAKEUP.jpg";
import scragg28 from "images/scragg/TOP SLIDE ASSEMBLY ( 466-00334 )FOR SDS 900-1200 MACHINES..jpg";
import scragg29 from "images/scragg/TRAVERSE GUIDE MOULDING - ROUND ( 466 - 00256 ) FOR SDS 900 - 1200 MACHINES..jpg";
import scragg30 from "images/scragg/TRAVERSE GUIDE MOULDING - SQUARE ( 466 - 00256 ) FOR SDS 900-1200 MACHINES..jpg";

const BackButton = tw.div`mt-4 mb-4 md:mb-0 md:ml-4`;

const Features = tw.div`max-w-64 mx-auto md:mx-0 w-3/12 bg-gray-200 `;
const Features1 = tw.div`max-w-64 mx-auto md:mx-0 w-1/5 bg-gray-200 `;

const Feature = styled(Link)(props => [
  tw`block cursor-pointer py-3 mt-2 sm:mt-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base font-bold text-lg text-primary-500 cursor-pointer pl-2`,
  tw`hover:bg-gray-300 hover:text-gray-700`,
  props => props.active && tw`bg-primary-500! text-gray-100!`
]);

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const HeaderRow = tw.div`flex justify-center items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const Paragraph = tw.p`my-3 md:pr-8 md:pb-6 lg:my-3 text-base xl:text-lg`;
const Paragraph1 = tw.p`my-3 md:pr-8 lg:my-3 text-base xl:text-lg`;
const DiscContainer = tw.p`my-3 lg:my-3 text-base leading-normal`;
const Paragraph2 = tw.p`my-2 md:pr-8 lg:my-2 text-base xl:text-lg`;
const Paragraph3 = tw.p`my-3 ml-8 md:pr-8 lg:my-3 text-base xl:text-lg`;
const Paragraph4 = tw.p`mt-3 mb-1 md:pr-8 lg:my-1 text-base xl:text-lg`;


const TextCon = tw.div`flex justify-between w-56`;
const TextConSpan = tw.span`mr-10`;

const TabsControl = tw.div`flex flex-wrap justify-center bg-gray-200 px-2 py-2 rounded leading-none xl:mt-0 w-full`;

const TabControl = styled(Link)(props => [
  tw`cursor-pointer max-w-xss text-center px-3 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto`,
  tw`hover:bg-gray-300 text-gray-700`,
  props.active ? tw`bg-primary-500! text-gray-100!`: ''
]);

const TabControl1 = styled.div`
  ${tw`flex items-center cursor-pointer max-w-xss1 leading-tight text-center px-3 mt-2 sm:mt-0 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap`;
const TabInnerContent = tw(motion.div)`md:ml-8 flex flex-wrap`;
const TabInnerContent1 = tw(motion.div)`flex flex-wrap`;
const TabInnerDiv = tw.div`w-full md:w-9/12`;
const TabInnerDiv1 = tw.div`md:w-4/5`;

const CardContainer = tw.div`w-full sm:w-1/2 md:w-1/3 lg:w-1/5 mb-4 sm:pr-4 md:pr-2 lg:pr-6`;
const Card = tw(motion.a)`pt-4 bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-16 w-16 xl:h-16 m-auto xl:w-16 bg-center bg-cover relative rounded-full`}
`;

const CardText = tw.div`p-2 text-gray-900 text-center`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500 cursor-pointer`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row max-w-screen-xl mx-auto md:pb-12 pb-10 pt-4`;
const TwoColumn1 = tw.div`flex flex-col lg:flex-row max-w-screen-xl mx-auto md:pb-4 pb-4 pt-4`;
const LeftColumn = tw.div`relative lg:w-1/2 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const LeftColumn1 = tw.div`flex flex-col leading-10 justify-center relative lg:w-3/4 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const LeftColumn2 = tw.div`flex flex-col leading-10 justify-center relative text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;

const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;
const IllustrationContainer = tw.div`flex flex-col justify-center lg:justify-end items-center`;
const Polyurethane = tw.div`flex flex-col justify-center lg:justify-end items-center w-full`;

var featureChanged = false;
var tabChanged = false;

export default ({
  heading = "Spare Parts",
  tabs = [
    {
      index: 0,
      slug: "general-texturing-machine-parts",
      urlSlug: "/SpareParts/general-texturing-machine-parts",
      title: "General Texturing Machine Parts",
      subParts: [
        {
           imageSrc:OilingNozzle,
           title:"Oiling Nozzle",
           pdfUrl:"oilingNozzles"
         },
         {
         imageSrc:Eyelet,
         title:"Eyelet",
         pdfUrl:"eyelets"
         },
         {
           imageSrc:Trap,
           title:"Trap",
           pdfUrl:"trap"
         },
         {
           imageSrc:OpenType,
           title:"Open Type",
           pdfUrl:"openType"
         },
         {
           imageSrc:Traverse,
           title:"Traverse",
           pdfUrl:"traverse"
         },
         {
           imageSrc:Roller,
           title:"Roller",
           pdfUrl:"roller"
         },
         {
           imageSrc:PigTail,
           title:"Pigtail",
           pdfUrl:"pigtail"
         },
         {
           imageSrc:Diverse,
           title:"Diverse",
           pdfUrl:"diverse"
         },
         {
           imageSrc:Hook,
           title:"Hook",
           pdfUrl:"hooks"
         },
         {
           imageSrc:AirNozzle,
           title:"Air Nozzle",
           pdfUrl:"airNozzles"
         },
         {
           imageSrc:RodsAndTubes,
           title:"Rods And Tubes",
           pdfUrl:"rods"
         },
         {
           imageSrc:GuideAssembly,
           title:"Guide Assembly",
           pdfUrl:"guideAssembly"
         }
       ]
    },
    {
      index: 1,
      slug: "rieter-scragg",
      urlSlug: "/SpareParts/rieter-scragg",
      title: "Rieter Scragg"
    },
    {
      index: 2,
      slug: "barmag",
      urlSlug: "/SpareParts/barmag",
      title: "Barmag"
    },
    {
      index: 3,
      slug: "icbt-rieter-icbt",
      urlSlug: "/SpareParts/icbt-rieter-icbt",
      title: "ICBT Rieter ICBT"
    },
    {
      index: 4,
      slug: "ssm-giudici-rpr",
      urlSlug: "/SpareParts/ssm-giudici-rpr",
      title: "SSM Giudici RPR"
    },
    {
      index: 5,
      slug: "aalidhra-bhagat-himson",
      urlSlug: "/SpareParts/aalidhra-bhagat-himson",
      title: "Aalidhra - Bhagat Himson"
    },
    {
      index: 6,
      slug: "murata-tmt",
      urlSlug: "/SpareParts/murata-tmt",
      title: "Murata TMT"
    },
    {
      index: 7,
      slug: "textile-auxillary",
      urlSlug: "/SpareParts/textile-auxillary",
      title: "Textile Auxillary"
    }
  ],
  auxTabs = {
    "Spin Packs": [
    ],
    Spinnerets: [
    ],
    "Gaskets and Diaphragm Seals":[
    ],
    "Filter Weaves":[
    ],
    "Spin Pack Filters":[
    ],
    "Candle Filters": [
    ],
    "Spin Pack Media":[
    ],
    "Screen Filters":[
    ],
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const { name, type } = useParams();
  const [activeTab, setActiveTab] = useState(tabs[0].index);

  const auxTabsKeys = Object.keys(auxTabs);
  const [activeAuxTab, setActiveAuxTab] = useState(auxTabsKeys[0]);

  const features = [
    {
      index: 0,
      title: "Ceramic Guides",
      slug:"ceramic-guides",
      value: 0,
      urlSlug: '/SpareParts/ceramic-guides',
      label: "Ceramic Guides",
      description: "We have the best professional marketing people across the globe just to work with you."
    },
    {
      index: 1,
      value: 1,
      label: "Polyurethane Friction Discs",
      slug:"polyurethane-friction-discs",
      urlSlug: '/SpareParts/polyurethane-friction-discs',
      title: "Polyurethane Friction Discs",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 2,
      value: 2,
      label: "Ceramic Friction Discs",
      slug:"ceramic-friction-discs",
      urlSlug: '/SpareParts/ceramic-friction-discs',
      title: "Ceramic Friction Discs",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 3,
      value: 3,
      label: "Air Jets & Yarn Splicers",
      slug:"air-jets-splicers",
      urlSlug: '/SpareParts/air-jets-splicers',
      title: "Air Jets & Yarn Splicers",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 4,
      value: 4,
      label: "Apron Belts & Rubber Cots",
      slug:"apron-belts",
      urlSlug: '/SpareParts/apron-belts',
      title: "Apron Belts & Rubber Cots",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 5,
      value: 5,
      label: "Friction Unit Spindle Bearings",
      slug:"friction-unit-spindle-bearings",
      urlSlug: '/SpareParts/friction-unit-spindle-bearings',
      title: "Friction Unit Spindle Bearings",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 6,
      value: 6,
      label: "Belt Jockey Pulleys",
      slug:"belt-jockey-pulleys",
      urlSlug: '/SpareParts/belt-jockey-pulleys',
      title: "Belt Jockey Pulleys",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 7,
      value: 7,
      label: "Nip Roller Bearings",
      slug:"nip-roller-bearings",
      urlSlug: '/SpareParts/nip-roller-bearings',
      title: "Nip Roller Bearings",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 8,
      value: 8,
      label: "Take Up Rollers",
      slug:"take-up-rollers",
      urlSlug: '/SpareParts/take-up-rollers',
      title: "Take Up Rollers",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 9,
      value: 9,
      label: "Plastic Dye Tubes & Cones",
      slug:"plastic-dye-tubes-cones",
      urlSlug: '/SpareParts/plastic-dye-tubes-cones',
      title: "Plastic Dye Tubes & Cones",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 10,
      value: 10,
      label: "Yarn Cutters",
      slug:"yarn-cutters",
      urlSlug: '/SpareParts/yarn-cutters',
      title: "Yarn Cutters",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    },
    {
      index: 11,
      value: 11,
      label: "Yarn Sensors",
      slug:"yarn-sensors",
      urlSlug: '/SpareParts/yarn-sensors',
      title: "Yarn Sensors",
      description: "We promise to offer you the best rate we can - at par with the industry standard."
    }
  ];

  const featuresAuxParts = [
    {
      index: 0,
      title: "Polymer Filtration",
      value: 0,
      label: "Polymer Filtration",
    }
  ];

  //Yarn cutters
  const yarnCutters = [
    {
      imageSrc: barmagafkCutter
    },
    {
      imageSrc: barmagafk6Cutter
    },
    {
      imageSrc: giudiciCutter
    },
    {
      imageSrc: icbtClipOnCutter
    },
    {
      imageSrc: icbtCutter
    },
    {
      imageSrc: rprCutter
    },
    {
      imageSrc: scraggCutter
    }
  ];

   //Yarn cutters
   const yarnSensors = [
    { imageSrc: ET028 },
    { imageSrc: ET029 },
    { imageSrc: ET030 },
    { imageSrc: ET062 },
    { imageSrc: ET069 },
    { imageSrc: ET070 },
    { imageSrc: ET071 },
    { imageSrc: ET071C },
    { imageSrc: ET077 },
    { imageSrc: ET078 },
    { imageSrc: ET078N },
    { imageSrc: ET227 },
    { imageSrc: ET262 }
  ];

  const yarnSensors2 = [
    { imageSrc: ET067 },
    { imageSrc: ET068 },
    { imageSrc: ET188 },
    { imageSrc: ET1883 },
    { imageSrc: ET1884 },
    { imageSrc: ET188A },
    { imageSrc: ET226 }
  ];

  const nipRollerBearings = [
    { imageSrc: barmagRoller },
    { imageSrc: barmagBearing },
    { imageSrc: scraggRoller },
    { imageSrc: scraggBearing },
    { imageSrc: scraggBearing2 },
    { imageSrc: scraggBearing3 }
  ];

  const frictionBearings = [
    { imageSrc: friction1 },
    { imageSrc: friction2 },
    { imageSrc: friction3 },
    { imageSrc: friction4 },
    { imageSrc: friction5 },
    { imageSrc: friction6 },
    { imageSrc: friction7 },
    { imageSrc: friction8 },
    { imageSrc: friction9 },
    { imageSrc: friction10 },
    { imageSrc: friction11 },
    { imageSrc: friction12 },
    { imageSrc: friction13 },
    { imageSrc: friction14 },
    { imageSrc: friction15 },
    { imageSrc: friction16 }
  ];

  const jockeyPulleys = [
    { imageSrc: jockey1 },
    { imageSrc: jockey2 },
    { imageSrc: jockey3 },
    { imageSrc: jockey4 },
    { imageSrc: jockey5 },
    { imageSrc: jockey6 },
    { imageSrc: jockey7 }
  ];

  const barmag = [
    { imageSrc: barmag1 },
    { imageSrc: barmag2 },
    { imageSrc: barmag3 },
    { imageSrc: barmag4 },
    { imageSrc: barmag5 },
    { imageSrc: barmag6 },
    { imageSrc: barmag7 },
    { imageSrc: barmag8 },
    { imageSrc: barmag9 },
    { imageSrc: barmag10 },
    { imageSrc: barmag11 },
    { imageSrc: barmag12 },
    { imageSrc: barmag13 },
    { imageSrc: barmag14 },
    { imageSrc: barmag15 },
    { imageSrc: barmag16 },
    { imageSrc: barmag17 },
    { imageSrc: barmag18 },
    { imageSrc: barmag19 },
    { imageSrc: barmag20 },
    { imageSrc: barmag21 },
    { imageSrc: barmag22 },
    { imageSrc: barmag23 }
  ];

  const barmagMob = [
    { imageSrc: barmagmob1 },
    { imageSrc: barmagmob2 },
    { imageSrc: barmagmob3 },
    { imageSrc: barmagmob4 },
    { imageSrc: barmagmob5 },
    { imageSrc: barmagmob6 },
    { imageSrc: barmagmob7 },
    { imageSrc: barmagmob8 },
    { imageSrc: barmagmob9 },
    { imageSrc: barmagmob10 },
    { imageSrc: barmagmob11 },
    { imageSrc: barmagmob12 },
    { imageSrc: barmagmob13 },
    { imageSrc: barmagmob14 },
    { imageSrc: barmagmob15 },
    { imageSrc: barmagmob16 },
    { imageSrc: barmagmob17 },
    { imageSrc: barmagmob18 },
    { imageSrc: barmagmob19 },
    { imageSrc: barmagmob20 },
    { imageSrc: barmagmob21 },
    { imageSrc: barmagmob22 },
    { imageSrc: barmagmob23 },
    { imageSrc: barmagmob24 },
    { imageSrc: barmagmob25 },
    { imageSrc: barmagmob26 },
    { imageSrc: barmagmob27 },
    { imageSrc: barmagmob28 },
    { imageSrc: barmagmob29 },
    { imageSrc: barmagmob30 },
    { imageSrc: barmagmob32 },
    { imageSrc: barmagmob33 },
    { imageSrc: barmagmob34 },
    { imageSrc: barmagmob35 },
    { imageSrc: barmagmob36 },
    { imageSrc: barmagmob37 },
    { imageSrc: barmagmob38 },
    { imageSrc: barmagmob40 },
    { imageSrc: barmagmob41 },
    { imageSrc: barmagmob42 },
    { imageSrc: barmagmob43 },
    { imageSrc: barmagmob44 },
    { imageSrc: barmagmob45 },
    { imageSrc: barmagmob46 },
    { imageSrc: barmagmob47 },
    { imageSrc: barmagmob48 },
    { imageSrc: barmagmob50 },
    { imageSrc: barmagmob51 },
    { imageSrc: barmagmob68 },
    { imageSrc: barmagmob69 },
    { imageSrc: barmagmob70 },
    { imageSrc: barmagmob71 },
    { imageSrc: barmagmob72 },
    { imageSrc: barmagmob73 },
    { imageSrc: barmagmob52 },
    { imageSrc: barmagmob53 },
    { imageSrc: barmagmob54 },
    { imageSrc: barmagmob55 },
    { imageSrc: barmagmob56 },
    { imageSrc: barmagmob57 },
    { imageSrc: barmagmob58 },
    { imageSrc: barmagmob60 },
    { imageSrc: barmagmob61 },
    { imageSrc: barmagmob62 },
    { imageSrc: barmagmob63 },
    { imageSrc: barmagmob64 },
    { imageSrc: barmagmob65 },
    { imageSrc: barmagmob66 },
    { imageSrc: barmagmob67 }
  ];

  const rollers = [
    { imageSrc: rollers1 },
    { imageSrc: rollers2 },
    { imageSrc: rollers3 },
    { imageSrc: rollers4 },
    { imageSrc: rollers5 },
    { imageSrc: rollers6 },
    { imageSrc: rollers7 },
    { imageSrc: rollers8 },
    { imageSrc: rollers9 },
    { imageSrc: rollers10 },
    { imageSrc: rollers11 },
    { imageSrc: rollers12 },
    { imageSrc: rollers13 },
    { imageSrc: rollers14 }
  ];

  const jpulley = [
    { imageSrc: jpulley1, title: 'ITEM 2' },
    { imageSrc: jpulley2, title: 'ITEM 3' },
    { imageSrc: jpulley3, title: 'ITEM 4' },
    { imageSrc: jpulley4, title: 'ITEM 5' },
    { imageSrc: jpulley5, title: 'ITEM 6' },
    { imageSrc: jpulley6, title: 'ITEM 7' },
    { imageSrc: jpulley7, title: 'ITEM 8' },
    { imageSrc: jpulley8, title: 'ITEM 9' },
    { imageSrc: jpulley9, title: 'ITEM 10' },
    { imageSrc: jpulley10, title: 'ITEM 11' },
    { imageSrc: jpulley11, title: 'ITEM 12' },
    { imageSrc: jpulley12, title: 'ITEM 13' },
    { imageSrc: jpulley13, title: 'ITEM 14' },
    { imageSrc: jpulley14, title: 'ITEM 15' },
    { imageSrc: jpulley15, title: 'ITEM 16' },
    { imageSrc: jpulley16, title: 'ITEM 17' },
    { imageSrc: jpulley17, title: 'ITEM 18' }
  ];

  const spindles = [
    { imageSrc: spindles0, title: 'ITEM 1' },
    { imageSrc: spindles1, title: 'ITEM 2' },
    { imageSrc: spindles2, title: 'ITEM 3' },
    { imageSrc: spindles3, title: 'ITEM 4' },
    { imageSrc: spindles4, title: 'ITEM 5' },
    { imageSrc: spindles5, title: 'ITEM 6' },
    { imageSrc: spindles6, title: 'ITEM 7' },
    { imageSrc: spindles7, title: 'ITEM 8' },
    { imageSrc: spindles8, title: 'ITEM 9' },
    { imageSrc: spindles9, title: 'ITEM 10' },
    { imageSrc: spindles10, title: 'ITEM 11' },
    { imageSrc: spindles11, title: 'ITEM 12' },
    { imageSrc: spindles12, title: 'ITEM 13' },
    { imageSrc: spindles13, title: 'ITEM 14' },
    { imageSrc: spindles14, title: 'ITEM 15' },
    { imageSrc: spindles15, title: 'ITEM 16' },
    { imageSrc: spindles16, title: 'ITEM 17' },
    { imageSrc: spindles17, title: 'ITEM 18' },
    { imageSrc: spindles18, title: 'ITEM 19' },
    { imageSrc: spindles19, title: 'ITEM 20' },
    { imageSrc: spindles20, title: 'ITEM 21' },
    { imageSrc: spindles21, title: 'ITEM 22' },
    { imageSrc: spindles22, title: 'ITEM 23' },
    { imageSrc: spindles23, title: 'ITEM 24' },
    { imageSrc: spindles24, title: 'ITEM 25' },
    { imageSrc: spindles25, title: 'ITEM 26' },
    { imageSrc: spindles26, title: 'ITEM 27' },
    { imageSrc: spindles27, title: 'ITEM 28' }
  ];


  const scragg = [
    { imageSrc: scragg1, title: 'BOTTOM SLIDE ASSEMBLY' },
    { imageSrc: scragg2, title: 'CAM FOLLOWER'  },
    { imageSrc: scragg3, title: 'CAM LEVER'  },
    { imageSrc: scragg4, title: 'CAM LEVER FOR SDS 700'  },
    { imageSrc: scragg5, title: 'END CAP 45 MM BULL NOSE'  },
    { imageSrc: scragg6, title: 'END CAP 57 X 280'  },
    { imageSrc: scragg7, title: 'END CAP BULL NOSE'  },
    { imageSrc: scragg8, title: 'END CAP 57.60 BULL NOSE'  },
    { imageSrc: scragg9, title: 'END CAP 57.60 X 280'  },
    { imageSrc: scragg10, title: 'END CAP 69 MM'  },
    { imageSrc: scragg11, title: 'END CAP PLAIN END 57 MM'  },
    { imageSrc: scragg12, title: 'END CAP PLAIN END'  },
    { imageSrc: scragg13, title: 'END CAP PLAIN END FOR 69 X 280 PAPER TUBE'  },
    { imageSrc: scragg14, title: 'END CAP PLAIN END FOR 280 TUBE'  },
    { imageSrc: scragg15, title: 'END CAPS BULL NOSE'  },
    { imageSrc: scragg16, title: 'FACE JOCKEY PULLEY'  },
    { imageSrc: scragg17, title: 'FEED BACK ARM LINK'  },
    { imageSrc: scragg18, title: 'FEED ROLLER'  },
    { imageSrc: scragg19, title: 'CAM ASSEMBLY'  },
    { imageSrc: scragg20, title: 's-3 57.6x280'  },
    { imageSrc: scragg21, title: 'SDTM10-611'  },
    { imageSrc: scragg22, title: 'SDTM11-256'  },
    { imageSrc: scragg23, title: 'SDTM6-668'  },
    { imageSrc: scragg24, title: 'SDTM6-86'  },
    { imageSrc: scragg25, title: 'SUCTION GUN'  },
    { imageSrc: scragg26, title: 'TAKE UP BOWL 30 MM DIA WITH CHECK NUT OF 30 % GF POLYESTER MATERIAL'  },
    { imageSrc: scragg27, title: 'TAKE UP BOWL (591-02500 )'  },
    { imageSrc: scragg28, title: 'TOP SLIDE ASSEMBLY'  },
    { imageSrc: scragg29, title: 'TRAVERSE GUIDE MOULDING - ROUND'  },
    { imageSrc: scragg30, title: 'TRAVERSE GUIDE MOULDING - SQUARE'  }
  ];

  const icbt = [
    { imageSrc: icbt1 },
    { imageSrc: icbt2 },
    { imageSrc: icbt3 },
    { imageSrc: icbt4 },
    { imageSrc: icbt5 },
    { imageSrc: icbt6 },
    { imageSrc: icbt7 },
    { imageSrc: icbt8 },
    { imageSrc: icbt9 }
  ];

  const icbtMob = [
    { imageSrc: icbtmob1 },
    { imageSrc: icbtmob2 },
    { imageSrc: icbtmob3 },
    { imageSrc: icbtmob4 },
    { imageSrc: icbtmob5 },
    { imageSrc: icbtmob6 },
    { imageSrc: icbtmob7 },
    { imageSrc: icbtmob8 },
    { imageSrc: icbtmob9 },
    { imageSrc: icbtmob10 },
    { imageSrc: icbtmob11 },
    { imageSrc: icbtmob12 },
    { imageSrc: icbtmob13 },
    { imageSrc: icbtmob14 },
    { imageSrc: icbtmob15 },
    { imageSrc: icbtmob16 },
    { imageSrc: icbtmob17 },
    { imageSrc: icbtmob18 },
    { imageSrc: icbtmob19 },
    { imageSrc: icbtmob20 },
    { imageSrc: icbtmob21 },
    { imageSrc: icbtmob22 },
    { imageSrc: icbtmob23 },
    { imageSrc: icbtmob24 },
    { imageSrc: icbtmob25 },
    { imageSrc: icbtmob26},
    { imageSrc: icbtmob27 }
  ];

  const Aalidhra = [
    {imageSrc:Aalidhra1},
    {imageSrc:Aalidhra2},
    {imageSrc:Aalidhra3},
    {imageSrc:Aalidhra4},
    {imageSrc:Aalidhra5},
    {imageSrc:Aalidhra6},
    {imageSrc:Aalidhra7},
    {imageSrc:Aalidhra8},
    {imageSrc:Aalidhra9},
    {imageSrc:Aalidhra10},
    {imageSrc:Aalidhra11},
    {imageSrc:Aalidhra12},
    {imageSrc:Aalidhra13},
    {imageSrc:Aalidhra14},
    {imageSrc:Aalidhra15},
    {imageSrc:Aalidhra16},
    {imageSrc:Aalidhra17},
    {imageSrc:Aalidhra18}
  ];

  const alidhraMob = [
    {imageSrc:Aalidhra1a1},
    {imageSrc:Aalidhra1a2},
    {imageSrc:Aalidhra2a1},
    {imageSrc:Aalidhra2a2},
    {imageSrc:Aalidhra3a1},
    {imageSrc:Aalidhra3a2},
    {imageSrc:Aalidhra4a1},
    {imageSrc:Aalidhra4a2},
    {imageSrc:Aalidhra5a1},
    {imageSrc:Aalidhra5a2},
    {imageSrc:Aalidhra6a1},
    {imageSrc:Aalidhra6a2},
    {imageSrc:Aalidhra7a1},
    {imageSrc:Aalidhra7a2},
    {imageSrc:Aalidhra8a1},
    {imageSrc:Aalidhra8a2},
    {imageSrc:Aalidhra9a1},
    {imageSrc:Aalidhra9a2},
    {imageSrc:Aalidhra10a1},
    {imageSrc:Aalidhra10a2},
    {imageSrc:Aalidhra11a1},
    {imageSrc:Aalidhra11a2},
    {imageSrc:Aalidhra12a1},
    {imageSrc:Aalidhra12a2},
    {imageSrc:Aalidhra13a1},
    {imageSrc:Aalidhra13a2},
    {imageSrc:Aalidhra14a1},
    {imageSrc:Aalidhra14a2},
    {imageSrc:Aalidhra15a1},
    {imageSrc:Aalidhra15a2},
    {imageSrc:Aalidhra16a1},
    {imageSrc:Aalidhra16a2},
    {imageSrc:Aalidhra17a1},
    {imageSrc:Aalidhra17a2},
    {imageSrc:Aalidhra18a1},
    {imageSrc:Aalidhra18a2}
  ]

  const murata = [
    { imageSrc: tmt1 },
    { imageSrc: tmt2 },
    { imageSrc: tmt3 },
    { imageSrc: tmt4 },
    { imageSrc: tmt5 },
    { imageSrc: tmt6 },
    { imageSrc: tmt7 },
    { imageSrc: tmt8 },
    { imageSrc: tmt9 }
  ];

  const murataMob = [
    { imageSrc: tmtmob1 },
    { imageSrc: tmtmob2 },
    { imageSrc: tmtmob3 },
    { imageSrc: tmtmob4 },
    { imageSrc: tmtmob5 },
    { imageSrc: tmtmob6 },
    { imageSrc: tmtmob7 },
    { imageSrc: tmtmob8 },
    { imageSrc: tmtmob9 },
    { imageSrc: tmtmob10 },
    { imageSrc: tmtmob11 },
    { imageSrc: tmtmob12 },
    { imageSrc: tmtmob13 },
    { imageSrc: tmtmob14 },
    { imageSrc: tmtmob15 },
    { imageSrc: tmtmob16 },
    { imageSrc: tmtmob17 },
    { imageSrc: tmtmob18 },
    { imageSrc: tmtmob19 },
    { imageSrc: tmtmob20 },
    { imageSrc: tmtmob21 },
    { imageSrc: tmtmob22 },
    { imageSrc: tmtmob23 },
    { imageSrc: tmtmob24 },
    { imageSrc: tmtmob25 },
    { imageSrc: tmtmob26 },
    { imageSrc: tmtmob27 }
  ];


  const rprImages = [
    { imageSrc: rpr1 },
    { imageSrc: rpr2 },
    { imageSrc: rpr3 },
    { imageSrc: rpr4 },
    { imageSrc: rpr5 }
  ];

  const rprMobImages = [
    { imageSrc: rprmob1 },
    { imageSrc: rprmob2 },
    { imageSrc: rprmob3 },
    { imageSrc: rprmob4 },
    { imageSrc: rprmob5 },
    { imageSrc: rprmob6 },
    { imageSrc: rprmob7 },
    { imageSrc: rprmob8 },
    { imageSrc: rprmob9 },
    { imageSrc: rprmob10 },
    { imageSrc: rprmob11 },
    { imageSrc: rprmob12 },
    { imageSrc: rprmob13 },
    { imageSrc: rprmob14 },
    { imageSrc: rprmob15 }
  ];


  const [activeFeature, setActiveFeature] = useState(features[0].index);
  const [showResults, setShowResults] = useState('');
  const updatePdfUrl = (newUrl) => {
    //setPDF({newUrl})
    setShowResults(newUrl);
    console.log(showResults);
  }

  const changeFeature = (index, isFromLoad) => {
    setShowResults('');
    setActiveFeature(index);

    if(isFromLoad) {
      featureChanged = true;
    } else {
      const body = document.querySelector('#spare');

      if(body){
        body.scrollIntoView({
          behavior: 'smooth'
        }, 500);
      }
    }
  }

  const changeTab = (index, isFromLoad) => {
    setActiveTab(index);

    if(isFromLoad) {
      tabChanged = true;
    }
  }

  useEffect(() => {
    if(!featureChanged) {
      let featureSlug = features.find( ({ slug }) => slug === type );
      if(featureSlug) {
        changeFeature(featureSlug.index, true);
      }
    }

    if(!tabChanged){
      let tabSlug = tabs.find( ({ slug }) => slug === type );
      if( tabSlug && activeTab !== tabSlug.index ) {
        changeTab(tabSlug.index, true);
      }
    }
  });

  return (
    <Container>
      <ContentWithPaddingXl>
      <Header>{heading}</Header>
      <Paragraph>
        TSP is committed to being a total spare parts supplier.  Below is a selection of menus where our quality parts are categorised. If you require a part which is not listed on our website, please send us your inquiry through our <a href="/ContactUs" tw="px-2 text-primary-500 hocus:text-primary-200">Contact Us</a> page and we will endeavour to fulfil your request.  
      </Paragraph>
        <HeaderRow id="spare">
          <TabsControl>
            {tabs.map((tab, index) => (
              <TabControl to={tab.urlSlug} key={index} active={activeTab === index} onClick={() => setActiveTab(index)}>
                {tab.title}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>
        {tabs.map((tab, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === index ? "current" : "hidden"}
            animate={activeTab === index ? "current" : "hidden"}
          >
          { activeTab === 0 ? <>
            <Features tw="hidden md:block">
                  {features.map((feature, index) => (
                    <Feature to={feature.urlSlug} key={index} active={activeFeature === index} onClick={() => changeFeature(index)}>
                      {feature.title}
                    </Feature>
                  ))}
            </Features>
            <Select options={features} defaultValue={features[0]} tw="w-full block text-left md:hidden block" onChange={e => changeFeature(e.index)} />
            { activeFeature === 0 ?
              <div>
                { showResults !== '' ? <BackButton><PrimaryButton onClick={() => updatePdfUrl('')}>Back</PrimaryButton></BackButton> : '' }
                { showResults === 'rods' ?
                <SinglePageViewer pdf={rods}/> : '' }
                { showResults === 'oilingNozzles' ?
                <SinglePageViewer pdf={oilingNozzles}/> : '' }
                { showResults === 'eyelets' ?
                <SinglePageViewer pdf={eyelets}/> : '' }
                { showResults === 'trap' ?
                <SinglePageViewer pdf={trapGuides}/> : '' }
                { showResults === 'openType' ?
                <SinglePageViewer pdf={openGuides}/> : '' }
                { showResults === 'diverse' ?
                <SinglePageViewer pdf={diverse}/> : '' }
                { showResults === 'roller' ?
                <SinglePageViewer pdf={rollerGuides}/> : '' }
                { showResults === 'pigtail' ?
                <SinglePageViewer pdf={pigTails}/> : '' }
                { showResults === 'airNozzles' ?
                <SinglePageViewer pdf={airNozzles}/> : '' }
                { showResults === 'traverse' ?
                <SinglePageViewer pdf={traverse}/> : '' }
                { showResults === 'hooks' ?
                <SinglePageViewer pdf={hooks}/> : '' }
                { showResults === 'guideAssembly' ?
                <SinglePageViewer pdf={ceramicAssemblies}/> : '' }
              </div> : '' }
              <TabInnerDiv>
                <TabInnerContent>
                    {activeFeature === 0 && showResults === '' ? <>
                    <Paragraph1>
                      <div tw="mb-1">All ceramic guides are available in the following materials,</div>
                      <Paragraph1 tw="m-8">
                        <div tw="mb-1">•	Titanium</div>
                        <div tw="mb-1">•	Aluminium Oxide (95% to 99.7%)</div>
                        <div tw="mb-1">•	Zirconium </div>
                      </Paragraph1>
                      <div tw="mb-1">Ceramic guides are available in various profiles and surface finishes,</div>
                      <Paragraph1 tw="m-8">
                        <div tw="mb-1">•	Mirror Surface finish	0.05 to 0.10μm</div>
                        <div tw="mb-1">•	Matt Surface finish	0.35 to 0.45μm</div>
                      </Paragraph1>
                    </Paragraph1>
                    <Paragraph1>
                    Ceramic guides are offered for the following OEM’s and others not listed,<br></br>
                    Rieter Scragg, Barmag, ICBT, RPR, Giudici, Aalidhra, Himson, Murata, TMT and all Chinese OEM’s
                    </Paragraph1>
                    <TwoColumn>
                        <LeftColumn> 
                          <IllustrationContainer>
                            <img tw="md:mr-4 min-w-0 w-full max-w-lg xl:max-w-3xl rounded-lg" src={twistedYarns} alt="Design Illustration" />
                          </IllustrationContainer>
                        </LeftColumn>
                        <RightColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 min-w-0 w-full max-w-lg xl:max-w-3xl rounded-lg" src={deTwistedYarns} alt="Design Illustration" />
                          </IllustrationContainer>
                        </RightColumn>
                      </TwoColumn>
                      
                      {tabs[0].subParts.map((card, index) => (
                          <CardContainer key={index}>
                            <Card className="group" href={card.url} onClick={() => updatePdfUrl(card.pdfUrl)} initial="rest" whileHover="hover" animate="rest">
                              <CardImageContainer imageSrc={card.imageSrc}>
                              </CardImageContainer>
                              <CardText>
                                <CardTitle>{card.title}</CardTitle>
                              </CardText>
                            </Card>
                          </CardContainer>
                        ))} </>: '' }
                    {activeFeature === 1 ? <> 
                      <Polyurethane tw="md:mb-8 mt-4 md:mt-0">
                        <SectionHeading>Polyurethane Friction Discs</SectionHeading>
                        <IllustrationContainer>
                            <img tw="mr-4 mt-4 mb-8 min-w-0 w-full max-w-lg xl:max-w-2xl rounded-lg" src={poly1} alt="Polyurethane" />
                        </IllustrationContainer>                        
                      </Polyurethane>
                      <div tw="flex">
                        <Paragraph1>
                          Polyurethane discs offered have the following characteristics,
                                <br></br>
                          <br></br>
                          <div>•	Wear resistance against high-speed disc rotation</div>
                          <div>•	Stable process tensions at higher speeds</div>
                          <div>•	Spin finish oil resistant</div>
                          <div>•	Antistatic charge resistant</div>
                          <div>•	PU discs available in 84A, 86A, 88A & 90A shore hardness</div>
                          <div>•	100% disc inspection for dimensional accuracy and surface finish</div>
                        </Paragraph1>
                      </div>
                      <SectionHeading>
                        Polyurethane Disc Sizes Available
                      </SectionHeading>
                      <IllustrationContainer tw="mb-8">
                        <div tw="flex flex-col md:flex-row items-center">
                          <img tw="md:mr-8 md:mt-4 min-w-0 w-full max-w-xl xl:max-w-xl rounded-lg" src={polytable} alt="Polyurethane" />
                          <img tw="md:mr-6 min-w-0 rounded-lg w-64" src={poly2} alt="Design Illustration" />
                        </div>
                      </IllustrationContainer>
                      <SectionHeading>
                        Texturing Process Expertise
                      </SectionHeading>
                      <Paragraph1>
                        One of the most important parameters to the draw texturing process is the amount of twist the friction and discs generate. 
                        The twist level for the process is a significant factor that determines process speed and yarn quality. 
                        <br></br><br></br>The twist inserted into the yarn by the friction unit is measured by doing a “snatch” test. The yarn is lifted out of the cooling zone while it is running and “snatched” by fingers or a special clamp. 
                        <br></br><br></br>The “snatched” length of yarn is then transferred to the instrument below and de-twisted until the filaments are parallel. A calculation then determines the twist per meter. 
                      </Paragraph1>
                      <IllustrationContainer>
                          <img tw="mr-4 mt-4 mb-4 min-w-0 w-full max-w-lg xl:max-w-2xl rounded-lg" src={poly3} alt="Polyurethane" />
                      </IllustrationContainer>
                      <Paragraph1>
                        Through extensive research and practical experience TSP has accumulated the knowledge to calculate twist levels generated from different sizes of friction discs and friction units. The unique mathematical calculations enables TSP to recommend the correct size of disc for our customer’s requirements. 
                        <br></br><br></br>TSP can recommend the correct disc sizes to run at higher speeds or alternatively recommend discs that can give specific yarn and fabric requirements.
                      </Paragraph1>
                      <Paragraph1>
                        The twist level is influenced by the following:
                        <br></br>
                        <br></br>      
                        <div>•	Tri spindle centre distance</div>
                        <div>•	Disc diameter</div>
                        <div>•	Disc thickness</div>
                        <div>•	Disc edge profile</div>
                        <div>•	Spacing between discs</div>
                        <br></br>The overlapped discs in the friction unit, twist and forward the yarn simultaneously. The yarn runs at a given angle across the discs. The angle of the yarn on the discs is determine by the factors stated above.
                        <br></br><br></br>The overlapping discs form an imaginary cylinder and the yarn follows a spiral path around this cylinder resulting in an angle across the disc. This is illustrated in the accompanying pictures below.
                      </Paragraph1>
                      <TwoColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 min-w-0 md:w-4/5 rounded-lg" src={poly41} alt="Design Illustration" />
                            <img tw="md:mr-6 min-w-0 md:w-104 rounded-lg" src={poly42} alt="Design Illustration" />
                          </IllustrationContainer>
                      </TwoColumn>
                      <TwoColumn>
                        <LeftColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 md:mb-4 min-w-0 lg:max-w-80 xl:max-w-96 rounded-lg" src={poly5} alt="Design Illustration" />
                          </IllustrationContainer>
                        </LeftColumn>
                        <RightColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 min-w-0 lg:max-w-80 xl:max-w-96 rounded-lg" src={poly6} alt="Design Illustration" />
                          </IllustrationContainer>
                        </RightColumn>
                      </TwoColumn>
                    </> : '' }
                    {activeFeature === 2 ? <> 
                      <Polyurethane tw="md:mb-8">
                        <SectionHeading>Ceramic Friction Discs</SectionHeading>
                        <IllustrationContainer>
                            <img tw="mr-4 mt-4 mb-4 min-w-0 w-full max-w-lg xl:max-w-2xl rounded-lg" src={ceramicfriction} alt="Polyurethane" />
                        </IllustrationContainer> 
                      </Polyurethane>        
                      <div tw="flex">
                          <Paragraph1>
                            TSP Ceramic Discs have the following characteristics,
                            <br></br>
                            <br></br> 
                            <div>•	Wear resistance against high-speed disc rotation</div>
                            <div>•	Stable and uniform process tensions</div>
                            <div>•	Spin finish oil resistant</div>
                            <div>•	Antistatic charge resistant</div>
                            <div>•	Input Guide Discs are available in “C” and asymmetric edge profiles</div>
                            <div>•	100% disc inspection for dimensional accuracy and surface finish</div>
                          </Paragraph1>
                      </div>
                        <SectionHeading>
                          Ceramic Disc Profiles and Sizes Available
                        </SectionHeading>
                        <IllustrationContainer>
                            <img tw="rounded-lg" src={inputguide} alt="Polyurethane" />
                        </IllustrationContainer>
                        <IllustrationContainer>
                            <img tw="md:mr-4 mt-10 mb-4 rounded-lg" src={cdprofile} alt="Polyurethane" />
                        </IllustrationContainer>
                        <IllustrationContainer>
                            <img tw="md:mr-4 mt-4 mb-4 rounded-lg" src={exitknife} alt="Polyurethane" />
                        </IllustrationContainer>
                        <SectionHeading>
                          Texturing Process Expertise
                        </SectionHeading>
                        <Paragraph1>
                          One of the most important parameters to the draw texturing process is the amount of twist the friction and discs generate. 
                          The twist level for the process is a significant factor that determines process speed and yarn quality. 
                          <br></br><br></br>The twist inserted into the yarn by the friction unit is measured by doing a “snatch” test. The yarn is lifted out of the cooling zone while it is running and “snatched” by fingers or a special clamp. 
                          <br></br><br></br>The “snatched” length of yarn is then transferred to the instrument below and de-twisted until the filaments are parallel. A calculation then determines the twist per meter. 
                        </Paragraph1>
                        <IllustrationContainer>
                            <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full max-w-lg xl:max-w-2xl rounded-lg" src={poly3} alt="Polyurethane" />
                        </IllustrationContainer>
                      <Paragraph1>
                        Through extensive research and practical experience TSP has accumulated the knowledge to calculate twist levels generated from different sizes of friction discs and friction units. The unique mathematical calculations enables TSP to recommend the correct size of disc for our customer’s requirements. 
                        <br></br><br></br>TSP can recommend the correct disc sizes to run at higher speeds or alternatively recommend discs that can give specific yarn and fabric requirements.
                      </Paragraph1>
                      <Paragraph1>
                        The twist level is influenced by the following,
                        <br></br>
                        <br></br> 
                        <div>•	Tri spindle centre distance</div>
                        <div>•	Disc diameter</div>
                        <div>•	Disc thickness</div>
                        <div>•	Disc edge profile</div>
                        <div>•	Spacing between discs</div>
                        <br></br>The overlapped discs in the friction unit, twist and forward the yarn simultaneously. The yarn runs at a given angle across the discs. The angle of the yarn on the discs is determine by the factors stated above.
                        <br></br><br></br>The overlapping discs form an imaginary cylinder and the yarn follows a spiral path around this cylinder resulting in an angle across the disc. This is illustrated in the accompanying pictures below.
                      </Paragraph1>
                      <TwoColumn>
                          <IllustrationContainer>
                          <img tw="md:mr-6 min-w-0 md:w-4/5 rounded-lg" src={poly41} alt="Design Illustration" />
                            <img tw="md:mr-6 min-w-0 md:w-104 rounded-lg" src={poly42} alt="Design Illustration" />
                          </IllustrationContainer>
                      </TwoColumn>
                      <TwoColumn>
                        <LeftColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 mb-4 min-w-0 lg:max-w-80 xl:max-w-96 rounded-lg" src={poly5} alt="Design Illustration" />
                          </IllustrationContainer>
                        </LeftColumn>
                        <RightColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 min-w-0 lg:max-w-80 xl:max-w-96 rounded-lg" src={poly6} alt="Design Illustration" />
                          </IllustrationContainer>
                        </RightColumn>
                      </TwoColumn>
                    </> : '' }
                    {activeFeature === 3 ? <> 
                      <Paragraph> We are the United Kingdom and Ireland agents for Heberlein. For  comprehensive information regarding,
                        <br></br><br></br>Air Interlacing Jets
                        <div>Air Texturing Jets</div>
                        <div>Spinning Jets</div>
                        <div>BCF Jets</div>
                        <div>Yarn Splicers</div>
                        <div>Yarn Suction Devices</div><br></br>
                        <div>Please click our principles logo below.</div></Paragraph> 
                      <a href="https://www.heberlein.com/en/" target="_blank">
                        <img tw="mr-6 min-w-0 w-full max-w-lg xl:max-w-3xl rounded-lg" src={heberlein} alt="Design Illustration" />
                      </a>
                    </> : '' }
                    {activeFeature === 4 ? <> 
                      <div><SectionHeading>Rubber Cots and Aprons</SectionHeading>
                      <br></br>
                      <Subheading1>False Twist Texturing Rubber Cots</Subheading1>
                      <Paragraph1>
                        Various Construction Types Available:
                      </Paragraph1>
                      <Paragraph1>
                        <table>
                          <tbody>
                            <tr><td><TextConSpan>Plain </TextConSpan></td><td>Glue required for fixing</td><td></td></tr>
                            <tr><td><TextConSpan>Spring Grip</TextConSpan></td><td>Press fit roller has a corded re-enforced inner (No Glue Required)</td><td></td></tr>
                            <tr><td><TextConSpan>Alu Core</TextConSpan></td><td>Press fit roller has an Aluminum core </td><td></td></tr>
                          </tbody>
                        </table>
                      </Paragraph1>
                      <Paragraph1>
                      All constructions are available in shore hardness from 60A to 80A
                      </Paragraph1>
                      <Paragraph1>
                      All sizes available for Rieter Scragg, ICBT, Barmag, Aalidhra, Himson, RPR, Giudici, TMT and all Chinese OEM texturing machinery
                      </Paragraph1>
                      <Paragraph1>
                      Rollers are suitable for False Twist Texturing, Air Jet Texturing, and Magnetic Spindle Texturing
                      <br></br>All rollers are available in plain or chamfered edge profiles
                      </Paragraph1>
                      <TwoColumn>
                        <LeftColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 mb-4 min-w-0 lg:max-w-80 xl:max-w-96 rounded-lg" src={cots1} alt="Design Illustration" />
                          </IllustrationContainer>
                        </LeftColumn>
                        <RightColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 min-w-0 lg:max-w-80 xl:max-w-96 rounded-lg" src={cots2} alt="Design Illustration" />
                          </IllustrationContainer>
                        </RightColumn>
                      </TwoColumn>
                      </div>
                      <Subheading1>False Twist Texturing Apron Belts</Subheading1>
                      <Paragraph1>
                      All sizes available for Barmag, Aalidhra, Himson, RPR, Giudici, TMT and all Chinese OEM texturing machinery
                      </Paragraph1>
                      <Paragraph1>
                      Apron belts are suitable for False Twist Texturing, Air Jet Texturing, and Magnetic Spindle Texturing
                      </Paragraph1>
                      <Paragraph1>
                      <b>Machines that use rubber rollers can also be converted to use aprons belts.
                      </b>
                      </Paragraph1>
                      <TwoColumn>
                        <LeftColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 mb-4 min-w-0 lg:max-w-80 xl:max-w-96 rounded-lg" src={cots3} alt="Design Illustration" />
                          </IllustrationContainer>
                        </LeftColumn>
                        <RightColumn>
                          <IllustrationContainer>
                            <img tw="md:mr-6 min-w-0 lg:max-w-80 xl:max-w-96 rounded-lg" src={cots4} alt="Design Illustration" />
                          </IllustrationContainer>
                        </RightColumn>
                      </TwoColumn>
                    </> : '' }
                    {activeFeature === 5 ? <> 
                      <SliderCard cards={frictionBearings} title="Friction Unit Spindle Bearings" />
                      <SliderCard cards={spindles} title="Spindles" widthClass="spindleWidth"/>
                    </> : '' }
                    {activeFeature === 6 ? <> 
                      <SliderCard cards={jockeyPulleys} title="Belt Jockey Pulleys" />
                      <SliderCard cards={jpulley} title="Jockey Pulleys" />
                      <IllustrationContainer>
                          <img tw="mr-6 mb-4 w-full max-w-lg xl:max-w-2xl rounded-lg" src={table1} alt="Design Illustration" />
                      </IllustrationContainer>
                      <IllustrationContainer>
                          <img tw="mr-6 mb-4 w-full max-w-lg xl:max-w-2xl rounded-lg" src={table2} alt="Design Illustration" />
                      </IllustrationContainer>
                      <IllustrationContainer>
                          <img tw="mr-6 ml-1 mb-4 w-full max-w-lg xl:max-w-xl rounded-lg" src={table3} alt="Design Illustration" />
                      </IllustrationContainer>
                    </> : '' }
                    {activeFeature === 7 ? <> 
                      <SliderCard cards={nipRollerBearings} title="Nip Roller Bearings" />
                    </> : '' }
                    {activeFeature === 8 ? <> 
                      <SectionHeading>Take Up Rollers</SectionHeading>
                      <Paragraph4> TSP offers a wide range of high quality Bakelite take up rollers for all OEM’s. If the roller you require is not shown in the following pages please contact us with your requirement via our <a href="/ContactUs" tw="px-2 text-primary-500 hocus:text-primary-200">Contact Us</a> page.</Paragraph4> 
                      <SliderCard cards={rollers} slidesShow={1} title="" compactClass="compact"/>
                    </> : '' }
                    {activeFeature === 9 ? <> 
                      <SectionHeading>Dye Tubes, Dye Cones and Yarn Carriers</SectionHeading>
                      <br></br>
                      <IllustrationContainer>
                          <img tw="mr-4 mt-4 mb-4 min-w-0 w-full max-w-lg xl:max-w-full rounded-lg" src={dyetubes} alt="Polyurethane" />
                      </IllustrationContainer>
                      <Paragraph1>
                      TSP offers a variety of plastic tubes.
                      </Paragraph1>
                      <Paragraph1>
                      TSP offers a large variety of perforated dye tubes and cones with different perforations for various applications. Dye tubes and cones are produced from modified high temperature resistant material. They are available in different conicity, perforations, weights and dimensions according to your requirements. Tubes and cones are available for single or multi use applications. 
                      </Paragraph1>
                    </> : '' }
                    {activeFeature === 10 ? <> 
                      <SliderCard cards={yarnCutters} title="Yarn Cutters" />
                    </> : '' }
                    {activeFeature === 11 ? <> 
                      <SliderCard cards={yarnSensors} title="Yarn Sensors" />
                      <SliderCard cards={yarnSensors2} title="" />
                    </> : '' }
                </TabInnerContent>
            </TabInnerDiv>
            </>: ''}
            { activeTab === 3 ? <> 
              <Paragraph1> TSP is committed to providing quality spare parts and service. We offer a comprehensive collection of parts for various generations of machines. Please scroll through the parts below. If the part you require is not shown or you want any further information or technical discussion then please send us the details via our Contact Us page.
              </Paragraph1>
              <SliderCard cards={icbt} title="" hideMobile="true" slidesShow={1}/>
              <SliderCard cards={icbtMob} title="" isMobile="true"/>
            </>: ''}
            { activeTab === 1 ? <> 
              <Paragraph2> Textile Solutions Professional Ltd. are the only company that offers a comprehensive service for all Rieter Scragg machinery. We supply and manufacture according to original Rieter Scragg specifications. Having previously been employed by Rieter Scragg we are able to assist users with expert advice to overcome their machinery and process issues.
              </Paragraph2>
              <Paragraph2>
              We have cost effect solutions to replace the electronic control systems of Introl 1, 2 and 3 with modular modern PLC control system. The new systems are developed with off the shelve parts enabling the user to maintain the system.
              </Paragraph2>
              <Paragraph2>
              We also offer a guaranteed repair service for electrical boards and inverters.
              </Paragraph2>
              <Paragraph2>
              TSP also offers attachments or conversions to produce fancy yarns, improve the process efficiency or product quality. For more details, please contact us for your specific requirements.
              </Paragraph2>
              <Paragraph2 tw="w-full">
                We have soft copies of all spare parts manuals and manufacturing drawings so we can identify all parts via part numbers or pictures. We have production capabilities to make all the parts required for Rieter Scragg machinery listed below,
                <br></br><br></br>
                <table class="table-auto">
                  <tbody>
                  <tr><td><TextConSpan>SDS 2</TextConSpan></td> <td>SDS 3</td><td></td></tr>
                  <tr><td><TextConSpan>SDS 700</TextConSpan></td>	<td>SDS 800</td><td></td></tr>
                  <tr><td><TextConSpan>SDS 900</TextConSpan></td>	<td>SDS 1200</td><td></td></tr>
                  <tr><td><TextConSpan>DCS 1000</TextConSpan></td>	<td>DCS 1200</td><td></td></tr>
                  <tr><td><TextConSpan>Drawset 1</TextConSpan></td>	<td>Drawset 2</td><td></td></tr>
                  <tr><td><TextConSpan>DrawTex</TextConSpan></td><td></td><td></td></tr>
                  </tbody>
                </table>
                <SliderCard cards={scragg} title="" widthClass="slideWidth" />
              </Paragraph2>
            </>: ''}
            { activeTab === 6 ? <> 
              <Paragraph1> TSP is committed to providing quality spare parts and service. We offer a comprehensive collection of parts for various generations of machines. Please scroll through the parts below.
              </Paragraph1>
              <SliderCard cards={murata} title="" hideMobile="true" slidesShow={1}/>
              <SliderCard cards={murataMob} title="" isMobile="true"/>
            </>: ''}
            { activeTab === 2 ? <> 
              <Paragraph1> TSP is committed to providing quality spare parts and service. We offer a comprehensive collection of parts for various generations of machines. Please scroll through the parts below.
              </Paragraph1>
              <SliderCard cards={barmag} title="" hideMobile="true" slidesShow={1}/>
              <SliderCard cards={barmagMob} title="" isMobile="true"/>
            </>: ''}
            { activeTab === 5 ? <> 
              <Paragraph1> TSP is committed to providing quality spare parts and service. We offer a comprehensive collection of parts for various generations of machines. Please scroll through the parts below.
              </Paragraph1>
              <SliderCard cards={Aalidhra} title="" hideMobile="true" slidesShow={1}/>
              <SliderCard cards={alidhraMob} title="" isMobile="true"/>
            </>: ''}
            { activeTab === 4 ? <> 
              <Paragraph1> TSP is committed to providing quality spare parts and service. We offer a comprehensive collection of parts for various generations of machines. Please scroll through the parts below.
              </Paragraph1>
              <SliderCard cards={rprImages} title="" hideMobile="true" slidesShow={1}/>
              <SliderCard cards={rprMobImages} title="" isMobile="true"/>
            </>: ''}
            { activeTab === 7 ? <> 
              <Features1 tw="hidden md:block">
                    {featuresAuxParts.map((feature, index) => (
                      <Feature key={index} active={activeFeature === index} onClick={() => changeFeature(index)}>
                        {feature.title}
                      </Feature>
                    ))}
              </Features1>
              <Select options={featuresAuxParts} defaultValue={featuresAuxParts[0]} tw="w-full block text-left md:hidden block" onChange={e => changeFeature(e.index)} />
              <TabInnerDiv1>
                <TabInnerContent1>
                <TabsControl>
                  {Object.keys(auxTabs).map((tabName, index) => (
                    <TabControl1 className={ index === 2 ? 'tabAuxWidth': ''}  key={index} active={activeAuxTab === tabName} onClick={() => setActiveAuxTab(tabName)}>
                      {tabName}
                    </TabControl1>
                    
                  ))}
                </TabsControl>
                {auxTabsKeys.map((tabKey, index) => (
                  <TabContent
                    key={index}
                    variants={{
                      current: {
                        opacity: 1,
                        scale:1,
                        display: "flex",
                      },
                      hidden: {
                        opacity: 0,
                        scale:0.8,
                        display: "none",
                      }
                    }}
                    transition={{ duration: 0.4 }}
                    initial={activeAuxTab === tabKey ? "current" : "hidden"}
                    animate={activeAuxTab === tabKey ? "current" : "hidden"}
                  >
                          { activeAuxTab === "Spin Packs" ? <> 
                              <TabInnerContent>
                                <SectionHeading>Spin Packs</SectionHeading>
                                <br></br>
                                <Paragraph1>
                                  We supply a comprehensive range of components for the spin pack assembly. We can also provide the spin pack housing. Please contact us via our Contact Us page with your requirements.
                                </Paragraph1>
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mb-4 min-w-0 w-full rounded-lg" src={spinpacks1} alt="Polyurethane" />
                                </IllustrationContainer>
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={spinpacks2} alt="Polyurethane" />
                                </IllustrationContainer>
                              </TabInnerContent>
                          </>: ''}
                          { activeAuxTab === "Spinnerets" ? <> 
                            <TabInnerContent>  
                                <SectionHeading>Spinnerets</SectionHeading> 
                                <Paragraph1>
                                  We supply a comprehensive range of high pressure, stainless steel spinnerets for spinning monofilaments, multi-filaments and staple fibres, including bi-components. They are suitable for various types of polymers like PET, PA.6, PA.66 and PP for producing Textile, Technical Textiles, BCF yarns.
                                </Paragraph1>  
                                <Paragraph1>
                                  Spinnerets are available in different materials such as, Stainless-Steel, Hastelloy, etc. All Spinnerets are heat treated and surface hardening. The spinnerets exit surface is finished with a matt, mirror or super mirror finish.
                                </Paragraph1>  
                                <Paragraph1>
                                  Spinnerets are available in various profiles or according to the user’s specification. Spinneret capillaries are available in the traditional Round, Trilobal, Octalobal profiles. More exotic capillary profiles are also available.
                                </Paragraph1> 
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-10 min-w-0 w-full rounded-lg" src={spinnerets} alt="Polyurethane" />
                                </IllustrationContainer>
                                <div>
                                  <Subheading1>Scanning Electron Microscopy Photos of Spinneret Capillaries</Subheading1>     
                                  <IllustrationContainer>
                                      <img tw="md:mr-4 mt-4 mb-10 min-w-0 w-full rounded-lg" src={spinnerets1} alt="Polyurethane" />
                                  </IllustrationContainer>
                                </div>
                                <div>
                                  <Subheading1>Spinneret Exit Super Mirror Finish</Subheading1>
                                  <IllustrationContainer>
                                      <img tw="md:mr-4 mt-4 mb-10 min-w-0 w-full rounded-lg" src={spinnerets2} alt="Polyurethane" />
                                  </IllustrationContainer>   
                                </div>
                                <div>
                                  <Subheading1>Spinneret Mirror Finish</Subheading1>
                                  <IllustrationContainer>
                                      <img tw="md:mr-4 mt-4 mb-10 min-w-0 w-full rounded-lg" src={spinnerets3} alt="Polyurethane" />
                                  </IllustrationContainer>  
                                </div>
                                <div>
                                  <Subheading1>Spinneret Matt Finish</Subheading1>
                                  <IllustrationContainer>
                                      <img tw="md:mr-4 mt-4 mb-10 min-w-0 w-full rounded-lg" src={spinnerets4} alt="Polyurethane" />
                                  </IllustrationContainer>    
                                </div>
                                <div>
                                  <Subheading1>Exotic Spinneret Capillary Profiles</Subheading1>
                                  <IllustrationContainer>
                                      <img tw="md:mr-4 mt-4 mb-10 min-w-0 w-full rounded-lg" src={spinnerets5} alt="Polyurethane" />
                                  </IllustrationContainer> 
                                </div>  
                            </TabInnerContent>
                          </>: ''}
                          { activeAuxTab === "Gaskets and Diaphragm Seals" ? <> 
                            <TabInnerContent>  
                                <SectionHeading>Diaphragm Seals & Gaskets</SectionHeading> 
                                <Paragraph1>
                                  We supply a comprehensive range of Diaphragm Seals and Gaskets in various materials and profiles. Our gaskets are used on Polymer Extruders, Spinning Pumps, Spin Packs, and others area of the Polymer Extrusion process.
                                </Paragraph1>
                                <Paragraph1>
                                  Diaphragm Seals and Gaskets are used to avoid the leakage of the polymer melt. They are designed to fill in spaces of irregular surfaces of different components.
                                </Paragraph1>
                                <Paragraph1 tw="pb-8">
                                  Diaphragm Seal and Gasket materials are available in, aluminium, copper, stainless steel, Teflon, PTFE and others materials, or as per user’s requirements.
                                </Paragraph1>
                                <Subheading1>Diaphragm Seals</Subheading1>
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-10 min-w-0 w-full rounded-lg" src={gasket1} alt="Polyurethane" />
                                </IllustrationContainer>
                                <div>
                                  <Subheading1>Gaskets</Subheading1>
                                  <IllustrationContainer>
                                      <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={gasket2} alt="Polyurethane" />
                                  </IllustrationContainer>
                                </div>
                            </TabInnerContent>
                          </>: ''}  
                          { activeAuxTab === "Screen Filters" ? <> 
                            <TabInnerContent>   
                              <SectionHeading>Screen Filters</SectionHeading> 
                              <Paragraph1>
                                Screen filters are a vital component used in the polymer extrusion process. The screens are placed on the extruders breaker plate to filter contaminants from the melted polymer.
                              </Paragraph1>
                              <Paragraph1>
                                We supply precision Screen Filters in various shapes, structures and batch sizes. The screen design is customer-specific using different layer configurations and weaving structures to create the required filtration and dispersion rates. We produce our screens with precision tooling or with tool-free plasma cutting for smaller batch sizes. 
                              </Paragraph1>
                              <Paragraph1>
                                <table>
                                  <tbody>
                                    <tr><td><TextConSpan><strong>Type:</strong> </TextConSpan></td><td>single-layer, multi-layer spot-welded, multi-layer framed</td><td></td></tr>
                                    <tr><td><TextConSpan><strong>Shape:</strong></TextConSpan></td><td>circle, ring, oval, kidney</td><td></td></tr>
                                    <tr><td><TextConSpan><strong>Material:</strong></TextConSpan></td><td>304, 316, 316L (other specifications are available)</td><td></td></tr>
                                    <tr><td><TextConSpan><strong>Structure:</strong></TextConSpan></td><td>customer-specific; 6 mesh structures and fibre felt available</td><td></td></tr>
                                    <tr><td><TextConSpan><strong>Filter Size:</strong></TextConSpan></td><td>1µm – 500µm (process-specific design)</td><td></td></tr>
                                  </tbody>
                                </table>
                              </Paragraph1>
                              <IllustrationContainer>
                                  <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={screenfilter} alt="Polyurethane" />
                              </IllustrationContainer>     
                            </TabInnerContent>
                          </>: ''}  
                          { activeAuxTab === "Spin Pack Filters" ? <> 
                            <TabInnerContent>   
                              <SectionHeading>Spin Pack Filters</SectionHeading> 
                              <Paragraph1>
                                Spin pack filters are a vital component used in the polymer extrusion process. The filters are used in the spin pack assembly to filter contaminants from the melted polymer.
                              </Paragraph1>
                              <Paragraph1>
                                We supply precision Spin Pack Filters in various shapes, structures and batch sizes. The filter design is customer-specific using different layer configurations and weaving structures to create the required filtration and dispersion rates. We produce our screens with precision tooling or with tool-free plasma cutting for smaller batch sizes.  
                              </Paragraph1>
                              <Paragraph1>
                                <table>
                                  <tbody>
                                    <tr><td><TextConSpan><strong>Type:</strong> </TextConSpan></td><td>single-layer, multi-layer spot-welded, multi-layer framed</td><td></td></tr>
                                    <tr><td><TextConSpan><strong>Shape:</strong></TextConSpan></td><td>circle, ring, oval, kidney</td><td></td></tr>
                                    <tr><td><TextConSpan><strong>Material:</strong></TextConSpan></td><td>304, 316, 316L (other specifications are available)</td><td></td></tr>
                                    <tr><td><TextConSpan><strong>Structure:</strong></TextConSpan></td><td>customer-specific; 6 mesh structures and fibre felt available</td><td></td></tr>
                                    <tr><td><TextConSpan><strong>Filter Size:</strong></TextConSpan></td><td>1µm – 500µm (process-specific design)</td><td></td></tr>
                                  </tbody>
                                </table>
                              </Paragraph1>
                              <IllustrationContainer>
                                  <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={spinpackfilter} alt="Polyurethane" />
                              </IllustrationContainer>      
                            </TabInnerContent>
                          </>: ''} 
                          { activeAuxTab === "Candle Filters" ? <> 
                            <TabInnerContent>   
                              <SectionHeading>Candle Filters</SectionHeading> 
                              <Paragraph1>
                              We supply a comprehensive range of Candle Filters in various constructions and profiles for the polymer extrusion process. 
                              </Paragraph1>
                              <Paragraph1>
                              Polymer Candle Filters are designed and developed to withstand high temperatures, high pressures and remove impurities such as gels and foreign objects from the polymer melt. 
                              </Paragraph1>
                              <Paragraph1>
                              Candle Filters are available in both cylindrical and pleated formats, in industry standard designs, or they can be custom made to fit any particular housing. These are available in both sintered metal fibre and woven wire mesh.
                              </Paragraph1>
                              <Paragraph1>
                              Available in filtration ratings from 3 to 100 microns, our candle filters are normally supplied with an outer guard, both to protect the media and to allow reverse flow during cleaning. 
                              </Paragraph1> 
                              <Paragraph1>
                              All Candle Filters are provided with internal volume reducers to avoid stagnant flow regions within the candle design. Flow diverter features within the volume reducer provide good distribution over the candles as the polymer enters the housing.
                              </Paragraph1> 
                              <Paragraph1>
                              Using our range of high strength, highly permeable stainless steel fibre media, results in candle filters with low initial pressure drops and long on-stream life.
                              </Paragraph1>
                              <IllustrationContainer>
                                  <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={candlefilters} alt="Polyurethane" />
                              </IllustrationContainer>
                              <IllustrationContainer>
                                  <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={candlefilters2} alt="Polyurethane" />
                              </IllustrationContainer>    
                            </TabInnerContent>
                          </>: ''}  
                          { activeAuxTab === "Spin Pack Media" ? <> 
                            <TabInnerContent>
                                <SectionHeading>Spin Pack Media</SectionHeading>
                                <div tw="mt-4">
                                  <Paragraph1>
                                    <Subheading1>Metal Powder (Sand)</Subheading1>
                                    Spinning companies are now using irregular shaped stainless-steel powder as a filter medium in the extrusion process to increase production efficiency and achieve better fibre quality.
                                  </Paragraph1>
                                  <Paragraph1>
                                    TSP offers good quality Metal Powder (commonly known as metal sand) for the polymer extrusion process. If you require further information or technical help, please contact us via our Contact Us Page.  
                                  </Paragraph1>
                                </div>
                                <div tw="mt-4">
                                  <Subheading1>Characteristics of Metal Powder for Polymer Filtration:</Subheading1> 
                                </div>
                                <div tw="mt-4">
                                  <Paragraph1>
                                    <Subheading>Inert to Polymers</Subheading>
                                    High-chromium alloy stainless steel powder is inert to all polymers due to the protective chromium oxide coated on the particle surface.
                                  </Paragraph1>
                                </div>
                                <div>                                  
                                  <Paragraph1>
                                    <Subheading>High Porosity Rate</Subheading>
                                    Porosity values are as high as 70% to 80%, the particle surface area is determined by the size and profile of the powder particles.
                                  </Paragraph1>
                                  <Paragraph1>
                                    Larger particles give a higher porosity and a lower active surface area. Finer particles give a higher active surface area, but a lower porosity volume.
                                  </Paragraph1>
                                  <Paragraph1>
                                    The high active surface area provides good 	polymer cleaning during the filtration process.
                                  </Paragraph1>
                                </div>
                                <Paragraph1>
                                  <Subheading>High Shearing Ability</Subheading>
                                  Stainless steel powder has very sharp, strong, and irregular edges, these characteristics enable good filtering, shearing and stripping of fine micro gels from the polymer melt.
                                </Paragraph1>
                                <Paragraph1>
                                  <Subheading>High Thermal Conductivity</Subheading>
                                  Stainless steel powder has approximately 4 times better heat conductivity than silica sand. It maintains the heat for better temperature control and it is a perfect solution for high-speed spinning.
                                </Paragraph1>
                                <div>
                                  <Paragraph1>
                                    <Subheading>Resistance to Compaction</Subheading>
                                    The filtration particles are made of stainless-steel alloys specially developed for hot polymer filtration. The alloys used for the metal powder reduces the compacting effect during filtration.
                                  </Paragraph1>
                                  <Paragraph1>
                                    This enables a stable pressure during filtration and can extend the spin pack life by 2 -3 times when compared to silica sand.
                                  </Paragraph1>
                                  <Paragraph1>
                                    The particles are not brittle and do not fracture during the filtration process, resulting in clog free spinnerets.
                                  </Paragraph1>
                                </div>
                                <Paragraph1>
                                  <Subheading>Production Process</Subheading>
                                  Stainless Steel Powder is made by water atomization, where high pressure water jets shattering a liquid metal stream into small very irregular shaped particles.
                                </Paragraph1>
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={spinpackmedia} alt="Polyurethane" />
                                </IllustrationContainer>
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={spinpackmedia1} alt="Polyurethane" />
                                </IllustrationContainer>
                                <Paragraph1>
                                  <Subheading1>Metal Powder Sizes Available</Subheading1>
                                </Paragraph1>  
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mb-4 min-w-0 w-full rounded-lg" src={spinpackmedia2} alt="Polyurethane" />
                                </IllustrationContainer>   
                                <Paragraph1>
                                  <Subheading1>Stainless Steel Metal Powder Composition</Subheading1>
                                </Paragraph1>  
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mb-4 min-w-0 w-full rounded-lg" src={spinpackmedia3} alt="Polyurethane" />
                                </IllustrationContainer>    
                            </TabInnerContent>
                          </>: ''}
                          { activeAuxTab === "Filter Weaves" ? <> 
                            <TabInnerContent> 
                              <SectionHeading>Filter Weaves</SectionHeading>
                              <div tw="pt-4">  
                                <Subheading1>Plain Weave</Subheading1> 
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={plainweave} alt="Polyurethane" />
                                </IllustrationContainer>
                                <Paragraph1>
                                  Each warp wire crosses alternately above and below every weft wire and vice versa. Warp and weft wires are normally of the same diameter. Used where a high flow rate of filtration is required.
                                </Paragraph1>
                              </div>
                              <div tw="pt-4">
                                <Subheading1>Twilled Weave</Subheading1> 
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={twilledweave} alt="Polyurethane" />
                                </IllustrationContainer>
                                <Paragraph1>
                                  Each weft wire passes alternately above and below every successive pair of warp wires and vice versa. This weave permits a heavier wire diameter to be used than would be possible in a plain weave with similar mesh count. 
                                </Paragraph1>
                              </div>
                              <div tw="pt-4">
                                <Subheading1>Plain Dutch Weave</Subheading1>   
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={plaindutchweave} alt="Polyurethane" />
                                </IllustrationContainer>
                                <Paragraph1>
                                Woven as a plain weave, but with the warp wires of greater diameter than the weft wires. The weft wires are woven tightly together producing a strong cloth whilst allowing a good flow rate.
                                </Paragraph1>  
                              </div>
                              <div tw="pt-4">  
                                <Subheading1>Dutch Twill Weave</Subheading1> 
                                <IllustrationContainer>
                                    <img tw="md:mr-4 mt-4 mb-4 min-w-0 w-full rounded-lg" src={dutchtwillweave} alt="Polyurethane" />
                                </IllustrationContainer> 
                                <Paragraph1>
                                  Similar to Plain Dutch Weave, but effectively has a double layer of weft wires woven in twill pattern. This cloth is "light tight", has a very smooth surface, is strong, but has a restricted flow rate. Also referred to as Micromesh and is used for critical filtration applications often under high pressure.
                                </Paragraph1> 
                              </div> 
                            </TabInnerContent>
                          </>: ''}
                      </TabContent>
                  ))}
                </TabInnerContent1>
            </TabInnerDiv1>
            </>: ''}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};