import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-4 md:py-10 md:py-12`}
`;
const Heading = tw(SectionHeading)`w-full mb-4 md:mb-8`;
const Paragraph = tw.p`my-2 md:pr-8 lg:my-2 text-base xl:text-lg`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 md:px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-6 md:py-8 border-2 border-dashed border-primary-500 rounded-lg mb-8`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-3`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl text-primary-500`}
  }

  .description {
    ${tw`mt-3 mb-6 text-lg text-secondary-500 leading-normal w-full`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {

  
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Process Optimisation",
      description: "Identifying machine settings, machine components and supply yarn that can contribute to high process break rates.",
      description2: "Optimise process settings to increase production speeds and efficiency."
    },
    { imageSrc: SupportIconImage,
      title: "Quality Control",
      description:"Improve yarn tensile properties and package to package dye uniformity.",
      description2: "Analysing OLT data and cross referencing the data with yarn quality and process breaks.",
      description3: "Recommend yarn testing procedures."
    },
    { imageSrc: CustomizeIconImage,
      title: "Plant Management",
      description:"Implement plant procedures to improve product flow.",
      description2: "Introduce preventive maintenance and machine maintenance schedules."
    },
    { imageSrc: ReliableIconImage,
      title: "Product Development",
      description:"Develop new yarn hardware and software permitting.",
      description2: "Develop fancy yarn hardware and software permitting.",
      description3: "Match competitor samples."
    },
    { imageSrc: FastIconImage,
      title: "Hardware Design",
      description:"Extensive knowledge of how the yarn interacts with the yarn path and its components.",
      description2: "Enables us to propose and provide solutions to overcome issues in production and product quality.",
      description3: "We can develop and interface new machine components to upgrade older machinery."
    },
    { imageSrc: SimpleIconImage,
      title: "Software Design",
      description:"Extensive knowledge of the process enables us to make proposals and give solutions to automate older machinery.",
      description2: "We can also develop new software to control proposed electronic hardware."
    },
    { imageSrc:SimpleIconImage,
      title:"Machinery Manufactures",
      description:"Experience includes working for machinery manufacturers Rieter Scragg and Rieter ICBT.",
      description2: "We have extensive knowledge of Barmag, Aalidhra, Himson, RPR, Giudici, Murata and Chinese machinery."
    },
    { imageSrc:SimpleIconImage,
      title:"Textile Commissioning",
      description:"Previously employed by Rieter Scragg & Rieter ICBT as a senior textile technologist.",
      description2: "Qualified to textile commission all types of older and new texturing machinery installations."
    },
    { imageSrc:SimpleIconImage,
      title:"Customer Visits",
      description:"Extensive customer visits to various texturing companies in Europe, Sub Continent, Far East, North and South America."
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Consultancy</Heading>
        <Paragraph>Synthetic filament yarn consultants with 30 years of experience. Primarily in the technical &amp; manufacturing areas of
        man-made fibers for the false twist texturing process.</Paragraph>
        <Paragraph>Working for machine manufacturers; Rieter Scragg &amp; Rieter ICBT within the Research and Development, Customer
        Services and After Sales departments. We have gained extensive knowledge of the texturing process in both
        theoretical and practical forms.</Paragraph>
        <Paragraph>Through these activities and experience we are able to professionally advise customers on process, component and
        machine configurations, designs and modifications.</Paragraph>
        <Paragraph>Through extensive business trips to different countries, unforeseen problems and stringent scheduling we are able to show initiative to resolve problems. We are able to communicate at all levels of management and with all cultures.</Paragraph>
        <Paragraph>We are conscientious consultants who approach tasks with initiative and logic to resolve issues related to texturing
        on all types of false twist texturing machinery. With a “can do” and a “hands on” attitude towards work, we can
        resolve all production related issues or provide proposals to resolve them.</Paragraph>
        <Heading>Our Professional <span tw="text-primary-500">Services</span></Heading>
        {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                {/* <span className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span> */}
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>
                </span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
                <p className="description">
                  {card.description2}
                </p>
                <p className="description">
                  {card.description3}
                </p>
              </Card>
            </Column>
          ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};