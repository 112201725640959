import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/yarn.jpeg";
import DesignIllustration2 from "../../images/yarn2.jpg";
import CustomersLogoStripImage from "../../images/logostrip.jpg";
import CustomersLogoStrip2Image from "../../images/logostrip2.jpg";
import heroScreenshotImageSrc from "images/machine.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row max-w-screen-xl mx-auto md:pb-8 pb-4 pt-4`;
const LeftColumn = tw.div`relative lg:w-5/12 max-w-lg mx-auto lg:max-w-none text-left`;
const RightColumn = tw.div`relative mt-0 flex-1 flex flex-col justify-center lg:self-start`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl max-w-screen-xl mx-auto pt-4 md:pt-20 text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 md:pr-8 md:pb-12 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex flex-col justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`flex flex-col items-center md:ml-24 md:mr-24`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full`}
  }
`;

export default () => {
  return (
    <>
      <Container>
          <Heading>
            Solutions Provider for <span tw="text-primary-500">Spare Parts, Attachments, Conversions and Consulting.</span>
          </Heading>
        <TwoColumn>
         
          <LeftColumn> 
            <Paragraph>
            TSP was established in 2007 to provide parts and services for false twist texturing machinery. TSP are experts in false twist texturing. Previously employed by Rieter Scragg and Rieter ICBT, we are able to provide a total service solution for mechanical and electronic parts for Rieter Scragg & ICBT machinery.
            </Paragraph>
            <Paragraph>
            TSP continually visit customer’s sites to give expert advice and service. Our site visits and services also cover customers with Barmag, ICBT, TMT, Guidici, RPR, Aalidhra, Bhagat, Himson and Chinese OEM Texturing machinery.
            </Paragraph>
            <Paragraph>
            TSP develops innovative cost effective products to improve the process efficiency and quality of customer’s products. TSP has also developed products that will enable customers to produce “Value Added Yarns" these can be exclusively developed for individual clients.
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="mt-8 min-w-0 w-full max-w-lg xl:max-w-3xl rounded-lg" src={heroScreenshotImageSrc} alt="Design Illustration" />
              <img tw="mt-4 sm:mt-12 lg:mt-12 min-w-0 w-full max-w-lg xl:max-w-3xl rounded-lg" src={DesignIllustration2} alt="Design Illustration" />
              <img tw="mt-4 sm:mt-12 lg:mt-12 min-w-0 w-full max-w-lg xl:max-w-3xl rounded-lg" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <CustomersLogoStrip>
            <img src={CustomersLogoStripImage} alt="Our Customers" />
            <img src={CustomersLogoStrip2Image} alt="Our Customers" />
        </CustomersLogoStrip>
      </Container>
    </>
  );
};
