import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Rieter Scragg" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>  
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            

           <p>
           Textile Solutions Professional (TSP) offers the complete range of parts and service for the full range of Rieter Scragg Machinery,
           <ul>
               <li>SDS 700, SDS 800</li>
               <li>SDS 900, SDS 1200</li>
               <li>DrawSet 1 & 2</li>
               <li>DCS 1000, DCS 1200</li>
               <li>DrawTex</li>
           </ul>
           </p>
           <p>TSP supplies and manufactures to original Scragg specifications. All parts are guaranteed and many consumable parts are stocked for immediate delivery.</p>
           <p>TSP employs ex Scragg mechanical and technical engineers to help customers with all aspects of Scragg related products.</p>
           <p>If you have any inquires for parts please contact us with original part numbers or photos of the part you require with the quantity you require,</p>
           <p>TSP has cost effective solutions to replace obsolete and troublesome electrical control systems. We convert Introl 1, 2 & 3 to the latest PLC modular control systems.</p>
           <p>TSP offers various attachments to enable customers to produce fancy yarns. Please see navigate to our dedicated machine attachments page.</p>
           <p>TSP is the only active company in the world to visit plants who specialise in helping customers utilise and optimise their machinery to keep making quality yarns efficiently.</p>
           <p><b>TSP is the only one stop solution for everything related to Scragg.</b></p>
            
           
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
