import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-1/3 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-3/4 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  tw`w-80 md:w-full m-auto`
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center sm:text-left md:text-left `;

const Subheading = tw(SubheadingBase)`text-center sm:text-left md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-4xl text-center  md:text-left leading-tight`;
const Description = tw.p`mt-4 mb-8 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 py-3 px-7 mr-2 bg-primary-500 text-gray-100
  hocus:bg-secondary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0`,
  props.buttonRounded && tw`rounded-full`
]);

export default(props)=>{
  return(
    <Container>
      <TwoColumn>
        <ImageColumn css={props.imageParentCss}>
          <Image css={props.imageCss} src={props.imageSrc} imageBorder={props.imageBorder} imageShadow={props.imageShadow} imageRounded={props.imageRounded}/>
          {props.imageDecoratorBlob && <DecoratorBlob css={props.imageDecoratorBlobCss} />}
        </ImageColumn>
       <TextColumn textOnLeft={props.textOnLeft}>
         <TextContent>
          <Subheading>{props.subheading}</Subheading>
          <Heading>{props.heading}</Heading>
          <Description>{props.description}</Description>
          <PrimaryButton buttonRounded={props.buttonRounded} as="a" href={props.primaryButtonUrl}>
            {props.primaryButtonText}
          </PrimaryButton>
         </TextContent>
       </TextColumn>
      </TwoColumn>
    </Container>
  );

};