import React, { useState } from "react";

import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading, Subheading1 } from "components/misc/Headings.js";
import { Subheading as SubheadingBase } from "components/misc/Headings";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import SliderCard from "components/cards/SliderWithoutTitle.js";
import { PrimaryButton, PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
//fancy Yarn

import electronic from "images/electronic/electronic.jpg";
import electronic1 from "images/electronic/electronic1.jpg";
import electronic2 from "images/electronic/electronic2.jpg";

import rig from "images/electronic/rig.jpg";
import kit from "images/electronic/kit.jpg";

import electronicpart1 from "images/electronic/electronicpart1.jpg";
import electronicpart2 from "images/electronic/electronicpart2.jpg";
import electronicpart3 from "images/electronic/electronicpart3.jpg";
import electronicpart4 from "images/electronic/electronicpart4.jpg";
import electronicpart5 from "images/electronic/electronicpart5.jpg";
import electronicpart6 from "images/electronic/electronicpart6.jpg";
import electronicpart7 from "images/electronic/electronicpart7.jpg";
import electronicpart8 from "images/electronic/electronicpart8.jpg";
import electronicpart9 from "images/electronic/electronicpart9.jpg";
import electronicpart10 from "images/electronic/electronicpart10.jpg";
import electronicpart11 from "images/electronic/electronicpart11.jpg";
import electronicpart12 from "images/electronic/electronicpart12.jpg";
import electronicpart13 from "images/electronic/electronicpart13.jpg";
import electronicpart14 from "images/electronic/electronicpart14.jpg";
import electronicpart15 from "images/electronic/electronicpart15.jpg";
import electronicpart16 from "images/electronic/electronicpart16.jpg";
import electronicpart17 from "images/electronic/electronicpart17.jpg";
import electronicpart18 from "images/electronic/electronicpart18.jpg";
import electronicpart19 from "images/electronic/electronicpart19.jpg";
import electronicpart20 from "images/electronic/electronicpart20.jpg";
import electronicpart21 from "images/electronic/electronicpart21.jpg";

const TextConSpan = tw.span`mr-10`;
const TextConSpanWide = tw.span`md:mr-24`;
const Paragraph1 = tw.p`my-3 md:pr-8 lg:my-3 text-base xl:text-lg`;
const Heading = tw(SectionHeading)`w-full text-3xl md:text-4xl`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const parts = [
  { imageSrc: electronicpart1 },
  { imageSrc: electronicpart2 },
  { imageSrc: electronicpart3 },
  { imageSrc: electronicpart4 },
  { imageSrc: electronicpart5 },
  { imageSrc: electronicpart6 },
  { imageSrc: electronicpart7 },
  { imageSrc: electronicpart8 },
  { imageSrc: electronicpart9 },
  { imageSrc: electronicpart10 },
  { imageSrc: electronicpart11 },
  { imageSrc: electronicpart12 },
  { imageSrc: electronicpart13 },
  { imageSrc: electronicpart14 },
  { imageSrc: electronicpart15 },
  { imageSrc: electronicpart16 },
  { imageSrc: electronicpart17 },
  { imageSrc: electronicpart18 },
  { imageSrc: electronicpart19 },
  { imageSrc: electronicpart20 },
  { imageSrc: electronicpart21 }
];

export default () => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>Electronic Repair and Remanufacturing Services</Heading>
        <Paragraph1>
        Our team of professional and highly experienced electronic engineers, identify faults to component level and replace them where necessary. Our preventative maintenance procedures whilst repairing the parts, dramatically reduces the risk of future failure. 
        </Paragraph1>
        <Paragraph1>
        We offer upgrade services to improve failure-prone electronics through the use of newer, more reliable parts or full circuit redesign. We also design and manufacture replacement parts for obsolete items.
        </Paragraph1>
        <Paragraph1>
        Our expert technicians repair a comprehensive range of textile machinery electronics including, Rieter Scragg, ICBT, Barmag, SSM, Giudici, TMT, RPR, Fadis, OMM, etc.
        </Paragraph1>
        <Paragraph1>
        We also repair electronic parts from spinning, weaving and knitting machine OEM’s.
        </Paragraph1>
        <Paragraph1>
        Our key areas of expertise are repairing: 
        </Paragraph1>
        <Paragraph1>
            <table>
              <tbody>
                <tr><td><TextConSpan>Variable Speed Drives </TextConSpan></td><td><TextConSpan>Frequency Inverters</TextConSpan></td><td>Spindle Drives</td></tr>
                <tr><td><TextConSpan>Servo Drives</TextConSpan></td><td><TextConSpan>Servo Motors</TextConSpan></td><td>Stepper Drives</td></tr>
                <tr><td><TextConSpan>HMI’s</TextConSpan></td><td><TextConSpan>Power Supplies</TextConSpan></td><td>Encoders</td></tr>
                <tr><td><TextConSpan>Displays</TextConSpan></td><td><TextConSpan>PCB’s</TextConSpan></td><td>PLC’s</td></tr>
              </tbody>
            </table>
        </Paragraph1>
        <Paragraph1>
          All our in-house repairs carry a 1 YEAR GUARANTEE. Should a repair not be possible or cost effective, our specialist procurement team will endeavour to source a suitable replacement.
        </Paragraph1>
        <img tw="visible sm:hidden mt-8 mb-4 rounded-lg" src={electronic1} alt="Design Illustration" />
        <img tw="visible sm:hidden mt-8 mb-4 rounded-lg" src={electronic2} alt="Design Illustration" />
        <img tw="hidden sm:block mt-8 mb-4 rounded-lg" src={electronic} alt="Design Illustration" />
        <Paragraph1>
        All parts are fully tested using state of the art diagnostics kits or using specially developed test kits.
        </Paragraph1>
        <div tw="md:flex mt-8">
            <div tw="flex flex-col items-center md:mr-8">
              <Subheading1 tw="text-center">Purpose Built SSM Test Rig</Subheading1>
              <img tw="mt-8 mb-4" src={rig} alt="Design Illustration" />
            </div>
            <div tw="flex flex-col items-center">
              <Subheading1 tw="text-center">abi System 8 Diagnostics Kit</Subheading1>
              <img tw="mt-8 mb-4" src={kit} alt="Design Illustration" />
            </div>
        </div>
        <Subheading1 tw="mt-8 text-left">Selection of SSM Electronics Tested & Repaired</Subheading1>
        <Paragraph1>
            <table tw="hidden sm:block">
              <tbody>
                <tr><td><TextConSpan>386.15774</TextConSpan></td><td><TextConSpanWide>Control Board</TextConSpanWide></td><td><TextConSpan>386.15773</TextConSpan></td><td><TextConSpan>Control Board</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.53313</TextConSpan></td><td><TextConSpanWide>Control Unit</TextConSpanWide></td><td><TextConSpan>386.53305</TextConSpan></td><td><TextConSpan>Control Unit</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.11254</TextConSpan></td><td><TextConSpanWide>PreciFlex Control Board</TextConSpanWide></td><td><TextConSpan>322.02680</TextConSpan></td><td><TextConSpan>PreciFlex Control Board</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.11286</TextConSpan></td><td><TextConSpanWide>PreciFlex Control Board</TextConSpanWide></td><td><TextConSpan>386.11242</TextConSpan></td><td><TextConSpan>PreciFlex Control Board</TextConSpan></td></tr>
                <tr><td><TextConSpan>322.10681</TextConSpan></td><td><TextConSpanWide>PreciFlex Control Board</TextConSpanWide></td><td><TextConSpan>386.11284</TextConSpan></td><td><TextConSpan>PreciFlex Controller</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.12272</TextConSpan></td><td><TextConSpanWide>PreciControl Control Board</TextConSpanWide></td><td><TextConSpan>386.11617</TextConSpan></td><td><TextConSpan>PreciControl Doffing Card</TextConSpan></td></tr>
                <tr><td><TextConSpan>319.00521</TextConSpan></td><td><TextConSpanWide>PreciControl Unit</TextConSpanWide></td><td><TextConSpan>386.11616</TextConSpan></td><td><TextConSpan>Winder Control Doffing Card</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.11613</TextConSpan></td><td><TextConSpanWide>Winder Control Doffing Card </TextConSpanWide></td><td><TextConSpan>386.70030</TextConSpan></td><td><TextConSpan>Heater Control Unit</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.11610</TextConSpan></td><td><TextConSpanWide>Winder Control Doffing Card </TextConSpanWide></td><td><TextConSpan>0447-01-01</TextConSpan></td><td><TextConSpan>Communication Board</TextConSpan></td></tr>
              </tbody>
            </table>

            <table tw="block sm:hidden">
              <tbody>
                <tr><td><TextConSpan>386.15774</TextConSpan></td><td><TextConSpanWide>Control Board</TextConSpanWide></td></tr>
                <tr><td><TextConSpan>386.15773</TextConSpan></td><td><TextConSpan>Control Board</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.53313</TextConSpan></td><td><TextConSpanWide>Control Unit</TextConSpanWide></td></tr>
                <tr><td><TextConSpan>386.53305</TextConSpan></td><td><TextConSpan>Control Unit</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.11254</TextConSpan></td><td><TextConSpanWide>PreciFlex Control Board</TextConSpanWide></td></tr>
                <tr><td><TextConSpan>322.02680</TextConSpan></td><td><TextConSpan>PreciFlex Control Board</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.11286</TextConSpan></td><td><TextConSpanWide>PreciFlex Control Board</TextConSpanWide></td></tr>
                <tr><td><TextConSpan>386.11242</TextConSpan></td><td><TextConSpan>PreciFlex Control Board</TextConSpan></td></tr>
                <tr><td><TextConSpan>322.10681</TextConSpan></td><td><TextConSpanWide>PreciFlex Control Board</TextConSpanWide></td></tr>
                <tr><td><TextConSpan>386.11284</TextConSpan></td><td><TextConSpan>PreciFlex Controller</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.12272</TextConSpan></td><td><TextConSpanWide>PreciControl Control Board</TextConSpanWide></td></tr>
                <tr><td><TextConSpan>386.11617</TextConSpan></td><td><TextConSpan>PreciControl Doffing Card</TextConSpan></td></tr>
                <tr><td><TextConSpan>319.00521</TextConSpan></td><td><TextConSpanWide>PreciControl Unit</TextConSpanWide></td></tr>
                <tr><td><TextConSpan>386.11616</TextConSpan></td><td><TextConSpan>Winder Control Doffing Card</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.11613</TextConSpan></td><td><TextConSpanWide>Winder Control Doffing Card </TextConSpanWide></td></tr>
                <tr><td><TextConSpan>386.70030</TextConSpan></td><td><TextConSpan>Heater Control Unit</TextConSpan></td></tr>
                <tr><td><TextConSpan>386.11610</TextConSpan></td><td><TextConSpanWide>Winder Control Doffing Card </TextConSpanWide></td></tr>
                <tr><td><TextConSpan>0447-01-01</TextConSpan></td><td><TextConSpan>Communication Board</TextConSpan></td></tr>              
                </tbody>
            </table>
        </Paragraph1>
        <Subheading1 tw="mt-8 mb-4 text-left">Selection of Various OEM Electronic Parts Repaired</Subheading1>
        <SliderCard title='' slidesShow={1} cards={parts} compactClass="compact" />
      </ContentWithPaddingXl>
      <DecoratorBlob2 />
    </Container>
  );
};