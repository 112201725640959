import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import emailjs from 'emailjs-com';
import toast, { Toaster } from 'react-hot-toast';
import ReCAPTCHA from "react-google-recaptcha";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { ReactComponent as Github } from "images/github-icon.svg";
import phone from "images/contactus/phone.jpg";
import whatsapp from "images/contactus/whatsapp.jpg";
import call from "images/contactus/call.jpg";
import email from "images/contactus/email.jpg";
import website from "images/contactus/website.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto `;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 md:mt-0 `,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const TextContent1 = tw.div`lg:py-8 text-center md:text-left md:pl-4 lg:pl-10 xl:pl-10 mt-8 md:mt-0 pb-4 md:pb-0`;

const Subheading = tw(SubheadingBase)`text-left text-lg`;
const Heading = tw(SectionHeading)`mt-4 font-black sm:text-3xl lg:text-4xl xl:text-5xl text-left md:text-left leading-tight `;
const Description = tw.p`mt-4 text-left md:text-left md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col mx-auto md:mx-0`;
const Input = tw.input`mb-2 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24 mb-8`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const Label = tw.label`flex items-center text-gray-700 text-base sm:text-lg font-bold mb-2 `;
const Label1 = tw.label`flex items-center text-gray-700 text-lg mb-2 `;

export default ({
  //FirstColoumn
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
  heading2 = <><span tw="text-primary-500">Contact Details</span><wbr /></>,
  headingCompanyAddress = <><span tw="text-primary-500">Company Address</span><wbr /></>,
  headingCompanyDetails = <><span tw="text-primary-500">Company Details</span><wbr /></>,
  description = "All our customers are important to us. Our Sales and Technical teams are happy to help you with any questions concerning our products and services. A contact number can be particularly useful if we need to clarify your requirements before responding.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  //SecondColoumn
  subheading1 = "Our Contact Details",
  heading1 = <>We are <span tw="text-primary-500">available in 24</span> hours</>,
  cards = [
    {
      label: "+44 7718 636296",
      image: phone,
      links: [
        {
          icon: Github
        }
      ]
    },
    {
      label: "+44 7718 636296",
      image: whatsapp
    },
    {
      label: "+44 1204 455427",
      image: call
    },
    {
      label: "support@drawtexturingsolutions.com",
      image: email
    },
    {
      label: "www.drawtexturingsolutions.com",
      image: website
    }
  ],
  addrCards = [
    {
      addr1: "Street",
      addr2: "19 Shepherd Cross Street"
    },
    {
      addr1: "County",
      addr2: "Greater Manchester"
    },
    {
      addr1: "Town",
      addr2: "Bolton"
    },
    {
      addr1: "Postcode",
      addr2: "BL1 3BS"
    },
    {
      addr1: "Country",
      addr2: "United Kingdom"
    }
  ],
  detailCards = [
    {
      addr1: "Company Number",
      addr2: "06042505"
    },
    {
      addr1: "VAT Number",
      addr2: "910390456"
    },
    {
      addr1: "EORI Number",
      addr2: "GB910390456000"
    }
  ]

}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)

  }, []);

  const form = useRef();
  const recaptchaRef = React.createRef();
  const [phoneNumber, setPhoneNumber] = useState();

  function onChange(value) {
  }

  const sendEmail = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    if(recaptchaValue){
      emailjs.sendForm("service_26go1br", "template_07iyiaq", form.current, 'user_5RHWhrMgk2t6cACgp5thw')
      .then((result) => {
        toast.success('Your enquiry has been sent successfully. Well get back to you soon!');
      }, (error) => {
          console.log(error.text);
      });
    }
    else {
      toast.success('Invalid Captcha. Verify yourself by clicking on the Google Captcha!');
    }
  };

  return (<div>
    <Container>
    <div><Toaster/></div>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            {description && <Description>{description}<br />
              <br></br>
              <p>Please complete the form below, we aim to send you a response within 24 hours</p>
            </Description>}
            <Form action={formAction} method={formMethod} ref={form} onSubmit={sendEmail}>
              <Input type="text" name="company" placeholder="Company" required />
              <Input type="email" id="email" name="email" placeholder="Your Email Address" required/>
              <Input type="text" name="name" placeholder="Full Name" required/>
              <PhoneInput placeholder="Your Mobile/LAN number" inputProps={{ name: "number", required: true }} value={phoneNumber} onChange={() => setPhoneNumber(phoneNumber)} />
              <Input type="text" name="subject" placeholder="Subject" required/>
              <Textarea name="message" placeholder="Your Message Here" required/>
              <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdUBJkcAAAAAMexxL7PKdz-EaMtwUOTHTapfdkw"
              onChange={onChange}  />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
        <TextColumn>
          <TextContent1>
            <Heading>{heading2}</Heading>
            <br></br>
            {cards.map((card, index) => (
              <div key={index} tw="flex justify-between mb-2">
                <img src={card.image} alt="Polyurethane" />
                <Label>{card.label}</Label>
              </div>
            ))}
            <br></br>
            <Heading>{headingCompanyAddress}</Heading>
            <br></br>
            {addrCards.map((card, index) => (
              <div key={index} tw="flex justify-between mb-2">
                <Label1>{card.addr1}</Label1>
                <Label>{card.addr2}</Label>
              </div>
            ))}
            <br></br>
            <Heading>{headingCompanyDetails}</Heading>
            <br></br>
            <Subheading>Textile Solutions Professional Ltd.</Subheading>
            <br></br>
            {detailCards.map((card, index) => (
              <div key={index} tw="flex justify-between mb-2">
                <Label1>{card.addr1}</Label1>
                <Label>{card.addr2}</Label>
              </div>
            ))}
          </TextContent1>
        </TextColumn>
      </TwoColumn>
    </Container></div>
  );
};
