import React, { useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import tw from "twin.macro";
const Container = tw.div`relative`;
const Pagination = tw.div`flex flex-col items-center`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-4 mr-4 md:mt-4 text-sm`;

export default function SinglePage(props) {
  const divRef = useRef();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    divRef.current.scrollIntoView({ behavior: "smooth" });
    changePage(-1);
  }

  function nextPage() {
    divRef.current.scrollIntoView({ behavior: "smooth" });
    changePage(1);
  }

  const { pdf } = props;

  return (
    <Container ref={divRef}>
      <Document
        file={pdf}
        options={{ workerSrc: "pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={window.innerWidth / 1.6} />
      </Document>
      <Pagination>
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <div>
          <PrimaryButton type="button" disabled={pageNumber <= 1} onClick={previousPage}>
            Previous
          </PrimaryButton>
          <PrimaryButton disabled={pageNumber >= numPages}
            onClick={nextPage}>
            Next
          </PrimaryButton>
        </div>
      </Pagination>
    </Container>
  );
}