import React from "react";
import ReactDOM from 'react-dom';
import App from "./App";
import Modal from "react-modal";

Modal.setAppElement("#root");

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}