import React from "react";
import { createPopper } from "@popperjs/core";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Link
} from "react-router-dom";

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`text-gray-100
hocus:bg-secondary-700 hocus:text-gray-200 cursor-pointer focus:shadow-outline text-white text-sm py-2 px-4 font-normal block w-full mt-1 md:mt-0 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw``
]);

const MenuButton = tw(Link)`
xl:px-4 lg:px-6 -mt-2 py-4 rounded bg-blue-500 text-gray-100
hocus:bg-secondary-700 hocus:text-gray-200 focus:shadow-outline
border-b-0
`;

const Container = tw.div`inline-block`;
const Container1 = tw.div``;
const Container2 = tw.div`relative inline-flex align-middle`;

const PopupMenu = tw.div`text-base z-50 float-left pt-2 list-none text-left rounded shadow-lg mt-1`;
 

export default ({ popupList = null, title="Spare Parts" }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start"
    });
    //setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const defaultPopupList = [
    {
      title: "General Texturing Machine Parts",
      to: "/SpareParts",
      selection: 'general'
    },
    {
      title: "Rieter Scragg",
      to: "/SpareParts",
      selection: 'general'
    },  {
      title: "Barmag",
      to: "/SpareParts",
      selection: 'general'
    },  {
      title: "Rieter ICBT - ICBT",
      to: "/SpareParts",
      selection: 'general'
    },  {
      title: "Aalidhra",
      to: "/SpareParts",
      selection: 'general'
    },  {
      title: "Himson",
      to: "/SpareParts",
      selection: 'general'
    },  {
      title: "RPR - Giudici",
      to: "/SpareParts",
      selection: 'general'
    },  {
      title: "Other Chinese OEM machine Parts",
      to: "/SpareParts",
      selection: 'general'
    },  {
      title: "Textile Auxiliaries",
      to: "/SpareParts",
      selection: 'general'
    },
  ];

  if (!popupList) popupList = defaultPopupList;

  return (
     <>
            <MenuButton as="a"
              href="/SpareParts"  
              ref={btnDropdownRef}
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
              {title}
            </MenuButton>
            <PopupMenu
              ref={popoverDropdownRef}
              className={
                (dropdownPopoverShow ? "block " : "hidden ")
              }
              style={{ maxWidth: "20rem" }}
            >
               {popupList.map((item, i) => (
              <PrimaryButton as="a"  href={item.to}  onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}>
                {item.title}
              </PrimaryButton>))}
            </PopupMenu>
            </>
  );
};