import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import MainFeature1 from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSimple.js";
import Features from "components/features/ThreeColSimple.js";

import CustomerLoveIconImage from "images/aboutus/customer-support.jpg";
import MarketingStrategyImage from "images/aboutus/marketing-strategy.jpg";
import MarketingNetworkImage from "images/aboutus/marketing-network.png";
import ManufacturingIconImage from "images/aboutus/manufacturing-icon.jpg";
import WorldPresenceImage from "images/aboutus/world-presence.jpg";
import FutureImage from "images/aboutus/future.jpg";


import StatsIllustrationSrc from "images/aboutus/professional_exp.jpg";
import AboutExpSrc from "images/aboutus/expertise.png";

const Key = tw.div`font-medium`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  ul {
    ${tw`list-disc`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default () => {
  useEffect(() => {
    const body = document.querySelector('#root');
  
    body.scrollIntoView({
        behavior: 'smooth'  
    }, 500)
  
  }, []);
 
  return (
    <div>
      <MainFeature1
        subheading={''}
        heading="Professional Experience"
        buttonRounded={false}
        primaryButtonText="See Products"
        primaryButtonUrl = "https://timerse.com"
        imageParentCss={tw``}  
        imageCss={tw``}
        textOnLeft = {true}
        description={ <div>TSP has over 35 years’ experience in the continuous synthetic filament industry. During this time, we worked with companies from North & South America, Europe, India, Middle East, China & South East Asia.
          <br></br><br></br><Key>Our experience includes:</Key>
          <br></br>
          <Text>
            <ul>
              <li>
                Working in R&D and Aftersales Departments for machinery manufacturers Rieter Scragg, Rieter ICBT and Rieter BCF
              </li>
              <li>
                Working for leading textile companies who produce continuous filament textured Polyester and Nylon yarns
              </li>
              <li>
                Extensive practical experience with British, French, German, Italian, Indian, Chinese and Japanese Machinery
              </li>
            </ul>
          </Text>
        </div> }
        imageSrc={StatsIllustrationSrc} 
      />
    
      <MainFeature2
        subheading={''}
        heading="Professional Experts"
        buttonRounded={false}
        primaryButtonText="See Products"
        description={ <div>TSP understands the difficulties texturing manufacturing plants face and we can provide expert solutions for textile and production related issues.<br/> 
        We have extensive expert theoretical knowledge and experience of the false twist process and we can always provide a solution.</div>}
        imageSrc={AboutExpSrc}
        imageParentCss={tw`h-40 w-40 mt-2`}  
        imageCss={tw`h-40 w-40`}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading></Subheading>}
        heading="Our Core Values"
        description="Act with uncompromising honesty and integrity in everything we do."
        description2="Satisfy our customers with innovative technology, superior quality, value and service."
        cards={[
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Support",
            description: "TSP is Committed to providing a comprehensive professional service with continuous customer support.",
            description2: " We always aim to build a courteous friendly and professional relationship with all our business associates." 
          },
          {
            imageSrc: MarketingStrategyImage,
            title: "Marketing Strategy",
            description: "Providing customers with value added products and service to enable them to remain competitive.",
            description2: " Supplying quality and improved product performance with better economics."
          },
          {
            imageSrc: MarketingNetworkImage,
            title: "Marketing Network",
            description: "Growing networks of local agents are available for sales and after sales service"
          },
          {
            imageSrc: ManufacturingIconImage,
            title: "Manufacturing Facilities",
            description: "TSP currently has high specification manufacturing facilities in UK, India and China"
          },
          {
            imageSrc: WorldPresenceImage,
            title: "Global Market Presence",
            description: "TSP is currently active in Europe, Middles East, India, China, South East Asia, North and South America"
          },
          {
            imageSrc: FutureImage,
            title: "Future Growth",
            description: "As we grow, we are continuously working to meet customer requirements by producing economic and innovative value added products.",
            description2: " We aim to provide solutions to help our customers keep pace with ever changing market trends."
          }
        ]}
        linkText=""
      />
      </div>
  );
};

