import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`text-left sm:text-left mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs mb-8 sm:mt-4 md:mt-0`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full lg:mx-4 px-4 lg:py-8 md:py-4 py-4 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 border-2 border-dashed border-primary-500 rounded-lg`}
  .imageContainer {
    ${tw`text-center rounded-full bg-gray-100`}
    img {
      ${tw`h-24`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm text-lg text-gray-800 text-left`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

const TwoColumn = tw.div`flex flex-col md:flex-row max-w-screen-xl mx-auto items-center`;
const ImageColumn = tw(Column)`md:w-1/6 flex-shrink-0 relative`;

export default (props) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn css={props.imageParentCss}>
        </ImageColumn>
        <div textOnLeft={props.textOnLeft}>
          {props.heading && <Heading tw="text-left">{props.heading}</Heading>}
          {props.description && <Description>{props.description}</Description>}
          {props.description2 ? <Description>{props.description2}</Description> : ''} 
        </div>
      </TwoColumn>
      <ThreeColumnContainer>
        {props.cards.map((card, i) => (
          <Column key={i}>
            <Card href={card.url}>
              <span className="imageContainer" css={props.imageContainerCss}>
                <img src={card.imageSrc} alt="" css={props.imageCss} />
              </span>
              <span className="title">{card.title}</span>
              <p className="description">{card.description}</p>
              { card.description2 ? <div><p className="description">{card.description2}</p></div> : ''}
              {props.linkText && (
                <span className="link">
                  <span>{props.linkText}</span>
                  <ArrowRightIcon className="icon" />
                </span>
              )}
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};