import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {

    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Fancy Yarn Introduction" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            

            <p>
            Worldwide production of standard textured yarns has become highly competitive. Companies are competing for a market, which is often unwilling to pay for the real production cost of the yarns. This has meant for many companies in the texturing industry much lower profits and even losses. The industry is under severe pressure to innovate by offering yarns with new features. Apart from chemical or spinning technology related changes there are also ways to modify the aesthetics of yarns on the texturing machine. These yarns are often referred to as fancy yarns.
            </p>

            <p>
            Fancy yarns have deliberately introduced <b>irregular</b> characteristics, in either
             <ul>
               <li>Diameter</li>
               <li>Bulk</li>
               <li>Colour</li>
               <li>Bi Component Yarns</li>
               <li>Core & Effect</li>
             </ul>
            </p>
            <p>
            Such yarns are also used for decorative textiles like
             <ul>
               <li>Curtains</li>
               <li>Carpets</li>
               <li>Apparel ladies and children outerwear</li>
               <li>Decor materials and textile fabrics in the corporate sector, as for example in the trim of a car or textile furnishing of a hotel lobby are becoming more and      more important</li>
               
             </ul>
             <p>
             Textile Solutions Professional can offer texturing companies process expertise, hardware, software and control systems for ANY OEM texturing machinery to produce fancy yarns.
             </p>
            </p>
            <p><b>Please contact us for more details</b></p>

            
           
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
