import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Experience" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2020</p>

            <p>
            TSP has over 30 years’ experience in the continuous synthetic filament industry. 
            During this time we have worked with companies from North & South America, Europe, India, Middle East, China & South East Asia
            </p>

            <p>
            Our experience includes:
             <ul>
               <li>Working for machinery manufacturers Rieter Scragg, Rieter ICBT, Rieter BCF</li>
               <li>Working for leading textile companies who produce continuous filament textured Polyester and Nylon yarns</li>
               <li>Extensive experience with German, French, Italian, Indian, Chinese and Japanese Machinery</li>
             </ul>
            </p>

            <h1>Customer and Marketing</h1>
            <h2>Customer Support</h2>
            <p>
            TSP is committed to providing a comprehensive professional service with continuous customer support.
             We always aim to build a courteous friendly and professional relationship with all our business associates.
            </p>
            

            <h2>Marketing Strategy</h2>
            <p>Supplying quality and improved product performance with better economics.
               To provide customers with value added products and service to enable them to remain competitive
            </p>
            <h2>Marketing Network</h2>
            <p>
            Growing networks of local agents are available for sales and after sales service.
            </p>
            <h2>Manufacturing Facilities</h2>
            <p>
            TSP currently has high specification manufacturing facilities in India and China.
            </p>
            <h2>Market Presence</h2>
            <p>
            TSP is currently active in Europe, Middles East, India, China, South East Asia, North and South America.
            </p>
            <h2>Future</h2>
            <p>
            As we grow, we are continuously working to meet customer requirements by producing, economic and innovative value added products.
             We aim to provide solutions to help our customers keep pace with ever changing market trends.
            </p>

           
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
